import { ColumnData, ColumnDataEntries } from './types';
import {
    ConfigPropertyArrayItem,
    ConfigState,
    ConfigPropertyObj
} from 'src/context/globalPopup/store';
import { ApiScreenColumnsOrder, ApiScreenColumnsConfiguration } from 'src/api/src/listScreen/types';
import { fakeCellData, fakeRowData } from 'src/constants/fakeData';

// TableStructureManager jest specjalnym obiektem który zarządza danimi kolumn na podstawie slice listScreenTableData dla akcji  - przypięcia i widoczności oraz kolejności dla (Pociągnij i upuść)
// TableStructureManager is specific object which menage data column base on slice listScreenTableData for action column table - pinned, visible and order (Drag & drop)

type GenerateNewOrder = {
    activeId: string;
    overId: string;
};

export type StoreNewOrder = {
    [key: string]: number;
};

interface TableStrucutureManagerProps {
    columnDataEntries: ColumnDataEntries;
}

export class TableStructureManager {
    columnDataEntries: ColumnDataEntries;
    sortedKeyColumns: string[];
    sortedData: [string, ColumnData][] | [];
    constructor({ columnDataEntries }: TableStrucutureManagerProps) {
        this.columnDataEntries = columnDataEntries;
        this.sortedKeyColumns = [];
        this.sortedData = [];
    }
    // handling isPinned and visible columns

    static setStatusForPinned(
        configPropertyCol: ConfigPropertyArrayItem[],
        limitIsAchieved: boolean
    ): ConfigPropertyArrayItem[] {
        if (limitIsAchieved) {
            return configPropertyCol.map((col) => {
                if (col.isVisible && col.isPinned) {
                    return {
                        ...col,
                        statusIconPinned: 'isPinned'
                    };
                }
                return {
                    ...col,
                    statusIconPinned: 'isDisabled'
                };
            });
        } else {
            return configPropertyCol.map((col) => {
                if (col.isVisible && col.isPinned) {
                    return {
                        ...col,
                        statusIconPinned: 'isPinned'
                    };
                } else if (col.isVisible && !col.isPinned) {
                    return {
                        ...col,
                        statusIconPinned: 'isActive'
                    };
                }
                return {
                    ...col,
                    statusIconPinned: 'isDisabled'
                };
            });
        }
    }

    static generateDataConfigForApi(configPropertyCol: ConfigPropertyArrayItem[]) {
        const apiConfig: ApiScreenColumnsConfiguration = {};
        configPropertyCol.forEach((item) => {
            apiConfig[`${item.id}_${item.fieldType}`] = {
                isPinned: item.isPinned,
                isVisible: item.isVisible
            };
        });
        return apiConfig;
    }

    setStatePopupPropertyCol() {
        const configPropertyCol: ConfigPropertyArrayItem[] = [];
        const configPropertyColObj: ConfigPropertyObj = {};
        for (const key in this.columnDataEntries) {
            if (!this.columnDataEntries[key].isDisplayExcluded) {
                configPropertyCol.push({
                    code: this.columnDataEntries[key].code,
                    isPinned: this.columnDataEntries[key].isPinned,
                    isVisible: this.columnDataEntries[key].isVisible,
                    name: this.columnDataEntries[key].name,
                    id: this.columnDataEntries[key].id,
                    fieldType: this.columnDataEntries[key].fieldType
                });
                configPropertyColObj[key] = {
                    isPinned: this.columnDataEntries[key].isPinned,
                    isVisible: this.columnDataEntries[key].isVisible
                };
            }
        }
        const limitIsAchieved =
            configPropertyCol.filter((item) => item.isPinned).length >= 4 ? true : false;
        return {
            limitIsAchieved: limitIsAchieved,
            propertyCol: TableStructureManager.setStatusForPinned(
                configPropertyCol,
                limitIsAchieved
            ),
            propertyColObj: configPropertyColObj
        };
    }

    static ubdateVisilbleColItem(configState: ConfigState, itemClicked: ConfigPropertyArrayItem) {
        const newPropertyColObj = {
            ...configState.propertyColObj,
            [itemClicked.code]: {
                ...itemClicked,
                isVisible: !itemClicked.isVisible,
                isPinned: itemClicked.isVisible ? false : itemClicked.isPinned
            }
        };
        const newPropertyCol = configState.propertyCol.map((item) => {
            if (item.code === itemClicked.code)
                return {
                    ...item,
                    isVisible: !itemClicked.isVisible,
                    isPinned: itemClicked.isVisible ? false : itemClicked.isPinned
                };
            return item;
        });
        const limitIsAchieved =
            newPropertyCol.filter((item) => item.isPinned).length >= 4 ? true : false;
        return {
            limitIsAchieved: limitIsAchieved,
            propertyCol: TableStructureManager.setStatusForPinned(newPropertyCol, limitIsAchieved),
            propertyColObj: newPropertyColObj
        };
    }

    static ubdatePinnedColItem(configState: ConfigState, itemClicked: ConfigPropertyArrayItem) {
        const newPropertyColObj = {
            ...configState.propertyColObj,
            [itemClicked.code]: {
                ...itemClicked,
                isPinned: !itemClicked.isPinned
            }
        };
        const newPropertyCol = configState.propertyCol.map((item) => {
            if (item.code === itemClicked.code)
                return {
                    ...itemClicked,
                    isPinned: !itemClicked.isPinned
                };
            return item;
        });
        const limitIsAchieved =
            newPropertyCol.filter((item) => item.isPinned).length >= 4 ? true : false;
        return {
            limitIsAchieved: limitIsAchieved,
            propertyCol: TableStructureManager.setStatusForPinned(newPropertyCol, limitIsAchieved),
            propertyColObj: newPropertyColObj
        };
    }

    //handling order column table (Drag & Drop)
    static sortColumnOnOrder(columnDataEntries: ColumnDataEntries) {
        return Object.entries(columnDataEntries).sort((x, y) => {
            return x[1].order - y[1].order;
        });
    }

    static groupColumnDataEntriesByPinned(columnDataEntries: ColumnDataEntries) {
        const pinnedColumnDataEntries: ColumnDataEntries = {};
        const unpinnedColumnDataEntries: ColumnDataEntries = {};
        for (const key in columnDataEntries) {
            if (columnDataEntries[key].isPinned && columnDataEntries[key].type !== 'threeDots') {
                pinnedColumnDataEntries[key] = columnDataEntries[key];
            } else {
                unpinnedColumnDataEntries[key] = columnDataEntries[key];
            }
        }
        return {
            pinnedColumnDataEntries,
            unpinnedColumnDataEntries
        };
    }

    static generateSortedKeyColumns(columnDataEntries: ColumnDataEntries) {
        const grouped = TableStructureManager.groupColumnDataEntriesByPinned(columnDataEntries);
        const orderedKeyColumns = [
            ...TableStructureManager.sortColumnOnOrder(grouped.pinnedColumnDataEntries),
            ...TableStructureManager.sortColumnOnOrder(grouped.unpinnedColumnDataEntries)
        ];
        const sortedKeyColumns: string[] = [];
        orderedKeyColumns.forEach((item) => {
            if (item[1]?.isVisible) {
                sortedKeyColumns.push(item[0]);
            }
        });

        return sortedKeyColumns;
    }

    sortColumnOnOrder() {
        this.sortedData = Object.entries(this.columnDataEntries).sort(
            (x, y) => x[1].order - y[1].order
        );
    }

    static generateFakeRow(columnDataEntries: ColumnDataEntries) {
        for (const key in columnDataEntries) {
            if (columnDataEntries[key].isVisible) {
                fakeRowData.cellValues[key] = [
                    {
                        ...fakeCellData
                    }
                ];
            }
        }
        return fakeRowData;
    }

    generateNewOrderForColumns({ activeId, overId }: GenerateNewOrder) {
        this.sortColumnOnOrder();
        const apiNewOrder: ApiScreenColumnsOrder = {};
        const storeNewOrder: StoreNewOrder = {};
        const active = this.columnDataEntries[activeId];
        const orderActive = this.columnDataEntries[activeId].order;
        const orderOver = this.columnDataEntries[overId].order;
        this.sortedData.forEach((item) => {
            if (orderActive > orderOver) {
                if (item[1].order <= orderActive && item[1].order >= orderOver) {
                    apiNewOrder[`${item[1].id}_${item[1].fieldType}`] = {
                        order: item[1].order + 1
                    };
                    storeNewOrder[item[0]] = item[1].order + 1;
                } else {
                    if (!item[1].isDisplayExcluded) {
                        apiNewOrder[`${item[1].id}_${item[1].fieldType}`] = {
                            order: item[1].order
                        };
                    }
                }
            } else if (orderActive < orderOver) {
                if (item[1].order >= orderActive && item[1].order <= orderOver) {
                    apiNewOrder[`${item[1].id}_${item[1].fieldType}`] = {
                        order: item[1].order - 1
                    };
                    storeNewOrder[item[0]] = item[1].order - 1;
                } else {
                    if (!item[1].isDisplayExcluded) {
                        apiNewOrder[`${item[1].id}_${item[1].fieldType}`] = {
                            order: item[1].order
                        };
                    }
                }
            }
        });
        apiNewOrder[`${active.id}_${active.fieldType}`] = {
            order: orderOver
        };
        storeNewOrder[activeId] = orderOver;
        return { apiNewOrder, storeNewOrder };
    }
}
