import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';

import { InputBase } from '@mui/material';

import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';

export const DynamicListManySearch2: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const theme = useTheme();

    const ids = listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        ? listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        : [];
    const defVal: string[] = searchField.searchOptions
        .filter((item) => {
            if (ids.includes(item.id)) {
                return true;
            }
            return false;
        })
        .map((item) => item.name);

    const useStyles = makeStyles({
        option: {
            minHeight: '30px!important'
        },
        listbox: {
            paddingTop: '0px!important',
            paddingBottom: '0px!important'
        },
        paper: {
            background: '#fff!important',
            marginTop: '1px',
            border: `solid 1px ${theme.palette.primary.main}`
        },
        popper: {
            position: 'absolute',
            zIndex: 0
        }
    });

    const classes = useStyles();

    return (
        <div style={{ position: 'relative' }}>
            <Autocomplete
                multiple
                popupIcon={''}
                defaultValue={defVal}
                options={searchField.searchOptions.map((op) => op.name)}
                onChange={(_, data: string[]) => {
                    const ids: number[] = [];
                    data.forEach((item) => {
                        const found = searchField.searchOptions.find(
                            (option) => option.name === item
                        );
                        if (found) ids.push(found.id);
                    });
                    dispatchFilter({
                        type: 'SET_VALUE_TWO',
                        payload: {
                            code: searchField.searchCode,
                            value: ids
                        }
                    });
                }}
                disableCloseOnSelect={true}
                disablePortal={true}
                clearIcon={
                    <ButtonCross
                        onClick={() => {}}
                        style={{
                            top: '2px'
                        }}
                    />
                }
                classes={{
                    option: classes.option,
                    listbox: classes.listbox,
                    paper: classes.paper,
                    popper: classes.popper
                }}
                renderInput={(params: any) => {
                    return (
                        <InputBase
                            {...params.InputProps}
                            {...params}
                            sx={{
                                border: `solid 1px ${theme.palette.primary.main}`,
                                borderRadius: '4px',
                                paddingLeft: '10px'
                            }}
                        />
                    );
                }}
            />
        </div>
    );
};
