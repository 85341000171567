import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Button } from '@mui/material';
import ContentScroll from 'src/components/shared/molecules/contentScroll/ContentScroll';
import styles from './PopupContentInfo.module.css';

export const PopupContentInfo: FC = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const popup = useTypedSelector((state) => state.popup);

    return (
        <PopupTemplateThird title={globalPopup.contentInfo.title}>
            <div className={styles.wrapper}>
                <ContentScroll maxWidth={800} maxHeight={300}>
                    <p
                        style={{
                            color: theme.palette.primary.main,
                            fontSize: '14px'
                        }}
                        dangerouslySetInnerHTML={{ __html: popup.listScreen.message }}></p>
                </ContentScroll>
                <div className={styles.box}>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={() => dispatch(closePopup())}>
                        <p>{globalPopup.contentInfo.button.yes}</p>
                    </Button>
                </div>
            </div>
        </PopupTemplateThird>
    );
};
