import React, { FC, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { ThemeCompatibleSvgProps } from './types';
import { SvgIcon } from '@mui/material';
import { DEFAULT_SYSTEM_COLOR } from 'src/constants';

/**
 * Provide easy way to act with SVG icons, handle applying color and fontSize to icon, compatible
 * with mui theme
 */
const ThemeCompatibleSvgIcon: FC<ThemeCompatibleSvgProps> = ({
    SvgComponent,
    color,
    rawColor,
    originColorToChange,
    sx,
    ...props
}) => {
    const theme = useTheme();

    const svgContainerRef = useRef<HTMLDivElement>(null);

    const fillColor = rawColor ?? (color === 'inherit' ? undefined : theme.palette[color].main);

    useEffect(() => {
        const baseColor = (originColorToChange ?? DEFAULT_SYSTEM_COLOR).toLowerCase();
        if (svgContainerRef.current) {
            // remove all fill attributes with color that should be dynamic, so color will be changing by fill: currentColor;
            svgContainerRef.current
                .querySelectorAll(`[fill='${baseColor}' i]`)
                ?.forEach((element) => element.removeAttribute('fill'));
            // set stroke='none' for elements not having stroke attribute so stroke won't be added by 'stroke={fillColor}' in SvgIcon
            svgContainerRef.current
                .querySelectorAll(':not([stroke]) > :not([stroke]), svg > :not([stroke])')
                ?.forEach((element) => element.setAttribute('stroke', 'none'));
            // remove all stroke attributes with color that should be dynamic, so stroke will be changing by stroke: currentColor;
            svgContainerRef.current
                .querySelectorAll(`[stroke='${baseColor}' i]`)
                // ?.forEach((element) => element.removeAttribute('stroke'));
                ?.forEach((element) => element.setAttribute('stroke', 'currentColor'));
        }
    }, [svgContainerRef, originColorToChange, SvgComponent]);

    return (
        <div ref={svgContainerRef}>
            <SvgIcon
                component={SvgComponent}
                stroke={fillColor}
                sx={{ ...sx, color: fillColor }}
                {...props}
                inheritViewBox={true}
            />
        </div>
    );
};

export default ThemeCompatibleSvgIcon;
