export const sxInputV1Default = {
    borderRadius: '3px',
    backgroundColor: '#fff',
    width: '100%',
    height: '28px',
    paddingTop: '2px',
    paddingLeft: '5px',
    fontSize: '12px',
    'input::placeholder': {
        textTransform: 'lowercase',
        fontSize: '12px',
        paddingLeft: '6px',
        color: '#B9B9B9'
    }
};

export const sxThemeCompatibleIconV1Default = {
    width: '12px',
    height: 'auto',
    marginTop: '4px',
    marginRight: '5px',
    cursor: 'pointer'
};

export const sxLabelV1Default = {};

export const sxCheckboxV1Default = {};

export const sxElWrapperV1Default = {
    width: '100%'
};

export const sxCalendarMainWraperDefault = {
    display: 'flex'
};

export const sxPickerPanel = {
    position: 'absolute',
    marginTop: '2px',
    borderRadius: '5px',
    backgroundColor: '#fff',
    display: 'flex',
    zIndex: '100'
};
export const sxPickerPanelWraperDefault = {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center'
};
export const sxPickerCheckboxDefault = {
    borderRadius: '3px',
    padding: '0'
};

export const sxPickerInputDefault = {
    borderRadius: '3px',
    backgroundColor: '#fff',
    width: '110px',
    height: '28px',
    paddingTop: '2px',
    paddingLeft: '5px',
    fontSize: '12px',
    'input::placeholder': {
        textTransform: 'lowercase',
        fontSize: '12px',
        paddingLeft: '6px',
        color: '#B9B9B9'
    }
};

export const sxPickerPanelDefault = {
    sxPickerPanelInsideWraper: {
        position: 'absolute',
        marginTop: '2px',
        borderRadius: '5px',
        backgroundColor: '#fff',
        display: 'flex',
        zIndex: '1000',
        border: '1px solid'
    },
    sxPickerPanelBox: {
        width: '214px',
        height: '201px',
        backgroundColor: '#fff',
        zIndex: '10',
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    sxPickerPanelLabel: {
        cursor: 'pointer',
        marginRight: '5px'
    }
};
