import React, { FC } from 'react';

import { NormalScroll } from 'src/components/shared/molecules/normalScroll/NormalScroll';
import { useComponentSize, useWindowSize } from 'react-use-size';
import { ContentScrollProps } from 'src/components/shared/molecules/contentScroll/types';
import styles from './ContentScroll.module.css';

const ContentScroll: FC<ContentScrollProps> = ({
    maxHeight,
    maxWidth,
    spaceLeftRight = 300,
    spaceUpDown = 300,
    children
}) => {
    const { height: heightWindow, width: widthWindow } = useWindowSize();
    const { ref, height, width } = useComponentSize();

    const availableWidth = widthWindow > maxWidth ? maxWidth : widthWindow - spaceLeftRight;
    const availableHeight =
        heightWindow - spaceUpDown < maxHeight ? heightWindow - spaceUpDown : maxHeight;

    const newHeight = availableHeight <= height ? availableHeight : height;
    const setScrollHeight = height >= availableHeight;
    const setScrollWidth = width >= availableWidth;
    if (setScrollHeight || setScrollWidth) {
        return (
            <NormalScroll width={availableWidth} height={newHeight}>
                <div className={styles.box} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.box2}>{children}</div>
                </div>
            </NormalScroll>
        );
    }
    return (
        <div
            className={styles.box}
            style={{
                maxHeight: `${maxHeight + 'px'}`,
                maxWidth: `${maxWidth + 'px'}`
            }}
            onClick={(e) => e.stopPropagation()}
            ref={ref}>
            <div className={styles.box2}>{children}</div>
        </div>
    );
};

export default ContentScroll;
