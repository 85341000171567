import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateSecond from 'src/components/shared/organisms/popupTemplateSecond/PopupTemplateSecond';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup, setPopupWithoutData } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Typography } from '@mui/material';
import { ApiActionBehavior } from 'src/data/types';

export const PopupContentInitialConfirmApi: FC = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const popup = useTypedSelector((state) => state.popup);
    const behaviour = popup.listScreen.action?.behaviour as ApiActionBehavior;
    const setNextConfirm = () => {
        dispatch(
            setPopupWithoutData({
                type: 'confirmationApi'
            })
        );
    };
    return (
        <PopupTemplateSecond
            title={globalPopup.contentConfirmation.title}
            buttonLeftTxt={globalPopup.contentConfirmation.button.yes}
            buttonRightTxt={globalPopup.contentConfirmation.button.no}
            buttonRightOnClick={() => dispatch(closePopup())}
            buttonLeftOnClick={setNextConfirm}>
            <Typography
                sx={{
                    color: theme.palette.primary.main
                }}>
                {behaviour?.data?.confirmText}
            </Typography>
        </PopupTemplateSecond>
    );
};
