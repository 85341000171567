import React, { FC } from 'react';

import { SystemAction } from 'src/data/types';
import { useTypedSelector } from 'src/store';
import { useTheme } from '@mui/material/styles';
import styles from './DynamicDescription.module.css';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
import { FieldHelper } from 'src/utils/src/shared/FieldHelper';
import { TemplateManagerExclamation } from 'src/utils/src/shared/TemplateManagerExclamation';
import { reduceStr } from 'src/utils';

type Props = {
    id: RowDataId;
    action: SystemAction | null;
    closePopup?: () => void;
};

export const DynamicDecscription: FC<Props> = ({ action, id }) => {
    const theme = useTheme();
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const fields = FieldHelper.getFilteredFields({
        fields: action?.behaviour.data?.fields,
        key: 'modyfikacja',
        value: 0
    });
    const fieldsTemplated = fields.map((item) => {
        return {
            ...item,
            domyslna_wartosc: new TemplateManagerExclamation({
                rowData: rowData,
                rowId: id,
                templateStr: item.domyslna_wartosc,
                typeSearch: 'text'
            }).getOne()
        };
    });

    return (
        <div className={styles.wrapper}>
            {fieldsTemplated.map((item, i) => {
                return (
                    <div key={item.nazwa + i} className={styles.box}>
                        <p
                            className={styles.p}
                            style={{
                                fontWeight: '600',
                                color: `${theme.palette.primary.main}`
                            }}>
                            {item.nazwa}:
                        </p>
                        <p className={styles.p}>{reduceStr(item.domyslna_wartosc, 100)}</p>
                    </div>
                );
            })}
        </div>
    );
};
