import { BaseApiResponse } from '../../types';
import { simpleQueryServerData } from '../simpleQueryServerData';
import { ApiScreenColumnsOrder } from './types';

/**
 * Update information about columns pin and visibility
 */
export async function updateScreenColumnsOrder(
    screen: string,
    columnsOrder: ApiScreenColumnsOrder
): Promise<BaseApiResponse<null>> {
    return simpleQueryServerData('api_react/src/listScreen/updateScreenColumnsOrder.php', 'POST', {
        screen,
        columns: columnsOrder
    });

    //temporary use mock fixed value
    // return {
    //     message: 'Zaktualizowano',
    //     success: true,
    //     errorCode: null,
    //     data: null
    // };
}
