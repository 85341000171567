import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';

import { UiInputNormal } from '../../atoms/uiInputs/uiInputNormal/UiInputNormal';
import { UiInputSelect } from '../../atoms/uiInputs/uiInputSelect/UiInputSelect';
import { sxInputV1Default } from 'src/components/shared/atoms/uiInputs/sxDefault';

export const DynamicDateRelativeSearch: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const theme = useTheme();

    const sxinputselect = {
        ...sxInputV1Default,
        border: `solid 1px ${theme.palette.primary.main}`
    };

    const useStyles = makeStyles({
        paper: {
            background: '#fff!important',
            fontWeight: 'bold',
            border: `solid 1px ${theme.palette.primary.main}`,
            transform: 'translateX(-2px)'
        }
    });

    const classes = useStyles();

    const value2 = listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        ? listSearchFilters[searchField.searchCode]?.fieldFilter?.value
        : null;
    const unit = value2?.unit ? value2?.unit : 'd';

    const daysDictionary = [
        { value: 'd', name: 'dni' },
        { value: 't', name: 'tygodnie' },
        { value: 'm', name: 'miesiące' },
        { value: 'r', name: 'lata' }
    ];

    const [unitWew, setUnitWew] = React.useState<string>(unit);

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '100%', paddingRight: '10px' }}>
                <UiInputNormal
                    type={'number'}
                    value={value2?.number}
                    onChange={(event: React.SyntheticEvent) => {
                        const value = (event.target as HTMLSelectElement).value
                            ? {
                                  number: Number((event.target as HTMLSelectElement).value),
                                  unit: unitWew
                              }
                            : null;
                        dispatchFilter({
                            type: 'SET_VALUE_EIGHT',
                            payload: {
                                code: searchField.searchCode,
                                value: value
                            }
                        });
                    }}
                />
            </Box>
            <Box sx={{ width: '100%', paddingLeft: '10px' }}>
                <UiInputSelect
                    value={unitWew}
                    options={daysDictionary}
                    sxinput={{
                        ...sxinputselect,
                        background: 'transparent',
                        paddingLeft: '0px',
                        '& fieldset': {
                            background: 'transparent',
                            border: '0px!important',
                            width: '100%',
                            '& legend': {
                                display: 'none'
                            }
                        }
                    }}
                    MenuProps={{ classes: { paper: classes.paper } }}
                    onChange={(event: React.SyntheticEvent) => {
                        setUnitWew((event.target as HTMLSelectElement).value);
                        if (value2) {
                            dispatchFilter({
                                type: 'SET_VALUE_EIGHT',
                                payload: {
                                    code: searchField.searchCode,
                                    value: {
                                        number: value2.number,
                                        unit: (event.target as HTMLSelectElement).value
                                    }
                                }
                            });
                        }
                    }}
                />
            </Box>
        </Box>
    );
};
