import { Box, Divider } from '@mui/material';
import React from 'react';
import styles from './TopMenu.module.css';
import WidgetsSection from 'src/components/menu/organisms/widgetsSection/WidgetsSection';
import MessagesSection from 'src/components/menu/organisms/messagesSection/MessagesSection';
import SearchBar from 'src/components/menu/organisms/searchBar/SearchBar';
import ShortcutsPinSection from 'src/components/menu/organisms/shortcutsPinSection/ShortcutsPinSection';
import UserSection from 'src/components/menu/organisms/userSection/UserSection';
import { LogoProduction } from 'src/components/menu/organisms/logo/LogoProduction';
import Logo from 'src/components/menu/organisms/logo/Logo';
import { MENU_HEADER_HEIGHT } from 'src/constants';
import ShortcutsMenuWrapper from 'src/components/menu/organisms/shortcutsMenuWrapper/ShortcutsMenuWrapper';

const TopMenu = () => {
    return (
        <Box
            width={'100%'}
            height={MENU_HEADER_HEIGHT}
            display={'grid'}
            gridTemplateColumns={'250px 1fr 380px;'}
            alignItems={'center'}
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
                background: (theme) => theme.palette.background.paper,
                padding: '0 18px'
            }}>
            <div className={styles.section}>
                {process.env.NODE_ENV === 'development' ? (
                    <Logo name={'logo.png'} />
                ) : (
                    <LogoProduction />
                )}
                <ShortcutsPinSection />
                <Divider orientation="vertical" sx={{ padding: 1 }} flexItem />
            </div>
            <div className={styles.sectionTwo}>
                <ShortcutsMenuWrapper />
                <div className={styles.sectionTwoInner}>
                    <SearchBar />
                </div>
            </div>
            <div className={`${styles.section} ${styles.sectionItemLast}`}>
                <WidgetsSection />
                <div className={styles.wrapperDivider}>
                    <Divider sx={{ marginRight: '10px' }} orientation="vertical" />
                </div>
                <MessagesSection />
                <UserSection />
            </div>
        </Box>
    );
};

export default React.memo(TopMenu);
