const regexNumber = /[0-9]/g;

export class DateManager {
    static combineDateWithTime({ date, time }: { date: Date; time: Date }) {
        const minuts = new Date(time).getMinutes();
        const hours = new Date(time).getHours() * 60;
        const allMinutsTimestamp = (hours + minuts) * 60000;
        const allDayTimestamp = new Date(new Date(date).setHours(0, 0, 0, 0)).getTime();
        return new Date(allDayTimestamp + allMinutsTimestamp);
    }

    static getDaysInMonth(year: number, month: number) {
        return new Date(year, month, 0).getDate();
    }
    static formatedDateToNewDate(date: Date | string) {
        if (date instanceof Date) {
            return date;
        }
        const numbersArr = [...date.matchAll(regexNumber)];
        if (numbersArr.length !== 8) return new Date();
        const numbersStr = numbersArr
            .map((item) => item[0])
            .reduce((previousValue, currentValue) => previousValue + currentValue);

        const days = Number(numbersStr.slice(0, 2));
        const month = Number(numbersStr.slice(2, 4));
        const year = Number(numbersStr.slice(4, 8));
        return new Date(year, month - 1, days);
    }
    static setTimeString(date: Date | string) {
        if (date instanceof Date) {
            let hours: string | number = date.getHours();
            if (hours < 10) hours = '0' + hours;
            let minutes: string | number = date?.getMinutes();
            if (minutes < 10) minutes = '0' + minutes;
            return `${hours}:${minutes}`;
        }
        return '00:00';
    }

    static setHourString(date: Date | string) {
        if (date instanceof Date) {
            let hours: string | number = date.getHours();
            if (hours < 10) hours = '0' + hours;
            return String(hours);
        }
        return '00';
    }

    static setMinutesString(date: Date | string) {
        if (date instanceof Date) {
            let minutes: string | number = date?.getMinutes();
            if (minutes < 10) minutes = '0' + minutes;
            return String(minutes);
        }
        return '00';
    }

    static setDateFromPhpTimestamp(timestampPhp: string) {
        const timestamp = Number(timestampPhp) * 1000;
        return new Date(timestamp);
    }
    static isToday(date: Date | string) {
        const today = new Date();
        if (date instanceof Date) {
            if (today.toDateString() === date.toDateString()) {
                return true;
            }
        } else if (typeof date === 'string') {
            const dateParts: string[] = date.split('-');
            const days = Number(dateParts[1]) - 1;
            const dateObject = new Date(Number(dateParts[2]), Number(days), Number(dateParts[0]));
            if (dateObject.toDateString() === today.toDateString()) {
                return true;
            }
        }
        return false;
    }
}
