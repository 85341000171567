import React, { FC } from 'react';
import { DatePicker } from 'src/components/shared/atoms/datePicker/DatePicker';
import { DynamicSearchInputProps } from 'src/components/listScreen/molecules/dynamicSearchInputs/type';

export const DynamicSingleDateSearch: FC<DynamicSearchInputProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const value = listSearchFilters[searchField.searchCode]?.fieldFilter?.value;
    return (
        <div>
            <DatePicker
                dateInitial={value?.data || undefined}
                isCheckboxToday={true}
                numberPanels="three"
                getDate={({ dateSelectedFormatted, isToday }) => {
                    dispatchFilter({
                        type: 'SET_VALUE_FOUR',
                        payload: {
                            code: searchField.searchCode,
                            value: dateSelectedFormatted
                                ? {
                                      data: dateSelectedFormatted,
                                      loadToday: isToday
                                  }
                                : null
                        }
                    });
                }}
                portalEnabled={true}
                sxContainerPanels={{ position: 'absolute' }}
                clearButton={true}
            />
        </div>
    );
};
