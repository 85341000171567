import { BaseApiResponse } from '../../types';
import { simpleQueryServerData } from '../simpleQueryServerData';

/**
 * decode given code to navigation system data
 */
export async function unmaskUrl(
    code: string
): Promise<BaseApiResponse<{ url: string; locationState: any }>> {
    return simpleQueryServerData('api_react/src/shared/unmaskUrl.php', 'POST', { code });
}
