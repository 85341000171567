import React, { FC, useMemo } from 'react';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { useTypedSelector } from '../../../../store';
import { styledCreateCustomTheme } from '../../../../styles/styledGeneralTheme';
import { createCustomTheme } from '../../../../styles/generalTheme';

const generateClassName = createGenerateClassName({
    productionPrefix: 'general'
});

const Theme: FC = ({ children }) => {
    const systemColor = useTypedSelector((store) => store.system.color);
    const styledTheme = useMemo(() => styledCreateCustomTheme(systemColor), [systemColor]);
    const theme = useMemo(() => createCustomTheme(systemColor), [systemColor]);
    return (
        <ThemeProvider theme={styledTheme}>
            <MuiThemeProvider theme={theme}>
                <StylesProvider injectFirst generateClassName={generateClassName}>
                    {children}
                </StylesProvider>
            </MuiThemeProvider>
        </ThemeProvider>
    );
};

export default Theme;
