export class NumberManager {
    static countDecimals = function (value: number | string) {
        let safeNumber = Number(value);
        if (typeof value === 'string') {
            safeNumber = Number(value.replaceAll(',', '.'));
        }
        if (isNaN(Number(safeNumber))) return 0;
        if (Math.floor(safeNumber) !== safeNumber)
            return safeNumber.toString().split('.')[1].length || 0;
        return 0;
    };
}
