import { BaseApiResponse } from '../types';

/**
 * Fetches information from server
 */
export async function simpleQueryServerData<T>(
    url: string,
    method: 'GET' | 'POST' | 'DELETE' = 'GET',
    bodyContent: any = undefined,
    parseBody: boolean = true
): Promise<BaseApiResponse<T>> {
    // Default options are marked with *
    let parsedBody: string = bodyContent;
    if (bodyContent && parseBody) {
        parsedBody = JSON.stringify(bodyContent);
    }

    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        },
        body: parsedBody
    });

    const dataPrimary = await response.text();

    let dataParsed = null;
    try {
        dataParsed = JSON.parse(dataPrimary);
    } catch (e) {
        return { success: false, errorCode: 500, data: null, message: e } as any;
    }

    return dataParsed;
}
