import React, { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { UiInputSelectProps } from '../types';
import { UiInputElWrapper } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElWrapper/UiInputElWrapper';
import { UiInputElLabel } from 'src/components/shared/atoms/uiInputs/uiInputElements/uiInputElLabel/UiInputElLabel';
import { sxInputV1Default } from '../sxDefault';
import { SelectIcon } from 'src/components/shared/atoms/uiInputs/uiInputSelect/SelectIcon';

export const UiInputSelect: FC<UiInputSelectProps> = (props) => {
    const theme = useTheme();

    const sxinputselect = {
        ...sxInputV1Default,
        border: `solid 1px ${theme.palette.primary.main}`,
        ...props.sxinput
    };
    const options = props.options ? props.options : [];
    if (!options.length && props.opcje) {
        const ops = props.opcje ? props.opcje : {};
        for (const property in ops) {
            options.push({ name: ops[property], value: property });
        }
    }

    return (
        <UiInputElWrapper {...props}>
            {props.label && <UiInputElLabel {...props} />}
            <Select
                {...props}
                sx={{
                    ...sxinputselect
                }}
                IconComponent={props.icon ? props.icon : SelectIcon}>
                {options?.map((option: { name: string; value: string | number }) => {
                    return (
                        <MenuItem key={option.value.toString()} value={option.value}>
                            {option.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </UiInputElWrapper>
    );
};
