import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { TemplateItem } from 'src/utils/src/shared/TemplateItem';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { TemplateGlobalManager } from 'src/utils/src/shared/TemplateGlobalManager';
type TemplateExclamationProps = {
    temporaryAllString: string;
    templateItem: TemplateItem;
    rowData: RowData[];
    rowId?: RowDataId | null;
};

export class TemplateExclamation extends TemplateGlobalManager {
    temporaryAllString: string;
    templateItem: TemplateItem;
    rowData: RowData[];
    rowId?: RowDataId | null;
    constructor({ templateItem, rowData, rowId, temporaryAllString }: TemplateExclamationProps) {
        super();
        this.templateItem = templateItem;
        this.temporaryAllString = temporaryAllString;
        this.rowData = rowData;
        this.rowId = rowId;
    }
    getData() {
        const raw = new TableDataManager({
            rowData: this.rowData
        }).getRaw(this.rowId);
        const contentToReplace = raw?.cellValues[this.templateItem.contentClear]?.[0]?.text
            ? raw?.cellValues?.[this.templateItem.contentClear]?.[0]?.text
            : '';

        return this.replaceContent({
            contentToReplace: typeof contentToReplace === 'string' ? contentToReplace : '',
            templateItem: this.templateItem,
            temporaryAllString: this.temporaryAllString
        });
    }
}
