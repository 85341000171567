import React, { FC } from 'react';
import { useTypedSelector } from 'src/store';
import { PopupContentTypeWew2fa } from 'src/components/shared/atoms/popupContentTypeWew2fa/PopupContentTypeWew2fa';
import { PopupContentInfo } from '../../molecules/popupContentInfo/PopupContentInfo';

export const PopupContentAlert: FC = () => {
    const popup = useTypedSelector((state) => state.popup);

    switch (popup.typeWew) {
        case '2fa':
            return <PopupContentTypeWew2fa />;
        case 'message':
            return <PopupContentInfo />;
        default:
            return <></>;
    }
};
