import React from 'react';
import { useTypedSelector } from 'src/store/index';
import GlobalPopup from 'src/components/shared/organisms/globalPopup/GlobalPopup';

const WraperGlobalPopup = () => {
    const popup = useTypedSelector((state) => state.popup);
    return <>{popup.isDisplayed && <GlobalPopup />}</>;
};

export default WraperGlobalPopup;
