import { TransformationsState } from 'src/store/src/listScreen/transformations/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryServerData } from '../simpleQueryServerData';
import { ApiObjectsData } from './types';

export async function fetchObjectsData(
    screen: string,
    transformationsState: TransformationsState
): Promise<BaseApiResponse<ApiObjectsData>> {
    const params = new URLSearchParams([
        ['screen', screen],
        ['page', transformationsState.paging.actualPage.toString()],
        ['objectsPerPage', transformationsState.paging.objectsPerPage.toString()]
    ]);
    const body = {
        filters: transformationsState.filters,
        sortingData: transformationsState.sortingData
    };
    return simpleQueryServerData(
        `api_react/src/listScreen/getObjectsData.php?${params.toString()}`,
        'POST',
        body
    );
}
