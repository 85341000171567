export const enum ContentResponseTypes {
    FILE,
    ERROR,
    VALID_CONTENT,
    CONTENT_LOOP
}

export const enum InjectingEndStatus {
    SUCCESS,
    ERROR,
    TOKEN_CHANGED
}
