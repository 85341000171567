import { ConvertableToParams } from 'src/data/types';
import { convertParamsObject } from './convertParamsObject';
import { getActualURLParamsAsObject } from './getActualURLParamsAsObject';

/**
 * merge convertableObject and actual params and return them
 * @param convertableObject object than should be converted to params
 * @returns params as URLSearchParams
 */
export const makeFullParamsFromLocationParams = (convertableObject: ConvertableToParams) => {
    const paramsObject = getMergedParams(convertableObject);
    return convertParamsObject(paramsObject);
};

/**
 * Merge given params with actual url params. New params are more important and override existing
 * @param newParams params to merge with actual
 */
const getMergedParams = (newParamsObject: ConvertableToParams) => {
    const params = getActualURLParamsAsObject();

    // first spread actual params, later new in order to let new override older
    return { ...params, ...newParamsObject };
};
