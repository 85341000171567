import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { TransformationManager } from 'src/store/src/listScreen/transformations/TransformationManager';
import { TemplateItem } from 'src/utils/src/shared/TemplateItem';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { TemplateGlobalManager } from 'src/utils/src/shared/TemplateGlobalManager';
import { TransformationsState } from 'src/store/src/listScreen/transformations/types';
import { SystemAction } from 'src/data/types';

const regexObjId = /(@objectId)/;
const regexListScreenParams = /(@listScreenParams)/;
const regexListScreenExcelParams = /(@listScreenExcelParams)/;

type TemplateMonkeyProps = {
    temporaryAllString: string;
    templateItem: TemplateItem;
    rowData: RowData[];
    totalIds?: string[];
    rowId?: RowDataId | null;
    transformationState: TransformationsState;
    screen?: null | string;
    action?: SystemAction;
};

export class TemplateMonkey extends TemplateGlobalManager {
    temporaryAllString: string;
    templateItem: TemplateItem;
    rowData: RowData[];
    rowId?: RowDataId | null;
    transformationState: TransformationsState;
    screen?: null | string;
    totalIds?: string[];
    action?: SystemAction;
    constructor({
        templateItem,
        rowData,
        rowId,
        temporaryAllString,
        transformationState,
        screen,
        totalIds,
        action
    }: TemplateMonkeyProps) {
        super();
        this.templateItem = templateItem;
        this.temporaryAllString = temporaryAllString;
        this.rowData = rowData;
        this.rowId = rowId;
        this.totalIds = totalIds;
        this.transformationState = transformationState;
        this.screen = screen;
        this.action = action;
    }
    getData() {
        if (this.templateItem.content.match(regexObjId)) {
            const ids = new TableDataManager({
                rowData: this.rowData,
                rowId: this.rowId,
                totalIds: this.totalIds,
                action: this.action
            }).getRowsIdBaseOnTemplate(this.templateItem);
            return this.replaceContent({
                contentToReplace: ids.join(','),
                templateItem: this.templateItem,
                temporaryAllString: this.temporaryAllString
            });
        } else if (this.templateItem.content.match(regexListScreenParams)) {
            const str = new TransformationManager({
                transformationState: this.transformationState
            }).getPagingAsParams({ merge: true });
            return this.replaceContent({
                contentToReplace: str,
                templateItem: this.templateItem,
                temporaryAllString: this.temporaryAllString
            });
        } else if (this.templateItem.content.match(regexListScreenExcelParams)) {
            const str = new TransformationManager({
                transformationState: this.transformationState
            }).getPagingAsParams({
                keys: ['actualPage', 'objectsPerPage']
            });
            return this.replaceContent({
                contentToReplace: str + `&screen=${this.screen}`,
                templateItem: this.templateItem,
                temporaryAllString: this.temporaryAllString
            });
        }
        return '';
    }
}
