import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { saveFilter } from 'src/api/src/listScreen/saveFilter';
import { listScreen } from 'src/constants/subtitles';
import { getState, useAppDispatch } from 'src/store';
import { ListSearchFilters } from 'src/store/src/listScreen/transformations/types';
import { fetchAndLoadUtilitiesBarActions } from 'src/store/src/utilitiesBar/utilitiesBar/utilitiesbarSlice';
import {
    getLinkCode,
    makeFullParamsFromLocationParams,
    makeListScreenLocationData,
    makeValidLocationState
} from 'src/utils';

/**
 * handle saving filter, by requesting code of current location and asking user for filter name
 * @param screen screen id or code
 */
export const useSaveListSearchFilter = () => {
    const storeState = getState();
    const dispatch = useAppDispatch();
    const location = useLocation();

    return useCallback(
        async (
            screen: string,
            updateObject: {
                paging: any;
                filters: ListSearchFilters | undefined;
            },
            name: string
        ) => {
            // make pageLocationStore same way as main nav functions do
            const newLocationState = makeListScreenLocationData(
                updateObject.paging,
                updateObject.filters,
                undefined,
                storeState.listTransformations
            );

            // get location code by PLS made above
            const code = await getLinkCode(
                makeValidLocationState(newLocationState),
                location.pathname,
                `?${makeFullParamsFromLocationParams(newLocationState.params).toString()}`
            );

            if (!code) {
                console.error(
                    'Nie można zapisać filtra, nie da się wyciągnąć kodu aktualnej lokacji'
                );
                return;
            }

            const saveResult = await saveFilter(screen, code, name);

            if (saveResult.success) {
                dispatch(fetchAndLoadUtilitiesBarActions(screen, 'ekran_obiektow'));
            } else {
                console.error(`saveFilter(): error: ${saveResult.message}`);
            }
        },
        [storeState.listTransformations, dispatch, location.pathname]
    );
};
