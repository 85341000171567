import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { TemplateItem } from 'src/utils/src/shared/TemplateItem';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { TemplateGlobalManager } from 'src/utils/src/shared/TemplateGlobalManager';

type TemplateDolarProps = {
    temporaryAllString: string;
    templateItem: TemplateItem;
    rowData: RowData[];
    rowId?: RowDataId | null;
};

export class TemplateDolar extends TemplateGlobalManager {
    temporaryAllString: string;
    templateItem: TemplateItem;
    rowData: RowData[];
    rowId?: RowDataId | null;
    constructor({ templateItem, rowData, rowId, temporaryAllString }: TemplateDolarProps) {
        super();
        this.templateItem = templateItem;
        this.temporaryAllString = temporaryAllString;
        this.rowData = rowData;
        this.rowId = rowId;
    }
    getData() {
        const rawValue = new TableDataManager({
            rowData: this.rowData,
            rowId: this.rowId
        }).getRawValues(this.templateItem);
        return this.replaceContent({
            contentToReplace: rawValue.join(','),
            templateItem: this.templateItem,
            temporaryAllString: this.temporaryAllString
        });
    }
}
