import { createTheme } from '@mui/material/styles/';
import { plPL } from '@mui/material/locale';

declare module '@mui/material/styles/createPalette' {
    export interface PaletteOptions {
        type: string;
    }
}

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        themeColor: {
            light: string;
            main: string;
            dark: string;
        };
    }
    interface TypeText {
        hint: string;
    }

    // extend interfaces to add custom 'white' color
    interface Palette {
        white: {
            main: string;
        };
    }
    interface PaletteOptions {
        white: {
            main: string;
        };
    }
}

// enable Icon component to use 'white' palette color
declare module '@mui/material/Icon' {
    interface IconPropsColorOverrides {
        white: true;
    }
}

export const createCustomTheme = (mainColor: string) => {
    return createTheme(
        {
            palette: {
                type: 'light',
                primary: {
                    main: mainColor
                },
                secondary: {
                    main: '#000000'
                },
                success: {
                    main: '#1a911a'
                },
                error: {
                    main: '#c52f2f'
                },
                // custom palette color
                white: {
                    main: '#ffffff'
                },
                divider: mainColor,
                background: {
                    default: '#ffffff',
                    paper: '#f6f6f6'
                },
                text: {
                    primary: '#000000',
                    secondary: mainColor,
                    disabled: '#DEDEDE',
                    hint: '#B9B9B9'
                }
            },
            typography: {
                h1: {
                    fontSize: '0.875rem', // 14 px
                    color: mainColor
                },
                h2: {
                    fontSize: '0.875rem' // 14 px
                },
                h3: {
                    fontSize: '0.75rem' // 12 px
                },
                h4: {
                    fontSize: '0.75rem' // 12 px
                },
                h5: {
                    fontSize: '0.75rem' // 12 px
                },
                h6: {
                    fontSize: '0.75rem' // 12 px
                },
                body1: {
                    fontSize: '0.75rem' // 12 px
                },
                subtitle1: {
                    fontSize: '0.625rem' // 10 px
                },
                subtitle2: {
                    fontSize: '0.5rem' // 8px
                },
                fontFamily: 'Roboto'
            },
            themeColor: {
                light: '#ffffff',
                main: mainColor,
                dark: '#000000'
            }
        },
        plPL
    );
};

export type ThemeType = ReturnType<typeof createTheme>;
