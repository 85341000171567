import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { AppDispatch, fetchAndHandleCalendarDataConditonally } from 'src/store/src/calendar';
import { useLoadInitialData } from 'src/hooks/src/shared/useLoadInitialDataCalendar'; //Fixme
import { useSetInitialDays } from 'src/hooks/src/calendar/useSetInitialDays';
import { useInterval } from 'src/hooks/src/shared/useInterval';
import { useObjectCreationHandler } from 'src/hooks/src/calendar/useObjectCreationHandler';
import { loadInitialData } from 'src/store/src/calendar';
import { setOnLoad, fetchAndHandleCalendarData } from 'src/store/src/calendar';
const Calendar = React.lazy(
    () => import(/* webpackChunkName: "Calendar" */ '../../../calendar/pages/calendar/Calendar')
);

const CalendarTop = () => {
    const dispatch = useDispatch<AppDispatch>();
    const dispatchApp = useAppDispatch();
    const loadOnFocus = useTypedSelector((state) => state.calendar.loadOnFocus);
    // useCallback to not trigger useEffect reruns
    const loadData = useCallback(
        () => dispatch(fetchAndHandleCalendarDataConditonally()),
        [dispatch]
    );

    // load all data once after mounting

    // prevent confirmation dialog upon exit
    useEffect(() => {
        window.onbeforeunload = null;
    }, []);

    useLoadInitialData(dispatch);

    // caculate and start initial calendar days
    useSetInitialDays(dispatch);

    // mount globally function called by another windows after object creation
    useObjectCreationHandler(dispatch);

    // load calendar events in interval
    useInterval(loadData, 120);

    useEffect(() => {
        const focus = () => {
            if (loadOnFocus) {
                dispatch(fetchAndHandleCalendarData());
                dispatchApp(setOnLoad(false));
            }
        };
        window.addEventListener('focus', focus);
        return () => {
            window.removeEventListener('focus', focus);
        };
    }, [loadOnFocus]);

    return (
        <>
            <React.Suspense fallback>
                <Calendar />
            </React.Suspense>
        </>
    );
};

export default CalendarTop;
