import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVIGATION_START_POINT_SET_KEY } from 'src/constants';
import { useAppDispatch } from 'src/store';
import { setLoggedOffUser } from 'src/store/src/general';
import { closePopup } from 'src/store/src/popup';
import { getCompatibleUrl } from 'src/utils';

export const useLogout = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        dispatch(setLoggedOffUser());
        sessionStorage.removeItem(NAVIGATION_START_POINT_SET_KEY);
        localStorage.removeItem('GT');
        localStorage.setItem('autologout', '1');
        navigate(getCompatibleUrl('logout.php'));
        dispatch(closePopup());
    }, [dispatch, navigate]);
};
