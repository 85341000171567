import { isNonNullChain } from 'typescript';
import { BaseApiResponse } from '../../types';
import { simpleQueryServerData } from '../simpleQueryServerData';

/**
 * Fetches information about messeges screen id, to render messages section in menu
 */
export async function fetchMessagesScreenStatus(): Promise<
    BaseApiResponse<{ isMessageScreenVisible: boolean; id: number }>
> {
    return simpleQueryServerData('api_react/src/menu/getMessagesScreenStatus.php');
}
