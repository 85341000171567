import React from 'react';
import { Box } from '@mui/material';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import UserIcon from 'src/assets/ikona_szukaj.svg';
import { topMenu } from 'src/constants/subtitles';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';
import styles from './SearchBar.module.css';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from 'src/store';

const SearchBar = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const userType = useTypedSelector((store) => store.user.userType);

    const searchFrase = React.useRef<string>();

    const redirect = (event: React.BaseSyntheticEvent, key = 'Enter') => {
        if (['click', 'keypress'].includes(event.type) === true && key === 'Enter') {
            if (searchFrase?.current && searchFrase.current.length > 0) {
                const link = `/index.html?query_global_search=${encodeURIComponent(
                    searchFrase.current
                )}&php_url=global_search.php`;
                navigate(link);
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            {userType !== 'paneledostepowe' && (
                <div className={styles.wrapperInner}>
                    <div className={styles.wrapperLoop}>
                        <span className={styles.span} onClick={redirect}>
                            <ThemeCompatibleIcon
                                SvgComponent={UserIcon}
                                fontSize={'medium'}
                                sx={{ width: '18px', height: 'auto', marginTop: '4px' }}
                            />
                        </span>
                    </div>
                    <InputBase
                        size="small"
                        placeholder={topMenu.input.searchBar}
                        onChange={(event) => {
                            searchFrase.current = event.target.value;
                        }}
                        sx={{
                            border: `solid 1px ${theme.palette.primary.main}`,
                            borderRadius: '3px',
                            backgroundColor: '#fff',
                            boxShadow: '0px 2px 6px #00000080',
                            width: '166px',
                            height: '28px',
                            paddingTop: '6px',
                            paddingLeft: '8px',
                            marginLeft: '5px',
                            fontSize: '12px',
                            'input::placeholder': {
                                textTransform: 'lowercase',
                                fontSize: '12px',
                                color: '#B9B9B9'
                            },
                            '&:focus': {
                                border: 'none !important'
                            }
                        }}
                        onKeyPress={(event) => {
                            redirect(event, event.key);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default SearchBar;
