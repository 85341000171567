import React, { FC } from 'react';
import { DynamicNumber } from 'src/components/shared/atoms/dynamicNumber/DynamicNumber';
import { DynamicTimeHour } from 'src/components/shared/atoms/dynamicTimeHour/DynamicTimeHour';
import { DynamicCheckbox } from 'src/components/shared/atoms/dynamicCheckbox/DynamicCheckbox';
import { DynamicText } from 'src/components/shared/atoms/dynamicText/DynamicText';
import { DynamicList } from 'src/components/shared/atoms/dynamicList/DynamicList';
import { UiWrapperField } from 'src/components/shared/atoms/uiLayout/uiWrapperField/UiWrapperField';
import { DynamicFieldProps } from 'src/components/shared/molecules/dynamicField/types';
import { DynamicDateHour } from '../../atoms/dynamicDateHour/DynamicDateHour';

export const DynamicField: FC<DynamicFieldProps> = (props) => {
    if (props.ukryte === 1) {
        return <></>;
    }
    switch (props.typ) {
        case 'liczba':
            return (
                <UiWrapperField width={props.width}>
                    <DynamicNumber {...props} />
                </UiWrapperField>
            );
        case 'data_godzina':
            return (
                <UiWrapperField width={props.width}>
                    <DynamicTimeHour {...props} />
                </UiWrapperField>
            );
        case 'lista':
            return (
                <UiWrapperField width={props.width}>
                    <DynamicList {...props} />
                </UiWrapperField>
            );
        case 'checkbox':
            return (
                <UiWrapperField width={props.width}>
                    <DynamicCheckbox {...props} />
                </UiWrapperField>
            );
        case 'tekst':
            return (
                <UiWrapperField width={props.width}>
                    <DynamicText {...props} />
                </UiWrapperField>
            );
        case 'data_godziny':
            return (
                <UiWrapperField width={props.width}>
                    <DynamicDateHour {...props} />
                </UiWrapperField>
            );

        default:
            return <></>;
    }
};
