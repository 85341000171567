import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTypedSelector } from 'src/store';

/**
 * Handle page head content considering app store with configuration from server
 */

const HeadManager = () => {
    const { systemName } = useTypedSelector((state) => state.system);

    return (
        <HelmetProvider>
            <Helmet>{systemName && <title>{systemName}</title>}</Helmet>
        </HelmetProvider>
    );
};

export default HeadManager;
