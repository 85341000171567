import * as actionTypes from './actionTypes';

const initialState = {
    tasks: [],
    events: [],
    open: false,
    allLoaded: false,
    page: 1
};

export default (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.SET_EVENTS: {
            const events = payload.data;
            return {
                ...state,
                events: events || []
            };
        }

        case actionTypes.LOAD_TASKS: {
            const tasks = payload.data;
            return {
                ...state,
                tasks: state.tasks.concat(tasks || []),
                page: state.page + 1
            };
        }
        case actionTypes.TOGGLE_OPEN:
            return {
                ...state,
                open: !state.open
            };

        case actionTypes.SET_ALL_LOADED:
            return {
                ...state,
                allLoaded: true
            };

        case actionTypes.REFRESH_LOAD_STATE:
            return {
                ...state,
                allLoaded: false,
                page: 1,
                tasks: []
            };

        default:
            return state;
    }
};
