import React, { FC } from 'react';
import styles from './NormalScroll.module.css';
import { NormalScrollProp } from 'src/components/shared/molecules/normalScroll/types';

export const NormalScroll: FC<NormalScrollProp> = ({
    children,
    height,
    width,
    styleOuterWrapper,
    styleInnerWrapper
}) => {
    return (
        <div
            style={{
                height: `${height}px`,
                width: `${width}px`,
                ...styleOuterWrapper
            }}
            className={styles.wrapper}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    ...styleInnerWrapper
                }}>
                {children}
            </div>
        </div>
    );
};
