export const topMenu = {
    tooltipOnHover: {
        pin: 'Dodaj do Menu',
        chat: 'Chat',
        todo: 'Lista To-Do',
        notes: 'Notes',
        timer: 'Licznik czasu'
    },
    button: {
        pinSection: 'Zapisz',
        myReport: {
            run: 'Generuj',
            cancel: 'Anuluj'
        }
    },
    input: {
        myReport: {
            first: 'Od',
            second: 'Do'
        },
        searchBar: 'Szukaj globalnie'
    },
    options: {
        userSection: {
            one: 'Moje Dane',
            two: 'Mój Raport',
            last: 'Wyloguj'
        }
    }
};

export const listScreen = {
    objectParamsError: 'Nieprawdiłowy adres, brak parametru "obiekt"',
    paginationBar: {
        left: {
            textOne: 'Ilość elementów na stronie:',
            textTwo: ' elementów'
        },
        right: {
            textOne: 'pierwsza',
            textTwo: 'ostatnia  ',
            textThree: ' Wybierz'
        }
    },
    getSearchFilterName: 'Nadaj nazwe wyszukania'
};

export const general = {
    defaultFileName: 'Plik',
    getLinkPrompt: 'Skopiuj do schowka: Ctrl+C, Enter',
    redirecting: 'Przekierowywanie...',
    missingCodeParameter: 'Brak parametru "code" '
};

export const globalPopup = {
    contentDelete: {
        one: {
            title: 'Usuwanie obiektu',
            content: 'Czy usunąć obiekt?'
        },
        many: {
            title: 'Usuwanie obiektów',
            content: 'Czy usunąć obiekty?'
        }
    },
    contentDeleteConfirm: {
        one: {
            title: 'Potwierdzenie usunięcia',
            content: 'Operacja jest nieodwracalna. Na pewno?'
        },
        many: {
            title: 'Potwierdzenie usunięcia',
            content: 'Operacja jest nieodwracalna. Na pewno?'
        }
    },
    contentSentInvoiceMail: {
        one: {
            title: 'Wyślij',
            content: 'Wybierz załączniki',
            input: {
                invoice: 'Faktura',
                invoiceAndReport: 'Faktura i raport'
            },
            button: {
                yes: 'Wyślij'
            }
        }
    },
    contentConfirmation: {
        title: 'Potwierdzenie',
        button: {
            yes: 'Tak',
            no: 'Nie'
        }
    },
    contentInfo: {
        title: 'Informacja',
        button: {
            yes: 'Ok'
        }
    },
    contentDynamic: {
        button: {
            yes: 'Zatwierdź'
        }
    },
    contentCompleteTask: {
        one: {
            title: 'Zrealizuj zadanie',
            content: 'Nazwa zadania:',
            input: {
                date: 'Data',
                timeAbsolute: 'Godzina',
                timeRelativeDecimal: 'Poświęcony czas (0,000 h)',
                timeRelativeNormal: 'Poświęcony czas (min)',
                realised: 'Zrealizowano'
            },
            button: {
                yes: 'Zrealizuj zadanie',
                no: 'Anuluj'
            }
        }
    },
    contentArchive: {
        one: {
            title: 'Archiwizowanie obiektu',
            content: 'Czy zaarchiwizować obiekt?'
        },
        many: {
            title: 'Archiwizowanie obiektów',
            content: 'Czy zaarchiwizować obiekty?'
        }
    },
    contentAlert: {
        title: 'Komunikat',
        button: {
            yes: 'Ok'
        }
    },
    contentDefault: {
        title: 'Przepraszamy zawartość chwilowo niedostępna'
    },
    checkTime: {
        content: 'Odliczanie do wylogowania z systemu:',
        content2: 'Jeżeli chcesz kontynuować pracę w systemie kliknij poniżej:',
        button: ' Odśwież sesje'
    }
};

export const dataPicker = {
    error: 'Nieprawidłowe dane',
    checkbox: {
        label: 'Dziś'
    }
};
