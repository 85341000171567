import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { AppThunk, RootState } from '../';
import { fetchResources } from '../../../../api';
import { resetApp } from '../sharedActions';
import { Resource, Department, ResourcesState, NonSelectableEmploye } from './types';
import { createDepartmentForUnassignedEmployees, makeCastPropertiesAsInt } from '../../../../utils';

export const initialState: ResourcesState = {
    departments: [],
    resources: [],
    allEmployees: []
};

const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        setAllEmployees: (state, action: PayloadAction<NonSelectableEmploye[]>) => {
            state.allEmployees = action.payload;
        },
        setResources: (state, action: PayloadAction<Resource[]>) => {
            state.resources = action.payload;
        },

        setDepartments: (state, action: PayloadAction<Department[]>) => {
            state.departments = action.payload;
        },

        toggleResource: (state, action: PayloadAction<number>) => {
            const resource = state.resources.find((e) => e.id === action.payload);

            if (!resource) return;

            resource.selected = !resource.selected;
        },
        toggleDepartment: (state, action: PayloadAction<number>) => {
            const department = state.departments.find((d) => d.id === action.payload);

            if (!department) return;

            // next dragEventState
            const nextState = !department.selected;

            // serve all employees that aren't in another selected department
            state.resources.forEach((resource) => {
                // this shouldnt happen but handle this case anyway
                if (!resource.departments) return;

                // if resource from outside department
                if (!resource.departments.includes(department.id)) return;

                // if next dragEventState is selected, just select all department resources
                if (nextState) {
                    resource.selected = nextState;
                    return;
                }

                // if action is to deselect, affect only employees that are not in another selected department
                const cantDeselect = resource.departments
                    // off all resource's departments exclude current department
                    .filter((depID) => depID !== department.id)
                    // find out if there is department seleceted
                    .some(
                        (depID) => state.departments.find((d) => d.id === depID)?.selected || false
                    );

                // do not touch those resources
                if (cantDeselect) return;

                // now its safe to update
                resource.selected = nextState;
            });

            // update department
            department.selected = nextState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, () => {
            return initialState;
        });
    }
});

export const { setDepartments, setResources, toggleDepartment, toggleResource, setAllEmployees } =
    resourcesSlice.actions;

export default resourcesSlice.reducer;

/* Thunk Actions */

// prepare mapping function which replace strings id with numbers
// Fucking php, can't handle db integers as integers and cast them as strings
const castIdAsInt = makeCastPropertiesAsInt('id');

export const handleResources =
    (
        resources: Resource[],
        allEmployees: NonSelectableEmploye[],
        departments?: Department[]
    ): AppThunk =>
    async (dispatch) => {
        const departmentsExists = departments && departments.length;

        /* If departments exists then it is employees fetch, not reservable assets */
        if (departmentsExists) {
            [departments, resources] = createDepartmentForUnassignedEmployees(
                departments!,
                resources
            );
        }

        batch(() => {
            dispatch(setResources(resources.map(castIdAsInt as any)));
            dispatch(setAllEmployees(allEmployees.map(castIdAsInt as any)));
            departmentsExists && dispatch(setDepartments(departments!.map(castIdAsInt as any)));
        });
    };

export const fetchAndHandleResources = (): AppThunk => async (dispatch, getState) => {
    try {
        const { variableJMK, entityID } = getState().calendar;
        const data = await fetchResources(variableJMK.type, entityID);
        if ('errorCode' in data) throw data;

        let { allEmployees, resources, departments } = data;

        dispatch(handleResources(resources, allEmployees, departments));
    } catch (e: any) {
        if ('errorCode' in e) console.error('error status:', e.errorCode);
        console.error('fetch resources:', e.message);
    }
};

/* SELECTORS */
const getDepartments = (state: RootState) => state.resources.departments;
const getChosenDepartment = (state: RootState, id: number) =>
    state.resources.departments.find((d) => d.id === id);
const getResources = (state: RootState) => state.resources.resources;

export const getSelectedDepartments = createSelector(getDepartments, (departments) => {
    return departments.filter((dep) => dep.selected);
});

export const getSelectedResources = createSelector(getResources, (resources) => {
    return resources.filter((r) => r.selected);
});

export const makeGetDepartmentResources = () =>
    createSelector(getChosenDepartment, getResources, (department, resources) => {
        if (!department) return [] as Resource[];
        return resources.filter((r) => r.departments?.includes(department.id));
    });
