import React, { FC } from 'react';
import { DynamicFieldProps } from 'src/components/shared/molecules/dynamicField/types';
import { UiInputSelect } from 'src/components/shared/atoms/uiInputs/uiInputSelect/UiInputSelect';

export const DynamicList: FC<DynamicFieldProps> = (props) => {
    return (
        <UiInputSelect
            value={props.field.value}
            label={props.nazwa}
            opcje={props.opcje}
            options={props.options}
            onChange={props.field.onChange}
        />
    );
};
