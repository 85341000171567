import { DynamicFieldT } from 'src/data/types';
import { DynamicStateForm } from 'src/components/shared/molecules/dynamicForm/DynamicFormHandler';
import { Control } from 'react-hook-form';

type DynamicFormInputProps = {
    field: DynamicFieldT;
    control: Control<DynamicStateForm>;
};

type IfOnlyToDisplay = Pick<DynamicFormInputProps, 'field'>;

export class DynamicFormInput {
    static ifOnlyToDisplay({ field }: IfOnlyToDisplay) {
        return field.modyfikacja === 0;
    }

    static checkIsDependent({ field, control }: DynamicFormInputProps) {
        if (field.pole_widocznosc) {
            control.getFieldState('register');
        }
    }

    static checkIfDisplay({ field, control }: DynamicFormInputProps) {
        if (DynamicFormInput.ifOnlyToDisplay({ field })) return false;
        DynamicFormInput.checkIsDependent({ field, control });
        return true;
    }
}
