import { DynamicFieldT } from 'src/data/types';

type GetFilteredFieldProps = {
    fields: DynamicFieldT[];
    key: keyof DynamicFieldT;
    value: any;
};

export class FieldHelper {
    static getFilteredFields({ fields, key, value }: GetFilteredFieldProps) {
        return fields.filter((item) => item[key] === value);
    }
}
