import { BaseApiResponse } from '../../types';
import { simpleQueryServerData } from '../simpleQueryServerData';

/**
 * encode navigation system data to code
 */
export async function maskURL(url: string, locationState: any): Promise<BaseApiResponse<string>> {
    return simpleQueryServerData('api_react/src/shared/maskUrl.php', 'POST', {
        url,
        locationState
    });
}
