import { combineReducers } from '@reduxjs/toolkit';
import statusTableDataSlice from './src/listScreen/statusData/statusDataSlice';
import treeTableDataSlice from 'src/store/src/listScreen/treeData/treeDataSlice';
import calendarSlice from 'src/store/src/calendar/calendar/calendarSlice';
import categoriesSlice from 'src/store/src/calendar/categories/categoriesSlice';
import resourcesSlice from 'src/store/src/calendar/resources/resourcesSlice';
import userSlice from 'src/store/src/general/user/userSlice';
import systemSlice from 'src/store/src/general/system/systemSlice';
import menuSlice from 'src/store/src/menu/menu/menuSlice';
import popSlice from 'src/store/src/popup/popup/popupSlice';
import transformationsSlice from 'src/store/src/listScreen/transformations/transformationsSlice';
import searchFieldsSlice from 'src/store/src/listScreen/searchFields/searchFieldsSlice';
import tableDataSlice from 'src/store/src/listScreen/tableData/tableDataSlice';
import utilitiesbarSlice from 'src/store/src/utilitiesBar/utilitiesBar/utilitiesbarSlice';
import { appReducer, userReducer, chatsReducer } from 'src/widgets/chat/store/reducers';
import todoReducer from 'src/widgets/todo/store/reducer';
import notesReducer from 'src/widgets/notes/store/reducer';
import alertsSlice from './src/general/alerts/alertsSlice';
import progressBarSlice from './src/general/progressBar/progressBarSlice';

const rootReducer = combineReducers({
    user: userSlice,
    system: systemSlice,
    alertsData: alertsSlice,
    progressBar: progressBarSlice,
    menu: menuSlice,
    popup: popSlice,
    utilitiesbar: utilitiesbarSlice,
    // calendar
    categories: categoriesSlice,
    calendar: calendarSlice,
    resources: resourcesSlice,
    // list page
    listTransformations: transformationsSlice,
    listSearchFields: searchFieldsSlice,
    listScreenTableData: tableDataSlice,
    listScreenStatusTableData: statusTableDataSlice,
    listTreeTableData: treeTableDataSlice,
    // widgets
    app: appReducer, // slice integrated for chat widget
    chats: chatsReducer, // slice integrated for chat widget
    chatUser: userReducer, // slice integrated for chat widget
    todo: todoReducer, // slice integrated for todo widget
    notes: notesReducer // slice integrated for notes widget
});

export {
    calendarSlice,
    categoriesSlice,
    resourcesSlice,
    userSlice,
    systemSlice,
    alertsSlice,
    menuSlice,
    popSlice,
    transformationsSlice,
    searchFieldsSlice,
    tableDataSlice,
    statusTableDataSlice,
    progressBarSlice
};
export default rootReducer;
