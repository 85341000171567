import React, { FC } from 'react';

type FrameProps = {
    id: string;
    width: number;
    height: number;
};

const Frame: FC<FrameProps> = ({ id, width, height }) => {
    const src = `./js/ViewerJS/index.html?#../../pobierz_podglad_pliku.php?id=${id}`;

    return (
        <div>
            {
                <iframe
                    id="podglad_pliku_plik"
                    src={src}
                    width={width - 80}
                    height={height - 90}
                    allowFullScreen></iframe>
            }
        </div>
    );
};

export default Frame;
