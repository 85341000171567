import React, { FC } from 'react';
import { DynamicFieldProps } from 'src/components/shared/molecules/dynamicField/types';
import { UiInputNormal } from 'src/components/shared/atoms/uiInputs/uiInputNormal/UiInputNormal';

export const DynamicText: FC<DynamicFieldProps> = ({
    field,
    nazwa,
    isDisabledLabel,
    placeholder
}) => {
    return (
        <UiInputNormal
            value={field.value}
            label={isDisabledLabel ? '' : nazwa}
            placeholder={placeholder}
            onChange={field.onChange}
        />
    );
};
