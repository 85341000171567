import { createUrl } from '../../shared/utils';
import { SET_NOTES } from './actionTypes';

const setNotes = (notes) => ({
    type: SET_NOTES,
    payload: { data: notes }
});

export const fetchInitNotes = (systemUrl) => async (dispatch) => {
    const url = 'api_react/bialakartka.php';
    const response = await fetch(url);
    const data = await response.json();
    dispatch(setNotes(data.bialakartka));
};

export const fetchAddNotes = async (systemUrl, notes) => {
    const formData = new FormData();
    formData.append('bialakartka_tresc', String(notes));
    const url = 'api_react/bialakartka.php';
    const response = await fetch(url, {
        method: 'POST',
        body: formData
    });
    const data = await response.json();
    return data;
};
