import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useWindowSize } from 'react-use-size';

import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import PopupContentConfig from 'src/components/listScreen/organisms/popupContentConfig/PopupContentConfig';
import PopupContentDefault from 'src/components/shared/molecules/popupContentDefault/PopupContentDefault';
import { PopupTypes } from 'src/store/src/popup/popup/types';
import { GlobalPopupProvider } from 'src/context/globalPopup/store';
import { PopupContentConfirmApi } from 'src/components/shared/molecules/popupContentConfirmation/PopupContentConfirmationApi';
import { PopupContentInitialConfirmApi } from 'src/components/shared/molecules/popupContentConfirmation/PopupContentInitialConfirmationApi';
import { PopupContentConfirmLink } from 'src/components/shared/molecules/popupContentConfirmation/PopupContentConfirmationLink';
import { PopupContentInfo } from 'src/components/shared/molecules/popupContentInfo/PopupContentInfo';
import { PopupContentDynamic } from 'src/components/shared/molecules/popupContentDynamic/PopupContentDynamic';
import PopupContentSearch from 'src/components/listScreen/organisms/popupContentSearch/PopupContentSearch';
import { PopupContentAlert } from 'src/components/shared/molecules/popupContentAlert/PopupContentAlert';
import PopupContentPreview from 'src/components/listScreen/organisms/popupContentPreview/PopupContentPreview';
import GlobalPopupCross from 'src/components/shared/atoms/globalPopupCross/GlobalPopupCross';
import { usePressKey } from 'src/hooks/src/shared/usePressKey';
import styles from './GlobalPopup.module.css';
import global from 'src/styles/globalClass.module.css';

export type PopupContentProps = {
    widthAvailable: number;
    heightAvailable: number;
};

const types: { [key in PopupTypes]: React.FC<PopupContentProps> } = {
    config: PopupContentConfig,
    initialConfirmationApi: PopupContentInitialConfirmApi,
    confirmationApi: PopupContentConfirmApi,
    confirmationLink: PopupContentConfirmLink,
    info: PopupContentInfo,
    dynamic: PopupContentDynamic,
    default: PopupContentDefault,
    search: PopupContentSearch,
    alert: PopupContentAlert,
    preview: PopupContentPreview
};

const GlobalPopup = () => {
    const { height: heightWindow, width: widthWindow } = useWindowSize();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const popup = useTypedSelector((state) => state.popup);
    const PopupContent = types[popup.type] ? types[popup.type] : types['default'];

    usePressKey(handleESC);

    const close = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (popup.isPossibleClose) {
            dispatch(closePopup());
        }
    };

    function handleESC(e: KeyboardEvent) {
        if (e.keyCode === 27) {
            dispatch(closePopup());
        }
    }

    React.useEffect(() => {
        function handleMouse(e: MouseEvent) {
            if (e.button === 3 || e.button === 4) {
                e.preventDefault();
            }
        }
        window.addEventListener('mouseup', handleMouse);
        return function cleanupListener() {
            window.removeEventListener('mouseup', handleMouse);
        };
    }, []);
    return (
        <div className={`${styles.wrapper} ${global.p12}`} onMouseDown={(e) => close(e)}>
            <div
                style={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    background: theme.palette.background.default
                }}
                className={styles.wrapperInner}
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}>
                <GlobalPopupProvider>
                    <PopupContent
                        widthAvailable={widthWindow - 30}
                        heightAvailable={heightWindow - 30}
                    />
                </GlobalPopupProvider>
                {popup.isPossibleClose && (
                    <div className={styles.boxClose} onClick={(e) => close(e)}>
                        <GlobalPopupCross />
                    </div>
                )}
            </div>
        </div>
    );
};

export default GlobalPopup;
