import React, { FC, useEffect } from 'react';
import { Routes as ReactRouterRoutes, Route, useLocation } from 'react-router-dom';
import PHPMaper from 'src/components/shared/pages/phpMapper/PhpMapper';
import LinkResolver from '../linkResolver/LinkResolver';
import AutoHideableMainMenu from '../autoHideableMainMenu/AutoHideableMainMenu';
import MessageDiePage from 'src/components/shared/pages/messageDiePage/MessageDiePage';
import CalendarTop from 'src/components/calendar/pages/calendar/CalendarTop';
import ObjectList from 'src/components/listScreen/pages/objectList/ObjectList';

const Routes: FC<{ basename: string }> = ({ basename }) => {
    //!!! commented because it is not necessary now, but it can be usefull i narrow future
    // const location = useLocation();

    // set globally Location so non react components can affect browser history
    // useEffect(() => {
    //     window.reactLocation = location;
    //     return () => {
    //         window.reactLocation = undefined;
    //     };
    // }, [location]);

    return (
        <ReactRouterRoutes>
            <Route path="/link" element={<LinkResolver />} />
            <Route path="/" element={<AutoHideableMainMenu />}>
                <Route path="/message" element={<MessageDiePage />} />
                <Route path="/kalendarz" element={<CalendarTop />} />
                <Route path="/lista" element={<ObjectList view={'listy'} />} />
                <Route path="/lista-drzewo" element={<ObjectList view={'drzewo'} />} />
                <Route path="/lista-statusy" element={<ObjectList view={'statusy'} />} />
                <Route path="/" element={<PHPMaper basename={basename} />} />
                <Route path="*" element={<PHPMaper basename={basename} />} />
            </Route>
        </ReactRouterRoutes>
    );
};

export default Routes;
