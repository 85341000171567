import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export { systemSlice, userSlice, alertsSlice } from '../../rootReducer';
export { loadInitialData } from '../shared/actions';

export { setSystemConfig, setInitialDataLoaded } from './system/systemSlice';
export { setLoggedOffUser } from './user/userSlice';
export { setAlerts, clearFirst } from './alerts/alertsSlice';
export { setProgress } from './progressBar/progressBarSlice';
