import React from 'react';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { SHOW_INACTIVITY_LOGOUT_MESSAGE_KEY } from 'src/constants';
import { ApiError } from 'src/utils';
import { CheckTimeTimer } from './checkTimeTimer/CheckTimeTimer';

export const CheckTime = () => {
    const { timeToLogout, isLogged } = useTypedSelector((state) => state.user);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (sessionStorage.getItem(SHOW_INACTIVITY_LOGOUT_MESSAGE_KEY) === '1') {
            sessionStorage.removeItem(SHOW_INACTIVITY_LOGOUT_MESSAGE_KEY);
            ApiError.handle({
                dispatch,
                err: { message: 'Wylogowano z powodu zbyt długiej nieaktywności' }
            });
        }
    }, [dispatch]);

    return isLogged && timeToLogout && Number(timeToLogout) > 0 ? <CheckTimeTimer /> : null;
};
