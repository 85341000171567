import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export { calendarSlice, categoriesSlice, userSlice } from '../../rootReducer';
export {
    VIEW_LABELS,
    setDate,
    setView,
    nextDate,
    prevDate,
    setDays,
    setFrom,
    setLoading,
    setTo,
    setMonth,
    setYear,
    setEvents,
    setGridEvents,
    setNoGridEvents,
    fetchAndHandleCalendarDataDebounced,
    fetchAndHandleCalendarData,
    fetchAndHandleCalendarDataConditonally,
    handleCalendarData,
    handleDateChange,
    handleViewChange,
    setEnteredHours,
    handleDays,
    setSelectedEvent,
    cancelSelectedEvent,
    setSelectedRow,
    fetchAndHandleCalendarDataWhenPending,
    setIcalUrl,
    setOnLoad
} from './calendar/calendarSlice';

export {
    clearAllSelected,
    deselectCategory,
    getSelectedCategories,
    selectCategory,
    setCategories,
    toggleCategory,
    handleCategories,
    fetchAndHandleCategories,
    makeIsCategoryByNameSelected
} from './categories/categoriesSlice';

export {
    getSelectedDepartments,
    getSelectedResources,
    makeGetDepartmentResources,
    setDepartments,
    setResources,
    toggleDepartment,
    toggleResource,
    setAllEmployees,
    handleResources,
    fetchAndHandleResources
} from './resources/resourcesSlice';

export { loadInitialData } from 'src/store/src/calendar/sharedActions';
