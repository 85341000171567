import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, shadows, spacing }) => ({
    widget_root: {
        position: 'fixed',
        top: (props) => 50 + (props.order * spacing(4.8) + props.order * 5),
        right: 0,
        zIndex: (props) => (props.open ? 50001 : 50000),
        borderRadius: '4px 0 0 0',
        outline: 'none'
    }
}));

const ContainerBtn = ({ order, open, children }) => {
    const classes = useStyles({ order, open });
    return <div className={classes.widget_root}>{children}</div>;
};
export default ContainerBtn;
