import {
    SET_DATA,
    TOGGLE_OPEN,
    LOAD_TASKS,
    SET_EVENTS,
    SET_ALL_LOADED,
    REFRESH_LOAD_STATE
} from './actionTypes';
import { createUrl } from '../../shared/utils';

const setAllLoaded = () => ({
    type: SET_ALL_LOADED
});

const refresLoadState = () => ({
    type: REFRESH_LOAD_STATE
});

const loadTasks = (tasks) => ({
    type: LOAD_TASKS,
    payload: { data: tasks },
    meta: {
        pg_bools: ['zalegle']
    }
});

const setEvents = (events) => ({
    type: SET_EVENTS,
    payload: { data: events },
    meta: {
        pg_bools: ['zalegle']
    }
});

export const fetchEvents = (systemUrl) => (dispatch) => {
    const url = 'api_react/todo.php?type=1';

    fetch(url)
        .then((res) => res.json())
        .then((data) => dispatch(setEvents(data.events)))
        .catch((err) => console.error(err.message));
};

export const fetchTasks = (systemUrl, restoreScroll, forceRefresh) => (dispatch, getState) => {
    if (forceRefresh) {
        dispatch(refresLoadState());
    }

    const { allLoaded, page } = getState().todo;

    if (allLoaded) return false;

    const url = `api_react/todo.php?type=0&page=${page}`;

    fetch(url)
        .then((res) => res.json())
        .then((data) => {
            const tasks = data.tasks;
            if (tasks.length) dispatch(loadTasks(data.tasks));
            /* if tasks length is less than 20, that means there are no more tasks to fetch in db  */
            if (tasks.length < 20) {
                dispatch(setAllLoaded());
            } else {
                restoreScroll && typeof restoreScroll === 'function' && restoreScroll();
            }
        })
        .catch((err) => {
            console.error(err.message);
        });
};

export const toggleOpen = () => ({ type: TOGGLE_OPEN });
