import { AppDispatch } from 'src/store';
import { setPopupWithoutData, setPopup } from 'src/store/src/popup';
import { SystemAction, PopupBehavior, ApiActionBehavior } from 'src/data/types';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
// PopupManager obsługe slice popup w redux, czyli w praktyce ustawia popup z danymi lub bez
// PopupManager menage slice popup in redux, in practice set popup with or without data

type PopupManagerProps = {
    dispatch: AppDispatch;
    action: SystemAction | null;
};

export class PopupManager {
    dispatch: AppDispatch;
    action: SystemAction | null;
    constructor({ dispatch, action }: PopupManagerProps) {
        this.dispatch = dispatch;
        this.action = action;
    }

    setPopupInfo(message: string) {
        this.dispatch(
            setPopup({
                type: 'info',
                message: message
            })
        );
    }

    setPopupTemplate(behaviour: PopupBehavior, rowId: RowDataId | null) {
        if (behaviour.data.template === 'preview') {
            this.dispatch(
                setPopup({
                    type: behaviour?.data?.template,
                    objectId: rowId ? [rowId] : [],
                    action: this.action
                })
            );
        } else {
            this.dispatch(
                setPopupWithoutData({
                    type: behaviour?.data?.template
                })
            );
        }
    }

    setPopupDynamic({ rowsId, title }: { rowsId: RowDataId[]; title: string }) {
        this.dispatch(
            setPopup({
                type: 'dynamic',
                action: this.action,
                objectId: rowsId,
                title: title
            })
        );
    }

    setPopupConfirmLink({ rowId }: { rowId: RowDataId[] }) {
        this.dispatch(
            setPopup({
                type: 'confirmationLink',
                objectId: rowId,
                action: this.action
            })
        );
    }

    setPopupConfirmApi({ rowId, behaviour }: { rowId: RowDataId[]; behaviour: ApiActionBehavior }) {
        if (behaviour?.data?.secondConfirmText) {
            this.dispatch(
                setPopup({
                    type: 'initialConfirmationApi',
                    objectId: rowId,
                    action: this.action
                })
            );
        } else {
            this.dispatch(
                setPopup({
                    type: 'confirmationApi',
                    objectId: rowId,
                    action: this.action
                })
            );
        }
    }
}
