import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UtilitiesBarState } from './types';
import { AppThunk } from '../../calendar';
import { UtilitiesBarPages } from 'src/api/src/shared/types';
import { fetchUtilitiesBarOptions } from 'src/api/src/shared/fetchUtilitiesBarOptions';
import { makeErrorText, ApiError } from 'src/utils';
import { UtilitiesBarManager } from 'src/store/src/utilitiesBar/utilitiesBar/UtilitiesbarManager';
import { SystemAction } from 'src/data/types';

export const initialState: UtilitiesBarState = {
    actions: []
};

const utilitiesBarSlice = createSlice({
    name: 'utilitiesbar',
    initialState,
    reducers: {
        setActions: (state: UtilitiesBarState, action: PayloadAction<SystemAction[]>) => {
            state.actions = action.payload;
        }
    }
});

export const fetchAndLoadUtilitiesBarActions =
    (screen: string, pageType: UtilitiesBarPages): AppThunk =>
    async (dispatch) => {
        // fetch api
        const apiResponse = await fetchUtilitiesBarOptions(screen, pageType);
        if (!apiResponse.success) {
            ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
            throw new Error(makeErrorText(apiResponse));
        }

        // set data
        const data = UtilitiesBarManager.prepareDataFromApi({ systemAction: apiResponse.data });
        dispatch(setActions(data));
    };

export const { setActions } = utilitiesBarSlice.actions;

export default utilitiesBarSlice.reducer;
