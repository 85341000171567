import { Location, NavigateFunction } from 'react-router-dom';
import { getAternativeViewUrlData } from '../navigationSystem/getAternativeViewUrlData';
import { makeUrlString } from '../shared/makeUrlString';

export const navigateToPreparedComponent = (
    queryParams: URLSearchParams,
    componentPath: string,
    navigate: NavigateFunction,
    location: Location
) => {
    queryParams.delete('php_url');

    const { queryParams: changedQueryParams, url } = getAternativeViewUrlData(
        componentPath,
        queryParams
    );
    navigate(makeUrlString(url, changedQueryParams), {
        state: location.state,
        replace: true
    });
};
