import { ConvertableToParams } from 'src/data/types';
import { checkIfValueIsTypedArray } from '../shared/checkIfValueIsTypedArray';

export const convertParamsObject = (paramsObject: ConvertableToParams) => {
    // not used simply for with urlSearcParams .set() because it not set multiple values for that
    //same key, and system should be compatible with php way to save arrays in search prarms i.e.
    // ?cars[]=Saab&cars[]=Audi

    let paramsString = '?';

    for (const [key, value] of Object.entries(paramsObject)) {
        // handle array params saving
        if (checkIfValueIsTypedArray<number | string>(value)) {
            for (const element of value) {
                paramsString += `${key}[]=${element}&`;
            }
        } else {
            paramsString += `${key}=${value}&`;
        }
    }

    return new URLSearchParams(paramsString);
};
