import React, { FC } from 'react';
import { useComponentSize } from 'react-use-size';

import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import Frame from 'src/components/listScreen/molecules/frame/Frame';
import { useTypedSelector } from 'src/store';
import { FileManager } from 'src/utils';
import { reduceStr } from 'src/utils';
import styles from './PopupContentPreview.module.css';
import FrameTwo from 'src/components/listScreen/molecules/frame/FrameTwo';

type FrameContent = 'one' | 'two';

export type ContentPreviewItem = {
    rawValue: string;
    text: string;
    isSelected?: boolean;
};

const types: { [key in FrameContent]: React.FC<{ id: string; width: number; height: number }> } = {
    one: Frame,
    two: FrameTwo
};

const PopupContentPreview: FC = () => {
    const { ref, width, height } = useComponentSize();
    const popup = useTypedSelector((state) => state.popup);
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const [itemPreview, setItemPreview] = React.useState<{
        frame: FrameContent;
        id: string;
        title: string;
    }>({
        frame: 'one',
        id: '',
        title: ''
    });
    const [allDataPreview, setAllDataPreview] = React.useState<ContentPreviewItem[]>([]);

    const FrameContent = types[itemPreview.frame] ? types[itemPreview.frame] : types['one'];

    const changeId = (type: 'before' | 'after') => {
        const index = allDataPreview.findIndex((item) => item.isSelected);
        const tempData = [...allDataPreview];

        if (type === 'before' && index) {
            const newId = allDataPreview[index - 1].rawValue;
            const title = allDataPreview[index - 1].text;
            setItemPreview({
                id: newId,
                title,
                frame: itemPreview.frame === 'one' ? 'two' : 'one'
            });
            tempData[index].isSelected = false;
            tempData[index - 1].isSelected = true;
            setAllDataPreview(tempData);
        } else if (type === 'after' && index !== allDataPreview.length - 1) {
            const newId = allDataPreview[index + 1].rawValue;
            const title = allDataPreview[index + 1].text;
            setItemPreview({
                id: newId,
                title,
                frame: itemPreview.frame === 'one' ? 'two' : 'one'
            });
            tempData[index].isSelected = false;
            tempData[index + 1].isSelected = true;
            setAllDataPreview(tempData);
        }
    };

    const downloadFile = async () => {
        const payload = [
            {
                key: 'id',
                value: itemPreview.id
            }
        ];
        const url = 'pobierz_plik.php';
        await FileManager.handlePreviewFile({ url, payload });
    };

    React.useEffect(() => {
        const dataAllPreview = new TableDataManager({
            rowData,
            rowId: popup.listScreen.objectId[0],
            action: popup.listScreen.action
        }).getDataForPreview();
        setAllDataPreview(dataAllPreview);
        const selected = dataAllPreview.find((item) => item.isSelected);
        if (selected) {
            setItemPreview({
                frame: 'two',
                id: selected.rawValue,
                title: selected.text
            });
        }
    }, [rowData, popup]);

    return (
        <div className={styles.wrapper} ref={ref}>
            <div className={styles.bar}>
                {' '}
                <div
                    onClick={() => changeId('before')}
                    style={{
                        cursor: 'pointer'
                    }}>
                    <ThemeCompatibleIcon
                        iconData={{
                            faCode: 'fa-arrow-left',
                            svgFileName: null
                        }}
                        sx={{
                            fontSize: '1.6rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '10px'
                        }}
                    />
                </div>
                <p style={{ fontSize: '16px' }}>{reduceStr(itemPreview.title, 90)}</p>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => downloadFile()}>
                        <ThemeCompatibleIcon
                            iconData={{
                                faCode: 'fa-download',
                                svgFileName: null
                            }}
                            sx={{
                                fontSize: '1.6rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '15px'
                            }}
                        />
                    </div>
                    <div
                        onClick={() => changeId('after')}
                        style={{
                            cursor: 'pointer'
                        }}>
                        <ThemeCompatibleIcon
                            iconData={{
                                faCode: 'fa-arrow-right',
                                svgFileName: null
                            }}
                            sx={{
                                fontSize: '1.6rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: '10px'
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                {itemPreview.id && (
                    <FrameContent id={itemPreview.id} width={width} height={height} />
                )}
            </div>
        </div>
    );
};

export default PopupContentPreview;
