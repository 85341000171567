import { GridEvent } from '../../store/src/calendar/calendar/types';
import { DayEvent, PlaceEvent } from '../types';
import { UserState } from '../../store/src/general/user/types';
import { GridCalcEvent } from 'src/utils';

/**
 * Algoritm finds width and left offset of each event in calendar day.
 * Adjust height and topOffset from 5 minutes slots to half hours divs in calendar view
 * @returns array of placed formatted user events, and boolean flag indicating if there are more events to display than fitted.
 */
export function placeEvents(
    events: DayEvent[],
    userState: UserState,
    limit?: number
): [GridEvent[], boolean] {
    /* Overflow flag, start to true, if some events won't make it to the user */
    let overflow = false;

    let tempEvents: PlaceEvent[] = events.map((ev) => ({
        id: ev.id,
        type: ev.type,
        name: ev.name,
        startHour: ev.startHour,
        endHour: ev.endHour,
        startRow: ev.startRow,
        endRow: ev.endRow,
        endTime: ev.endTime,
        startTime: ev.startTime,
        endDayTimestamp: ev.endDayTimestamp,
        height: ev.height,
        startDayTimestamp: ev.startDayTimestamp,
        left: 0,
        right: 0
    }));

    /* Old code, did not work well with placing events, now limit is passed to placeEvents function */
    // if (limit) {
    // 	tempEvents.forEach((event) => {
    // 		if (event.out) return;
    // 		const collides = tempEvents.filter((ev) => !ev.out && collidesWith(event, ev));
    // 		for (let i = Math.min(limit, collides.length); i < collides.length; i++) {
    // 			collides[i].out = true;
    // 			overflow = true;
    // 		}
    // 	});
    // 	tempEvents = tempEvents.filter(ev => !ev.out);
    // }

    layoutEvents(tempEvents, limit);
    /* Result array, filled with GridEvents in the last loop of algorithm */

    const gridEvents: GridEvent[] = [];

    // format GridEvents
    for (const event of tempEvents) {
        // ignore surplus events
        if (!event.right) {
            overflow = true;
            continue;
        }

        const {
            name,
            endTime,
            startTime,
            endDayTimestamp,
            id,
            startDayTimestamp,
            left,
            right,
            startHour,
            endHour,
            type
        } = event;

        const { offsetTop, startRow, height } = new GridCalcEvent(userState, event).getData();

        /* translation from 5 minutes rows to timeCell rows and some percentage offset */
        // let topOffset: number;
        //
        // switch (startRow % 6) {
        // 	case 0:
        // 		topOffset = 0;
        // 		break;
        // 	case 1:
        // 		topOffset = 16.66;
        // 		break;
        // 	case 2:
        // 		topOffset = 33.33;
        // 		break;
        // 	case 3:
        // 		topOffset = 50.00;
        // 		break;
        // 	case 4:
        // 		topOffset = 66.66;
        // 		break;
        // 	default:
        // 		topOffset = 83.33;
        // 		break;
        // }
        /* Format user event */

        const gridEvent: GridEvent = {
            type,
            endDayTimestamp,
            topOffset: offsetTop,
            // height from 5-minutes rows span to 30-minutes row in percent. Round down to integer, decimal part expressed as topOffset
            // height: Math.floor(height / 6 * 100),
            height: height,
            width: (right - left) * 100,
            // translate row from 5-minutes to 30-minutes
            // startRow: Math.floor(startRow / 6),
            startRow,
            startHour,
            endHour,
            startDayTimestamp,
            id,
            endTime,
            startTime,
            name,
            leftOffset: left * 100
        };
        // push it to result
        gridEvents.push(gridEvent);
    }
    // return formated events, and overflow flag
    return [gridEvents, overflow];
}

/// Pick the left and right positions of each event, such that there are no overlap.
/// Step 3 in the algorithm.
function layoutEvents(events: PlaceEvent[], limit?: number) {
    /* sort it first by event start ascending, then event end descending */
    events.sort((a, b) => {
        let byStart = a.startRow - b.startRow;
        if (byStart === 0) return b.endRow - a.endRow;
        return byStart;
    });

    let columns: PlaceEvent[][] = [];

    let lastEventEnding = -1;
    for (const ev of events) {
        if (ev.startRow >= lastEventEnding) {
            packEvents(columns);
            columns = [];
            lastEventEnding = -1;
        }

        let placed = false;
        for (const col of columns) {
            if (!collidesWith(col[col.length - 1], ev)) {
                col.push(ev);
                placed = true;
                break;
            }
        }
        if (!placed) {
            if (!limit || limit > columns.length) columns.push([ev]);
        }
        if (lastEventEnding === -1 || ev.endRow > lastEventEnding) {
            lastEventEnding = ev.endRow;
        }
    }
    if (columns.length > 0) {
        packEvents(columns);
    }
}

/// Set the left and right positions for each event in the connected group.
/// Step 4 in the algorithm.
function packEvents(columns: PlaceEvent[][]) {
    let numColumns = columns.length;
    let iColumn = 0;
    for (const col of columns) {
        for (const ev of col) {
            let colSpan = expandEvent(ev, iColumn, columns);

            ev.left = iColumn / numColumns;
            ev.right = (iColumn + colSpan) / numColumns;
        }
        iColumn++;
    }
}

/// Checks how many columns the event can expand into, without colliding with
/// other events.
/// Step 5 in the algorithm.
function expandEvent(ev: PlaceEvent, iColumn: number, columns: PlaceEvent[][]) {
    let colSpan = 1;
    for (let i = iColumn + 1; i < columns.length; i++) {
        for (const ev1 of columns[i]) {
            if (collidesWith(ev1, ev)) {
                return colSpan;
            }
        }
        colSpan++;
    }
    return colSpan;
}

function collidesWith(ev1: PlaceEvent, ev2: PlaceEvent): boolean {
    return ev1.startRow <= ev2.endRow && ev1.endRow >= ev2.startRow;
}
