import React, { ChangeEventHandler, MouseEventHandler } from 'react';
import InputMask from 'react-input-mask';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

import { UiInputNormal } from 'src/components/shared/atoms/uiInputs/uiInputNormal/UiInputNormal';
import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';

type InputDateProps = {
    label?: string;
    type: string;
    value: string;
    maskChar: string;
    sxinputdate?: SxProps<Theme> | undefined;
    isicon: 'true' | 'false';
    mask: string | Array<string | RegExp>;
    placeholder: string;
    handleReset: MouseEventHandler<HTMLInputElement>;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur: ChangeEventHandler<HTMLInputElement>;
    onClick: MouseEventHandler<HTMLInputElement>;
    onKeyDown: any;
    clearButton: boolean;
};

export const InputDate = (props: InputDateProps) => {
    return (
        <div style={{ position: 'relative' }}>
            <InputMask alwaysShowMask={false} {...props}>
                {(inputProps: InputMask) => {
                    return (
                        <UiInputNormal
                            {...inputProps}
                            sxinput={{
                                ...props.sxinputdate,
                                input: {
                                    '&::-webkit-input-placeholder': {
                                        paddingLeft: '0px'
                                    },
                                    '&::-ms-input-placeholder': {
                                        paddingLeft: '0px'
                                    }
                                }
                            }}
                        />
                    );
                }}
            </InputMask>
            {props.clearButton && props.value && (
                <ButtonCross
                    onClick={props.handleReset}
                    style={{
                        top: '6px',
                        right: '25px'
                    }}
                />
            )}
        </div>
    );
};
