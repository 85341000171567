import { BaseApiResponse } from '../../types';
import { TreeNode } from 'src/store/src/listScreen/treeData/types';
import { simpleQueryServerData } from 'src/api/src/simpleQueryServerData';

const data_WITH_SECOND_LEVEL = {
    treeNode: [
        {
            values: [
                {
                    header: 'Wspólnik',
                    content: 'Jan Abacki',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=38&obiekt=pracownicy'
                    }
                }
            ],
            children: [
                {
                    values: [
                        {
                            header: 'Szef działu gospodarczego',
                            content: 'Tomasz Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: [
                        {
                            values: [
                                {
                                    header: 'Aplikant',
                                    content: 'Jan Kowalski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: [
                                {
                                    values: [
                                        {
                                            header: 'Aplikant',
                                            content: 'Jan Nowak',
                                            link: {
                                                url: 'ekran_obiektu.php',
                                                params: 'id=37&obiekt=pracownicy'
                                            }
                                        }
                                    ],
                                    children: []
                                }
                            ]
                        },
                        {
                            values: [
                                {
                                    header: 'Aplikant',
                                    content: 'Tomasz Kowalski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: []
                        },
                        {
                            values: [
                                {
                                    header: 'Radca',
                                    content: 'Karolina Michalska',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: []
                        }
                    ]
                },
                {
                    values: [
                        {
                            header: 'Szef działu księgowego',
                            content: 'Adrian Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: [
                        {
                            values: [
                                {
                                    header: 'Aplikant',
                                    content: 'Tomasz Kowalski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: []
                        }
                    ]
                },
                {
                    values: [
                        {
                            header: 'Szef działu prawnego',
                            content: 'Marian Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                },
                {
                    values: [
                        {
                            header: 'Szef działu logistycznego',
                            content: 'Michał Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                }
            ]
        },
        {
            values: [
                {
                    header: 'Wspólnik',
                    content: 'Anna Korwska',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=38&obiekt=pracownicy'
                    }
                }
            ],
            children: [
                {
                    values: [
                        {
                            header: 'Szef działu gospodarczego',
                            content: 'Tomasz Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: [
                        {
                            values: [
                                {
                                    header: 'Aplikant',
                                    content: 'Jan Kowalski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: [
                                {
                                    values: [
                                        {
                                            header: 'Aplikant',
                                            content: 'Jan Nowak',
                                            link: {
                                                url: 'ekran_obiektu.php',
                                                params: 'id=37&obiekt=pracownicy'
                                            }
                                        }
                                    ],
                                    children: []
                                }
                            ]
                        },
                        {
                            values: [
                                {
                                    header: 'Aplikant',
                                    content: 'Tomasz Kowalski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: []
                        },
                        {
                            values: [
                                {
                                    header: 'Radca',
                                    content: 'Karolina Michalska',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: []
                        }
                    ]
                },
                {
                    values: [
                        {
                            header: 'Szef działu księgowego',
                            content: 'Adrian Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: [
                        {
                            values: [
                                {
                                    header: 'Aplikant',
                                    content: 'Tomasz Kowalski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: []
                        }
                    ]
                },
                {
                    values: [
                        {
                            header: 'Szef działu prawnego',
                            content: 'Marian Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                },
                {
                    values: [
                        {
                            header: 'Szef działu logistycznego',
                            content: 'Michał Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                }
            ]
        },
        {
            values: [
                {
                    header: 'Szef',
                    content: 'JMK Administrator',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=38&obiekt=pracownicy'
                    }
                }
            ],
            children: [
                {
                    values: [
                        {
                            header: 'Szef',
                            content: 'JMK Administrator',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                }
            ]
        },
        {
            values: [
                {
                    header: 'Szef',
                    content: 'JMK Administrator',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=38&obiekt=pracownicy'
                    }
                }
            ],
            children: []
        },
        {
            values: [
                {
                    header: 'Szef',
                    content: 'JMK Administrator',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=38&obiekt=pracownicy'
                    }
                }
            ],
            children: []
        }
    ]
};

const dataAll = {
    treeNode: [
        {
            title: 'Wspólnicy',
            values: [
                {
                    header: 'Wspólnik',
                    content: 'Jan Abacki',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=38&obiekt=pracownicy'
                    }
                },
                {
                    header: 'Wspólnik',
                    content: 'Anna Dąbek',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=37&obiekt=pracownicy'
                    }
                },
                {
                    header: 'Wspólnik',
                    content: 'Anna Dąbek',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=37&obiekt=pracownicy'
                    }
                }
            ],
            children: [
                {
                    values: [
                        {
                            header: 'Szef działu gospodarczego',
                            content: 'Tomasz Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: [
                        {
                            values: [
                                {
                                    header: 'Aplikant',
                                    content: 'Jan Kowalski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: [
                                {
                                    values: [
                                        {
                                            header: 'Aplikant',
                                            content: 'Jan Nowak',
                                            link: {
                                                url: 'ekran_obiektu.php',
                                                params: 'id=37&obiekt=pracownicy'
                                            }
                                        }
                                    ],
                                    children: []
                                },
                                {
                                    values: [
                                        {
                                            header: 'Aplikant',
                                            content: 'Jannna Nowakowska',
                                            link: {
                                                url: 'ekran_obiektu.php',
                                                params: 'id=37&obiekt=pracownicy'
                                            }
                                        }
                                    ],
                                    children: [
                                        {
                                            values: [
                                                {
                                                    header: 'Aplikant',
                                                    content: 'Jan Nowak',
                                                    link: {
                                                        url: 'ekran_obiektu.php',
                                                        params: 'id=37&obiekt=pracownicy'
                                                    }
                                                }
                                            ],
                                            children: []
                                        }
                                    ]
                                },
                                {
                                    values: [
                                        {
                                            header: 'Radca',
                                            content: 'Joanna Kozłowska-Korzeniewska',
                                            link: {
                                                url: 'ekran_obiektu.php',
                                                params: 'id=37&obiekt=pracownicy'
                                            }
                                        }
                                    ],
                                    children: []
                                }
                            ]
                        }
                        //     {
                        //         values: [
                        //             {
                        //                 header: 'Aplikant',
                        //                 content: 'Tomasz Kowalski',
                        //                 link: {
                        //                     url: 'ekran_obiektu.php',
                        //                     params: 'id=38&obiekt=pracownicy'
                        //                 }
                        //             }
                        //         ],
                        //         children: [
                        //             {
                        //                 values: [
                        //                     {
                        //                         header: 'Aplikant',
                        //                         content: 'Tomasz Kowalski',
                        //                         link: {
                        //                             url: 'ekran_obiektu.php',
                        //                             params: 'id=38&obiekt=pracownicy'
                        //                         }
                        //                     }
                        //                 ],
                        //                 children: []
                        //             }
                        //         ]
                        //     },
                        //     {
                        //         values: [
                        //             {
                        //                 header: 'Radca Michalska-Dobrowolska',
                        //                 content: 'Karolina Michalska-Dobrowolska',
                        //                 link: {
                        //                     url: 'ekran_obiektu.php',
                        //                     params: 'id=38&obiekt=pracownicy'
                        //                 }
                        //             }
                        //         ],
                        //         children: []
                        //     }
                    ]
                },
                {
                    values: [
                        {
                            header: 'Szef działu księgowego',
                            content: 'Adrian Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: [
                        {
                            values: [
                                {
                                    header: 'Aplikant',
                                    content: 'Tomasz Kowalski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: [
                                {
                                    values: [
                                        {
                                            header: 'Aplikant',
                                            content: 'Janina Michalska',
                                            link: {
                                                url: 'ekran_obiektu.php',
                                                params: 'id=38&obiekt=pracownicy'
                                            }
                                        }
                                    ],
                                    children: [
                                        // {
                                        //     values: [
                                        //         {
                                        //             header: 'Aplikant',
                                        //             content: 'Michał Dobrowolski',
                                        //             link: {
                                        //                 url: 'ekran_obiektu.php',
                                        //                 params: 'id=38&obiekt=pracownicy'
                                        //             }
                                        //         }
                                        //     ],
                                        //     children: []
                                        // }
                                    ]
                                }
                            ]
                        },
                        {
                            values: [
                                {
                                    header: 'Adwokat',
                                    content: 'Łukasz Szejnaski',
                                    link: {
                                        url: 'ekran_obiektu.php',
                                        params: 'id=38&obiekt=pracownicy'
                                    }
                                }
                            ],
                            children: []
                        }
                    ]
                },
                {
                    values: [
                        {
                            header: 'Szef działu prawnego',
                            content: 'Marian Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                },
                {
                    values: [
                        {
                            header: 'Szef działu logistycznego',
                            content: 'Michał Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                },
                {
                    values: [
                        {
                            header: 'Szef działu logistycznego',
                            content: 'Michał Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                },
                {
                    values: [
                        {
                            header: 'Szef działu logistycznego',
                            content: 'Michał Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                }
            ]
        },
        {
            title: 'Administrator',
            values: [
                {
                    header: 'Szef',
                    content: 'JMK Administrator',
                    link: {
                        url: 'ekran_obiektu.php',
                        params: 'id=38&obiekt=pracownicy'
                    }
                }
            ],
            children: [
                {
                    values: [
                        {
                            header: 'Szef działu logistycznego',
                            content: 'Michał Abacki',
                            link: {
                                url: 'ekran_obiektu.php',
                                params: 'id=38&obiekt=pracownicy'
                            }
                        }
                    ],
                    children: []
                }
            ]
        }
    ]
};

export async function fetchTreeData(screen: string): Promise<BaseApiResponse<TreeNode[]>> {
    return simpleQueryServerData(`api_react/src/listScreen/getTreeViewData.php?screen=${screen}`);
}

export async function fetchTreeDataMock(screen: string): Promise<BaseApiResponse<any>> {
    // return simpleQueryServerData(`/api_react/src/listScreen/getTreeViewData.php?screen=${screen}`);

    return {
        message: 'Zdobyto dane',
        success: true,
        errorCode: null,
        //]data: dataAll
        data: dataAll
    };
}
