import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchMenuDataForNavigation } from '../../../../api/src/menu/fetchMenuDataForNavigation';
import { AppThunk } from '../../../index';
import { MenuState, MenuStructureData, UbdateModule } from './types';
import { makeErrorText } from 'src/utils';
import { fetchNewMessagesCount } from 'src/api/src/menu/fetchNewMessagesCount';
import { MenuStateManager } from 'src/store/src/menu/menu/MenuStateManager';

export const initialState: MenuState = {
    menuStructureData: {
        modules: [],
        userOptions: [],
        screenSelected: [],
        moduleExpanded: null
    },
    newMessagesCount: null,
    isUnfolded: true
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenuStructure: (state: MenuState, action: PayloadAction<MenuStructureData>) => {
            const { modules, userOptions, screenSelected, moduleExpanded } = action.payload;
            state.menuStructureData.modules = modules;
            state.menuStructureData.userOptions = userOptions;
            state.menuStructureData.screenSelected = screenSelected;
            state.menuStructureData.moduleExpanded = moduleExpanded;
        },
        ubdateModule: (state: MenuState, action: PayloadAction<UbdateModule>) => {
            const { screenSelected, moduleExpanded } = action.payload;
            state.menuStructureData.moduleExpanded = moduleExpanded;
            state.menuStructureData.screenSelected = screenSelected;
        },
        ubdateModuleUnhold: (state: MenuState, action: PayloadAction<number | string>) => {
            if (action.payload == state.menuStructureData.moduleExpanded) {
                state.menuStructureData.moduleExpanded = null;
            } else {
                state.menuStructureData.moduleExpanded = action.payload;
            }
        },
        setNewMessgesCount: (state: MenuState, action: PayloadAction<number | null>) => {
            state.newMessagesCount = action.payload;
        },
        // update pins from payload having
        updateMenuPins: (state: MenuState, action: PayloadAction<{ id: string }[]>) => {
            for (const module of state.menuStructureData.modules) {
                for (const screen of module.screens) {
                    const correspondingScreen = action.payload.find(
                        (s) => s.id === screen.id.toString()
                    );
                    screen.isSelectedToShortcut =
                        correspondingScreen != null || screen.isStaticShortcut;
                }
            }
        },
        toggleUnfolded: (state: MenuState) => {
            state.isUnfolded = !state.isUnfolded;
        }
    }
});

/**
 * get from api MenuStructureData and update store
 */
export const fetchAndUpdateMenuData = (): AppThunk => async (dispatch) => {
    const response = await fetchMenuDataForNavigation();
    if (!response.success || !response.data) throw new Error(makeErrorText(response));
    dispatch(
        setMenuStructure({
            modules: response.data.modules,
            userOptions: response.data.userOptions,
            ...MenuStateManager.setInitMenuStructureData(response.data.modules)
        })
    );
};

/**
 * get from api newMessagesCount and update store
 */
export const fetchAndUpdateMessagesCount = (): AppThunk => async (dispatch) => {
    const response = await fetchNewMessagesCount();
    // only console log, because null data is proper for messages count
    /*·eslint-disable·*/
    if (!response.success) {
        console.log(makeErrorText(response));
    }

    dispatch(setNewMessgesCount(response.data));
};

export const {
    setMenuStructure,
    ubdateModule,
    ubdateModuleUnhold,
    setNewMessgesCount,
    updateMenuPins,
    toggleUnfolded
} = menuSlice.actions;

export default menuSlice.reducer;
