import {
    PopupState,
    PopupPayloadsBasic,
    PopupPayloadsListScreenBasic,
    PopupPayloads
} from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: PopupState = {
    isDisplayed: false,
    isPossibleClose: true,
    type: 'default',
    title: '',
    typeWew: 'default',
    listScreen: {
        objectId: [],
        action: null,
        message: ''
    }
};

const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        setPopupWithoutData: (state: PopupState, action: PayloadAction<PopupPayloadsBasic>) => {
            state.isDisplayed = true;
            state.type = action.payload.type;
            state.title = action.payload.title ? action.payload.title : '';
        },
        setPopupListScreenBasic: (
            state: PopupState,
            action: PayloadAction<PopupPayloadsListScreenBasic>
        ) => {
            state.isDisplayed = true;
            state.type = action.payload.type;
            state.listScreen = {
                ...state.listScreen,
                objectId: action.payload.objectId ? action.payload.objectId : [],
                action: action.payload.action ? action.payload.action : null,
                message: action.payload.message ? action.payload.message : ''
            };
        },
        setPopup: (state: PopupState, action: PayloadAction<PopupPayloads>) => {
            state.isDisplayed = true;
            state.type = action.payload.type;
            state.title = action.payload.title ? action.payload.title : '';
            state.typeWew = action.payload.typeWew ? action.payload.typeWew : 'default';
            state.isPossibleClose = action.payload.isPossibleClose
                ? action.payload.isPossibleClose
                : true;
            state.listScreen = {
                ...state.listScreen,
                objectId: action.payload.objectId ? action.payload.objectId : [],
                action: action.payload.action ? action.payload.action : null,
                message: action.payload.message ? action.payload.message : ''
            };
        },
        closePopup: (state: PopupState) => {
            state.isDisplayed = false;
            state.type = 'default';
            state.typeWew = 'default';
            state.title = '';
            state.listScreen = {
                objectId: [],
                action: null,
                message: ''
            };
        }
    }
});

export const { closePopup, setPopupWithoutData, setPopupListScreenBasic, setPopup } =
    popupSlice.actions;

export default popupSlice.reducer;
