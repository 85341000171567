import { useEffect } from 'react';
import { useSearchParam } from 'src/hooks';
import { loadInitialData } from '../../../store/src/calendar/index';
import { AppDispatch } from '../../../store/src/calendar/index';

/**
 * Fetched and dispatches all data for the calendar.
 * @param dispatch - takes dispatch funtion as a param to facilitate tests.
 */
export function useLoadInitialData(dispatch: AppDispatch) {
    const obiekt = useSearchParam('obiekt');
    useEffect(() => {
        dispatch(loadInitialData());
    }, [dispatch, obiekt]);
}
