import { TransformationsState } from 'src/store/src/listScreen/transformations/types';
import { RowData, RowDataId } from 'src/store/src/listScreen/tableData/types';
import { TemplateDolar } from 'src/utils/src/shared/TemplateDolar';
import { TemplateExclamation } from 'src/utils/src/shared/TemplateExclamation';
import { TemplateHash } from 'src/utils/src/shared/TemplateHash';
import { TemplateMonkey } from 'src/utils/src/shared/TemplateMonkey';
import { TemplateItem } from 'src/utils/src/shared/TemplateItem';
import { CombinedPropertiesOpionalFromActionBehaviorData, SystemAction } from 'src/data/types';
// DOC

// TemplateManager jest obiektem przygodowującym dane do wysłania do api, tworzenia linka itp.
// 1. TemplateManager zarządza procesem tworzenia zestawu danych ( obiekt pierwszego poziomu )
// 2. TemplateItemManager, zarządza poszczególną jednostką danych (np. param albo url) ( obiekt drugiego poziomu )
// 3. TemplateItem, jest obiektem danych który na podstawie otrzymanego wyjściowego stringa tworzy dane które później wykorzystuje TemplateItemManager (obiekt trzeciego poziomu)

// TemplateManager is object which prepare data for api, link
// 1. TemplateManager menage process create set of data (first level)
// 2. TemplateItemManager menage unit data (e.g. param or url) (second level)
// 3  TemplateItem is object data which base on input string create new data, which are used by TemplateItemManager (third level)

const regexD = /\{((\$|#|@|!)(.*?))\}/;
const regexListScreenParams = /(@listScreenParams)/;
const regexListScreenExcelParams = /(@listScreenExcelParams)/;

const specialRegexs: { type: SpecialRegexsTypes; regex: RegExp }[] = [
    {
        type: 'isListScreenExcelParams',
        regex: regexListScreenExcelParams
    },
    {
        type: 'isListScreenParams',
        regex: regexListScreenParams
    }
];

type SpecialRegexsTypes = 'isListScreenExcelParams' | 'isListScreenParams';

type TemplateManagerItemProps = {
    rowData: RowData[];
    transformationState: TransformationsState;
    screen?: string | null;
    rowId?: RowDataId | null;
    totalIds?: string[];
    action?: SystemAction;
};

type Payload = { key: string; value: string };

type PropertyArr = { key: string; value: string };

type SettingArr = {
    keyPropertyInData: 'payload' | 'description';
};

type SettingStr = {
    keyPropertyInData: 'params' | 'body' | 'url';
};

export class TemplateItemManager {
    rowId: string | null;
    screen?: null | string;
    rowData: RowData[];
    totalIds?: string[];
    transformationState: TransformationsState;
    action?: SystemAction;
    constructor({
        rowData,
        transformationState,
        rowId,
        screen,
        totalIds,
        action
    }: TemplateManagerItemProps) {
        this.screen = screen;
        this.rowId = rowId ? rowId : null;
        this.rowData = rowData;
        this.totalIds = totalIds;
        this.transformationState = transformationState;
        this.action = action;
    }

    findAndReplaceTemplates({ str }: { str: string | undefined | null }) {
        if (!str) return '';
        let i = true;
        let temporaryAllString = str;
        do {
            const itemStr = temporaryAllString.match(regexD);
            if (itemStr) {
                const templateItem = new TemplateItem({
                    itemStr: itemStr[0]
                });
                temporaryAllString = this.manageTemplate({ templateItem, temporaryAllString });
            } else {
                i = false;
            }
        } while (i);
        return temporaryAllString;
    }

    manageTemplate({
        templateItem,
        temporaryAllString
    }: {
        templateItem: TemplateItem;
        temporaryAllString: string;
    }) {
        switch (templateItem.sign) {
            case '@': {
                return new TemplateMonkey({
                    rowData: this.rowData,
                    rowId: this.rowId,
                    totalIds: this.totalIds,
                    templateItem,
                    temporaryAllString,
                    transformationState: this.transformationState,
                    screen: this.screen,
                    action: this.action
                }).getData();
            }
            case '$': {
                return new TemplateDolar({
                    rowData: this.rowData,
                    rowId: this.rowId,
                    templateItem,
                    temporaryAllString
                }).getData();
            }
            case '#': {
                return new TemplateHash({
                    templateItem,
                    temporaryAllString
                }).getData();
            }
            case '!': {
                return new TemplateExclamation({
                    rowData: this.rowData,
                    rowId: this.rowId,
                    templateItem,
                    temporaryAllString
                }).getData();
            }
            default:
                return temporaryAllString;
        }
    }

    static checkIsTemplateExist({ str, regex = regexD }: { str: string; regex?: RegExp }) {
        return str.match(regex);
    }

    static getAllTemplatesExisted({
        str,
        specialRegexs
    }: {
        str: string;
        specialRegexs: { type: SpecialRegexsTypes; regex: RegExp }[];
    }) {
        const allTemplate: { [key in SpecialRegexsTypes]?: any } = {};
        specialRegexs.forEach((regex) => {
            allTemplate[regex.type] = TemplateItemManager.checkIsTemplateExist({
                str: str,
                regex: regex.regex
            });
        });

        return allTemplate;
    }
}

type TemplateManagerActionBehavior = {
    type: string;
    data: CombinedPropertiesOpionalFromActionBehaviorData;
};

type TemplateManagerProps = {
    rowData: RowData[];
    transformationState: TransformationsState;
    screen?: string | null;
    rowId?: RowDataId | null;
    totalIds?: string[];
    actionBehavior: TemplateManagerActionBehavior;
    action?: SystemAction;
};

export class TemplateManager {
    actionBehavior: TemplateManagerActionBehavior;
    params: string;
    body: any;
    url: string;
    locationStateFlag: boolean;
    payload: Payload[];
    templateItemManager: TemplateItemManager;
    constructor({
        rowData,
        transformationState,
        actionBehavior,
        rowId,
        screen,
        totalIds,
        action
    }: TemplateManagerProps) {
        this.actionBehavior = actionBehavior;
        this.params = '';
        this.body = '';
        this.url = '';
        this.locationStateFlag = false;
        this.payload = [];
        this.templateItemManager = new TemplateItemManager({
            rowData,
            transformationState,
            rowId,
            screen,
            totalIds,
            action
        });
    }

    handleArr(settingData: SettingArr) {
        const arr: PropertyArr[] = [];
        if (settingData.keyPropertyInData === 'payload') {
            const payloadToParse = this.actionBehavior?.data?.payload
                ? this.actionBehavior?.data?.payload
                : [];
            const payloadParsed = this.preparePayloadData(payloadToParse);
            payloadParsed.forEach((item: Payload) => {
                const obj: any = {};
                for (const property in item) {
                    obj[property] = this.templateItemManager.findAndReplaceTemplates({
                        str: item[property as keyof Payload]
                    });
                }
                arr.push(obj);
            });
        }
        return arr;
    }

    handleStr(settingData: SettingStr) {
        return this.templateItemManager.findAndReplaceTemplates({
            str: this.actionBehavior?.data[settingData.keyPropertyInData]
        });
    }

    checkSpecialTemplate(str: string) {
        const specialTemplates = TemplateItemManager.getAllTemplatesExisted({
            str,
            specialRegexs
        });
        if (specialTemplates.isListScreenExcelParams) {
            this.body = {
                filters: this.templateItemManager.transformationState.filters,
                sortingData: this.templateItemManager.transformationState.sortingData
            };
        }
        if (specialTemplates.isListScreenParams) {
            this.locationStateFlag = true;
        }
    }

    private preparePayloadData(arr: string[]) {
        const newArr: Payload[] = [];
        arr.forEach((item) => {
            newArr.push(JSON.parse(item));
        });
        return newArr;
    }

    getData() {
        if (this.actionBehavior?.data?.url) {
            this.url = this.handleStr({
                keyPropertyInData: 'url'
            });
        }

        if (this.actionBehavior?.data?.body) {
            this.body = this.handleStr({
                keyPropertyInData: 'body'
            });
        }

        if (this.actionBehavior?.data?.payload) {
            this.payload = this.handleArr({
                keyPropertyInData: 'payload'
            });
        }

        if (this.actionBehavior?.data?.params) {
            this.params = this.handleStr({
                keyPropertyInData: 'params'
            });

            this.checkSpecialTemplate(this.actionBehavior?.data?.params);
        }

        return {
            url: this.url,
            params: this.params,
            body: this.body,
            payload: this.payload,
            locationStateFlag: this.locationStateFlag
        };
    }
}
