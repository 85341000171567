import { PopupFormBehavior, DynamicFieldT } from 'src/data/types';
import { RowDataId, RowData } from 'src/store/src/listScreen/tableData/types';
import { TemplateItem } from 'src/utils/src/shared/TemplateItem';
import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { DateManager, normaliseStringNumber } from 'src/utils';

const fields: { [key: string]: any } = {
    poswiecanyczas: '0.000',
    datarealizacji: new Date(),
    attachment: '',
    status: false
};

type DynamicStateFormHandlerProps = {
    ids: RowDataId[];
    rowData: RowData[];
    fields?: DynamicFieldT[];
    state?: DynamicStateForm;
};

export type DynamicStateForm = {
    [key: string]: boolean | Date | number | string;
};
export class DynamicStateFormHandler {
    fields: DynamicFieldT[];
    ids: RowDataId[];
    rowData: RowData[];
    state: DynamicStateForm;
    constructor({ ids, state, fields, rowData }: DynamicStateFormHandlerProps) {
        this.fields = fields ? fields.filter((item) => item.modyfikacja === 1) : [];
        this.ids = ids;
        this.state = state ? state : {};
        this.rowData = rowData;
    }
    createInitState() {
        this.ids.forEach((id) => {
            this.createFields(id);
        });
        return this.state;
    }
    private createFields(id: RowDataId) {
        const state: DynamicStateForm = {};
        this.fields.forEach((field: DynamicFieldT) => {
            state[id + '-' + field.kod] = this.setInitValue(field, id);
        });
        console.log(state, 'state');
        this.state = {
            ...this.state,
            ...state
        };
    }

    private setInitValue(field: DynamicFieldT, id: RowDataId) {
        const str = String(field.domyslna_wartosc);
        const templateItem = new TemplateItem({ itemStr: str });
        let value: any;
        if (templateItem.isDolar) {
            value = new TableDataManager({
                rowData: this.rowData,
                rowId: id
            }).getRawValue(templateItem);
        }
        switch (field.typ) {
            case 'checkbox':
                if (value && (value?.[0] === 1 || value?.[0] === '1')) {
                    return true;
                } else if (field.domyslna_wartosc === 1 || field.domyslna_wartosc === '1') {
                    return true;
                }
                return false;
            case 'liczba':
                if (value && value?.[0]?.[templateItem?.contentClear]) {
                    return normaliseStringNumber(value?.[0]?.[templateItem?.contentClear]);
                }
                return '0.500';
            case 'lista':
                return field?.domyslna_wartosc;
            case 'data':
            case 'data_godzina':
                if (field.domyslna_dzisiejsza === 't') {
                    return new Date();
                } else return null;
            case 'powiazanie_typ':
                return {
                    typ: '',
                    id: ''
                };
            case 'lista_obca_wielokrotna':
                return [];
            case 'powiazanie_typ_wielokrotne':
                return [];
            case 'data_godziny':
                if (templateItem.isDolar && Array.isArray(value)) {
                    return {
                        data: DateManager.setDateFromPhpTimestamp(value[0]?.data_rozp),
                        rozp: DateManager.setTimeString(
                            DateManager.setDateFromPhpTimestamp(value[0]?.data_rozp)
                        ),
                        zak: DateManager.setTimeString(
                            DateManager.setDateFromPhpTimestamp(value[0]?.data_zak)
                        )
                    };
                }
                return {
                    data: new Date(),
                    rozp: '00:00',
                    zak: '00:00'
                };
            default: {
                let value: any = null;
                if (!fields[field.kod]) {
                    value = fields[field.kod];
                }
                return field.domyslna_wartosc ? field.domyslna_wartosc : value;
            }
        }
    }
}
