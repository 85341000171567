import { ModuleMenuData, ScreenMenuData } from 'src/store/src/menu/menu/types';
import { useSearchParam } from 'src/hooks';
import { useTypedSelector } from 'src/store';

type StructureObjectAttribute = {
    (attribute: 'code', structureObjectType: 'screen' | 'modul'): null | number;
    (attribute: 'id', structureObjectType: 'screen' | 'modul'): null | string;
};

export const useStructureObjectAttribute: StructureObjectAttribute = (
    attribute: 'id' | 'code',
    structureObjectType: 'screen' | 'modul'
): any => {
    const menu = useTypedSelector((state) => state.menu.menuStructureData.modules);
    const searchBy: 'code' | 'id' = attribute == 'code' ? 'id' : 'code';
    const searchElement: 'code' | 'id' = attribute;
    const modul = useSearchParam('modul');
    const _screen = useSearchParam('screen');
    const _objekt = useSearchParam('obiekt');
    const screen = _screen || _objekt;
    const screens: (ScreenMenuData | ModuleMenuData)[] = [];

    if (structureObjectType === 'screen') {
        //splaszczam screeny menu
        menu.forEach((el: ModuleMenuData) => {
            (screens as ScreenMenuData[]).push(...el.screens);
        });
    }

    const [lookFor, structure] =
        structureObjectType === 'modul' ? [modul, menu] : [screen, screens]; // czego szukać i gdzie

    const isLookForNumber = lookFor != null ? Number.isInteger(parseInt(lookFor)) : false;

    if (!isLookForNumber && attribute === 'code') {
        return lookFor;
    }

    if (isLookForNumber && attribute === 'id') {
        return lookFor;
    }

    if (lookFor === null) {
        return null;
    }

    const result: ScreenMenuData | ModuleMenuData | undefined = structure.find(
        (el: ScreenMenuData | ModuleMenuData) => {
            return el[searchBy] == lookFor;
        }
    );

    return result ? (result[searchElement] ? result[searchElement] : null) : null;
};
