import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import { downloadFileAsync } from 'src/utils';
import { formatDayDateToApi } from 'src/utils/src/shared/formatDayDateToApi';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { topMenu } from 'src/constants/subtitles';
// import DatePicker from 'src/components/datePicker/organism/DatePicker';
import { DatePicker, GetDate } from 'src/components/shared/atoms/datePicker/DatePicker';
import { setSpinner, closeSpinner } from 'src/store/src/general/system/systemSlice';
type Props = { onClose: () => void };

const MyReport: FC<Props> = ({ onClose }) => {
    const user = useTypedSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [dateStart, setDateStart] = React.useState<Date | null>(null);
    const [dateFinish, setDateFinish] = React.useState<Date | null>(null);
    const [disabledBtn, setDisabledBtn] = React.useState<boolean>(false);
    const handleDownload = async () => {
        setDisabledBtn(true);
        onClose();
        // dispatch(setSpinner());
        await downloadFileAsync({
            resource: `raport_pracownikow_20180423.php?pracownik=${
                user.userID
            }&data_rozp=${formatDayDateToApi(dateStart)}&data_zak=${formatDayDateToApi(
                dateFinish
            )}`,
            nameFile: 'Raport pracownika.xlsx'
        });
        //  dispatch(closeSpinner());
    };

    const getStartDate = (data: GetDate) => {
        setDateStart(data.dateSelectedNormal);
    };
    const getEndDate = (data: GetDate) => {
        setDateFinish(data.dateSelectedNormal);
    };
    return (
        <Box
            border={1}
            padding={3}
            sx={{
                display: 'grid',
                gridTemplateRows: 'auto auto auto',
                gap: '10px',
                justifyItems: 'center',
                alignItems: 'center',
                borderColor: (theme) => theme.palette.primary.main,
                borderRadius: '4px',
                color: (theme) => theme.palette.primary.main,
                overflow: 'visible'
            }}>
            <DatePicker
                labelDate={topMenu.input.myReport.first}
                isCheckboxToday={true}
                numberPanels={'three'}
                getDate={getStartDate}
                sxContainerPanels={{ right: 0 }}
            />
            <DatePicker
                labelDate={topMenu.input.myReport.second}
                isCheckboxToday={true}
                numberPanels={'three'}
                getDate={getEndDate}
                sxContainerPanels={{ right: 0 }}
            />
            <Box
                display={'grid'}
                sx={{
                    gridTemplateColumns: 'auto auto',
                    gridTemplateRows: 'auto',
                    gap: '10px',
                    marginTop: '5px'
                }}>
                <Button variant="outlined" disabled={disabledBtn} onClick={handleDownload}>
                    {topMenu.button.myReport.run}
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    {topMenu.button.myReport.cancel}
                </Button>
            </Box>
        </Box>
    );
};

export default MyReport;
