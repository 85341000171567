import React, { FC, Reducer } from 'react';
import { DynamicFieldProps } from 'src/components/shared/molecules/dynamicField/types';
import { DatePicker, GetDate } from 'src/components/shared/atoms/datePicker/DatePicker';
import { InputTime } from 'src/components/shared/atoms/datePicker/InputTime';
import style from './style.module.css';

import { Error } from './Error';

import { TimeError, Action } from './types';

const isTimeValid = (time: string) => {
    if (time.length >= 5) {
        const timeReg = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
        return !timeReg.test(time);
    }
    return false;
};

export const DynamicDateHour: FC<DynamicFieldProps> = ({ nazwa, field }) => {
    const initialState: TimeError = {
        timeRozpError: false,
        timeZakError: false
    };

    const reducer: Reducer<TimeError, Action> = (state: TimeError, action: Action) => {
        switch (action.type) {
            case 'SET_TIME_ROZP_ERROR':
                return {
                    ...state,
                    timeRozpError: isTimeValid(action.payload)
                };
            case 'SET_TIME_ZAK_ERROR':
                return {
                    ...state,
                    timeZakError: isTimeValid(action.payload)
                };

            case 'CLOSE_ERROR':
                return {
                    timeRozpError: false,
                    timeZakError: false
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = React.useReducer<Reducer<TimeError, Action>>(reducer, initialState);

    return (
        <div className={style.flex}>
            <DatePicker
                dateInitial={field?.value.data}
                getDate={(data: GetDate) => {
                    field.onChange({ ...field?.value, data: data.dateSelectedNormal });
                }}
                labelDate={nazwa}
                numberPanels={'three'}
                isCheckboxToday={false}
                isInputTime={false}
                sxContainerPanels={{ position: 'fixed' }}
            />

            <div className={style.ml}>
                <InputTime
                    label="godz. rozp."
                    type={'text'}
                    placeholder="gg:mm"
                    sxinputtime={{ width: '60px' }}
                    maskChar={''}
                    value={field.value?.rozp || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange({ ...field?.value, rozp: e.target.value });
                    }}
                />
                {state.timeRozpError && <Error />}
            </div>
            <div className={style.ml}>
                <InputTime
                    label="godz. zak."
                    type={'text'}
                    placeholder="gg:mm"
                    sxinputtime={{ width: '60px' }}
                    maskChar={''}
                    value={field.value?.zak || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange({ ...field?.value, zak: e.target.value });
                    }}
                />
                {state.timeZakError && <Error />}
            </div>
        </div>
    );
};
