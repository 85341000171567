import React from 'react';
import { format } from 'date-fns';

import { DateManager } from 'src/utils';
import { Action, DatePickerState } from 'src/components/shared/atoms/datePicker/reducer';
import { GetDateFn } from 'src/components/shared/atoms/datePicker/DatePicker';

const regexWithoutUnderscore = /_/gi;

type DatePickerInputManagerProps = {
    dispatch: React.Dispatch<Action>;
    state: DatePickerState;
    value: string;
    getDate: GetDateFn;
};

const emptyDate = {
    dateSelectedNormal: null,
    dateSelectedFormatted: '',
    isToday: false
};
export class DatePickerInputManager {
    dispatch: React.Dispatch<Action>;
    state: DatePickerState;
    value: string;
    getDate: GetDateFn;
    valueWithoutLine: string;
    items: string[];
    isValid: boolean;
    isValidated: boolean;
    newDate: Date;
    constructor({ value, dispatch, state, getDate }: DatePickerInputManagerProps) {
        this.dispatch = dispatch;
        this.state = state;
        this.value = value;
        this.getDate = getDate;
        this.items = value.split(state.signDivider);
        this.valueWithoutLine = value
            .split(state.signDivider)
            .reduce((previousValue, currentValue) => previousValue + currentValue)
            .replace(regexWithoutUnderscore, '');
        this.isValid = true;
        this.isValidated = false;
        this.newDate = new Date();
    }
    handleInput(isEnter: boolean = false) {
        this.dispatch({
            type: 'EDIT_INPUT',
            payload: {
                ...this.state,
                dateInputVisible: this.value
            }
        });
        if (this.valueWithoutLine.length === 0) {
            this.dispatch({
                type: 'RESET_DATE_SELECTED',
                payload: {
                    dateSelected: null
                }
            });
            this.getDate({
                ...emptyDate
            });
        }
        if (this.valueWithoutLine.length === 8) {
            this.validate();
        }
        if (this.isValidated) {
            if (this.isValid) {
                this.dispatch({
                    type: `${
                        isEnter ? 'SET_SELECTED_DATE_WITH_CLOSE' : 'SET_SELECTED_DATE_WITHOUT_CLOSE'
                    }`,
                    payload: {
                        ...this.state,
                        datePanel: this.newDate,
                        dateSelected: this.newDate,
                        dateInputVisible: format(new Date(this.newDate), 'ddMMyyyy'),
                        hoursSelected: String(this.newDate.getHours()),
                        minutesSelected: String(this.newDate.getMinutes()),
                        isToday: DateManager.isToday(this.newDate)
                    }
                });
                this.getDate({
                    dateSelectedNormal: this.newDate,
                    dateSelectedFormatted: format(
                        new Date(this.newDate),
                        `dd${this.state.signDivider}MM${this.state.signDivider}yyyy`
                    ),
                    isToday: DateManager.isToday(this.newDate)
                });
            } else {
                this.dispatch({
                    type: 'OPEN_ERROR_DATE'
                });
            }
        }
    }

    handleOnBlurInput() {
        if (this.valueWithoutLine.length === 8) {
            this.validate();
        }
        if (!this.isValidated || (!this.isValid && this.isValidated)) {
            this.dispatch({
                type: 'RESET_DATE_SELECTED',
                payload: {
                    dateSelected: null
                }
            });
            this.getDate({
                ...emptyDate
            });
        }
    }

    handleReset() {
        this.dispatch({
            type: 'RESET'
        });

        this.getDate({
            ...emptyDate
        });
    }

    handleCheckbox() {
        if (this.value === 'false') {
            let hours = String(this.newDate.getHours());
            let minutes = String(this.newDate.getMinutes());
            if (hours.length === 1) {
                hours = '0' + hours;
            }
            if (minutes.length === 1) {
                minutes = '0' + minutes;
            }
            this.dispatch({
                type: 'SET_TODAY',
                payload: {
                    datePanel: this.newDate,
                    dateSelected: this.newDate,
                    dateInputVisible: format(new Date(this.newDate), 'ddMMyyyy'),
                    hoursSelected: hours,
                    minutesSelected: minutes,
                    timeVisible: hours + minutes
                }
            });
            this.getDate({
                dateSelectedNormal: this.newDate,
                dateSelectedFormatted: format(
                    new Date(this.newDate),
                    `dd${this.state.signDivider}MM${this.state.signDivider}yyyy`
                ),
                isToday: true
            });
        } else if (this.value === 'true') {
            this.dispatch({
                type: 'UNSET_TODAY'
            });
            this.getDate({
                ...emptyDate
            });
        }
    }

    validate() {
        const year = Number(this.items[2]);
        const month = Number(this.items[1]);
        const days = Number(this.items[0]);
        const daysInMonth = DateManager.getDaysInMonth(year, month);
        if (month > 12) {
            this.isValid = false;
        } else {
            if (days > daysInMonth) {
                this.isValid = false;
            }
        }
        this.newDate = new Date(
            year,
            month - 1,
            days,
            Number(this.state.hoursSelected),
            Number(this.state.minutesSelected)
        );
        this.isValidated = true;
    }
}
