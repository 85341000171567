import React from 'react';
import { Popover } from '@mui/material';
import Pin from 'src/assets/menu_gorne_dymek.svg';
import ShortcutsPinSectionModuleList from 'src/components/menu/organisms/shortcutsPinSectionModuleList/ShortcutsPinSectionModuleList';
import TooltipOnHover from 'src/components/shared/molecules/tooltipOnHover/TooltipOnHover';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { topMenu } from 'src/constants/subtitles';
import styles from './ShortcutPinSection.module.css';

const ShortcutsPinSection = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (_e: React.BaseSyntheticEvent) => {
        setAnchorEl(_e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={styles.wrapperMain}>
            <TooltipOnHover
                title={topMenu.tooltipOnHover.pin}
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -10]
                            }
                        }
                    ]
                }}>
                <div className={styles.box} aria-describedby={id} onClick={handleClick}>
                    <ThemeCompatibleIcon
                        SvgComponent={Pin}
                        fontSize={'large'}
                        sx={{
                            fontSize: '2.8rem',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    />
                </div>
            </TooltipOnHover>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}>
                <ShortcutsPinSectionModuleList onClose={handleClose} />
            </Popover>
        </div>
    );
};

export default React.memo(ShortcutsPinSection);
