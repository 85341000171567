import React, { FC } from 'react';
import { DynamicFieldProps } from 'src/components/shared/molecules/dynamicField/types';
import { UiCheckbox } from 'src/components/shared/atoms/uiInputs/uiCheckbox/UiCheckbox';
export const DynamicCheckbox: FC<DynamicFieldProps> = ({ field, nazwa }) => {
    return (
        <>
            <UiCheckbox label={nazwa} {...field} onChange={field.onChange} checked={field.value} />
        </>
    );
};
