import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export { systemSlice, userSlice } from '../../rootReducer';

export {
    closePopup,
    setPopupWithoutData,
    setPopupListScreenBasic,
    setPopup
} from './popup/popupSlice';
