import React, { FC } from 'react';
import { DynamicFieldProps } from 'src/components/shared/molecules/dynamicField/types';
import { DatePicker, GetDate } from 'src/components/shared/atoms/datePicker/DatePicker';

export const DynamicTimeHour: FC<DynamicFieldProps> = ({
    nazwa,
    field,
    isDisabledLabel,
    isInputTimeInDatePicker,
    isCheckboxTodayInDatePicker
}) => {
    const ubdateDate = (data: GetDate) => {
        field.onChange(data.dateSelectedNormal);
    };

    return (
        <>
            <DatePicker
                getDate={ubdateDate}
                labelDate={nazwa}
                numberPanels={'three'}
                dateInitial={field.value}
                timeInitial={field.value}
                isCheckboxToday={isCheckboxTodayInDatePicker}
                isInputTime={isInputTimeInDatePicker}
                sxContainerPanels={{ position: 'fixed' }}
                isDisabledLabel={isDisabledLabel}
            />
        </>
    );
};
