import React, { FC } from 'react';

import PopupTemplateHead from 'src/components/shared/molecules/popupTemaplateHead/PopupTemplateHead';
import { useTypedSelector } from 'src/store';
import { TemplateManagerExclamation } from 'src/utils/src/shared/TemplateManagerExclamation';
import { reduceStr } from 'src/utils';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';

type Props = {
    id: RowDataId;
};

export const DynamicTitle: FC<Props> = ({ id }) => {
    const popup = useTypedSelector((state) => state.popup);
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const titleTemplated = new TemplateManagerExclamation({
        rowData: rowData,
        rowId: id,
        templateStr: popup.title,
        typeSearch: 'text'
    }).getOne();

    return <PopupTemplateHead title={reduceStr(titleTemplated, 55)} />;
};
