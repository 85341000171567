import { PageLocationState, ConvertableToParams } from 'src/data/types';
import { SafeNavigateFunction } from 'src/hooks/src/shared/types';
import { makeFullParamsFromLocationParams } from './makeFullParamsFromLocationParams';
import { makeValidLocationState } from './makeValidLocationState';

/**
 * Save page data that should be stored in location
 * @param pageLocationState data to save
 * @param pathname pathname without basename part of a window.location.pathname, because we use
 * react router navigate
 */
export const savePageLocationState = <
    P extends ConvertableToParams,
    L extends ConvertableToParams,
    S
>(
    pageLocationState: PageLocationState<P, L, S>,
    navigate: SafeNavigateFunction,
    pathname: string
) => {
    // merge actual url search params with pageData.params
    const params = makeFullParamsFromLocationParams(pageLocationState.params);

    // save params and locationstate
    navigate(`${pathname}?${params}`, makeValidLocationState(pageLocationState));
};
