import { MenuStructureData } from '../../../store/src/menu/menu/types';
import { BaseApiResponse } from '../../types';
import { simpleQueryServerData } from '../simpleQueryServerData';

/**
 * Fetches information about actual system configuration
 */
export async function fetchMenuDataForNavigation(): Promise<BaseApiResponse<MenuStructureData>> {
    return simpleQueryServerData('api_react/src/menu/getMenuDataForNavigation.php');
}
