import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TimerIcon from '@material-ui/icons/Timer';
import { Divider } from '@mui/material';

const useStyles = makeStyles(({ spacing, palette }) => ({
    timer_add_root: {
        paddingTop: spacing(3),
        width: '100&',
        textAlign: 'center',
        background: palette.background.default
    },
    timer_add_btn: {
        fontSize: spacing(2),
        width: spacing(15),
        padding: `${spacing(0.3)}px ${spacing(2.1)}px`,
        fontWeight: 'bold',
        marginBottom: spacing(3)
    }
}));

/**
 * Bezstanowy komponent funkcyjny. Wyświetla przycisk który pozwala dodać stoper
 * @component
 * @category Components
 * @subcategory Timers
 * @param {object} props - Propsy komponentu
 * @param {Function} props.clickHandler - akcja wywoływana po kliknięciu w przycisk
 * @returns {ReactComponent}
 * @see [Components/Timers/Timers]{@link Timers}
 */
const AddTimer = ({ clickHandler }) => {
    const classes = useStyles();
    return (
        <div className={classes.timer_add_root}>
            <Button
                color="primary"
                variant="outlined"
                size="large"
                className={classes.timer_add_btn}
                onClick={clickHandler}
                endIcon={<TimerIcon />}>
                Dodaj
            </Button>
            <Divider />
        </div>
    );
};

export default AddTimer;
