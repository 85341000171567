import React, { FC } from 'react';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { UtilityBarOptionViewProps } from './types';
import GrowWrapper from 'src/components/shared/atoms/growWrapper/GrowWrapper';
import styles from './UtilityBarOptionView.module.css';
/**
 * Describes main menu, handle all mechanics related to menu data
 */
const UtilitiesBarOptionView: FC<UtilityBarOptionViewProps> = ({ name, icon }) => {
    return (
        <div className={styles.wrapper}>
            <GrowWrapper>
                <div className={styles.wrapperInner}>
                    <ThemeCompatibleIcon
                        iconData={icon}
                        fontSize={'medium'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    />
                </div>
                <p className={` ${styles.text}`}>{name}</p>
            </GrowWrapper>
        </div>
    );
};

export default UtilitiesBarOptionView;
