import { UpdateObjectDataForApi } from 'src/api/types';
import { DynamicStateForm } from 'src/components/shared/molecules/dynamicForm/DynamicFormHandler';
import { DynamicFieldT, DynamicFieldType } from 'src/data/types';

type DynamicFormDataApiCreatorProps = {
    state: DynamicStateForm;
    fields: DynamicFieldT[];
};

type handleField = {
    type: DynamicFieldType;
    code: string;
    value: any;
};

export class DynamicFormDataApiCreator {
    state: DynamicStateForm;
    fields: DynamicFieldT[];
    dataApi: UpdateObjectDataForApi;
    constructor({ state, fields }: DynamicFormDataApiCreatorProps) {
        this.state = state ? state : {};
        this.fields = fields;
        this.dataApi = {};
    }

    private getIdCodeAndType(property: string) {
        const data = property.split('-');
        const field = this.fields.find((item) => item.kod === data[1]);
        const typeOfField = field ? field.typ : 'default';
        data.push(typeOfField);
        return {
            id: data[0],
            code: data[1],
            type: typeOfField
        };
    }

    private handleField({ type, code, value }: handleField) {
        switch (type) {
            case 'data_godzina':
                return OutputData.timeHour({ code, value });
            case 'data_godziny':
                return OutputData.timeHours({ code, value });
            case 'checkbox':
                return {
                    [code]: value ? 1 : 0
                };
            default:
                return {
                    [code]: value
                };
        }
    }

    private prepareData() {
        for (const property in this.state) {
            const { id, code, type } = this.getIdCodeAndType(property);
            this.dataApi[id] = {
                ...this.dataApi[id],
                ...this.handleField({ type, code, value: this.state[property] })
            };
        }
    }

    getData() {
        this.prepareData();
        return this.dataApi;
    }
}

class OutputData {
    static timeHour({ code, value }: { value: Date; code: string }) {
        const date = OutputData.prepareDate(value);
        return {
            [code]: date,
            [`${code}Minute`]: value.getMinutes(),
            [`${code}Hour`]: value.getHours()
        };
    }
    static timeHours({ code, value }: { value: any; code: string }) {
        const date = OutputData.prepareDate(value.data);
        const rozp = value?.rozp?.split(':') ? value?.rozp?.split(':') : [];
        const zak = value?.zak?.split(':') ? value?.zak?.split(':') : [];
        return {
            [code]: date,
            [`${code}_rozpMinute`]: rozp[1],
            [`${code}_rozpHour`]: rozp[0],
            [`${code}_zakMinute`]: zak[1],
            [`${code}_zakHour`]: zak[0]
        };
    }

    static prepareDate(value: Date | string) {
        if (value instanceof Date) {
            let day: string | number = value?.getDate();
            if (day < 10) day = '0' + day;
            let month: string | number = value?.getMonth() + 1;
            if (month < 10) month = '0' + month;
            const year = value?.getFullYear();
            return `${day}-${month}-${year}`;
        }
        return value;
    }
}
