import { NavigateFunction } from 'react-router-dom';

import { SearchFieldsState } from './searchFields/types.d';
import { fetchScreenStructure } from 'src/api/src/listScreen/fetchScreenStructure';
import { AppThunk } from 'src/store';
import { makeErrorText, moveQueryParamsToLocationState, ApiError } from 'src/utils';
import {
    setColumnsData,
    setRowsData,
    setColumnsDatasums,
    setFooterActionsExist,
    setTargetScreenCode,
    setTotalIds,
    setTotalIdsIsActual
} from './tableData/tableDataSlice';
import { setSearchFields } from './searchFields/searchFieldsSlice';
import {
    setFilters,
    setObjectsPerPage,
    setPaging,
    setSorting,
    setTotalRows
} from './transformations/transformationsSlice';
import { setData } from 'src/store/src/listScreen/treeData/treeDataSlice';
import { ListPagingData, ListSearchFilters, ListSortProperty } from './transformations/types';
import { ColumnDataEntries, RowData } from './tableData/types';
import { fetchObjectsData } from 'src/api/src/listScreen/fetchObjectsData';
import { BrowserListScreenLocationState } from 'src/components/listScreen/pages/objectList/types';
import { setActualPage, setStatusTableFromApi } from '.';
import { INITIAL_OBJECTS_PER_PAGE } from 'src/constants';
import { getStatusTableViewData } from 'src/api/src/listScreen/getStatusTableViewData';
import { fetchTreeData } from 'src/api/src/listScreen/fetchTreeData';
import { fetchAllIds } from 'src/api/src/listScreen/fetchAllIds';
import { TreeDataManager } from './treeData/TreeDataManager';
import { prepareFilters, prepareSorting } from 'src/utils';
import { ListScreenViews } from 'src/data/types';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { sleep } from 'src/utils/src/shared/sleep';

export const fetchAndLoadInitialTreeData =
    (screen: string): AppThunk =>
    async (dispatch) => {
        // fetch api
        const apiResponse = await fetchTreeData(screen);
        if (!apiResponse.success) {
            ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
            throw new Error(makeErrorText(apiResponse));
        }
        // set data

        const data = {
            treeNode: apiResponse.data,
            ...new TreeDataManager({ treeNode: apiResponse.data }).run()
        };
        dispatch(setData(data));
        dispatch(setSpinner(false));
    };

export const fetchAndLoadInitialListScreenData =
    (screen: string): AppThunk =>
    async (dispatch) => {
        // set empty rows data, so app don't throw error before proper list table loads own data
        dispatch(setRowsData(modifyApiRowsData([])));
        dispatch(setColumnsDatasums({}));
        dispatch(setTotalRows(0));
        // set empty columns data too, to hide partly-loaded list
        dispatch(setColumnsData({}));
        dispatch(setFooterActionsExist(true));
        // fetch api
        const apiResponse = await fetchScreenStructure(screen);

        if (!apiResponse.success) {
            ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
            throw new Error(makeErrorText(apiResponse));
        }

        // set data
        dispatch(setSearchFields(apiResponse.data.searchFields));

        const storeColumns: ColumnDataEntries = {};
        for (const column of apiResponse.data.columns) {
            storeColumns[column.code] = {
                ...column,
                sums: []
            };
        }

        await dispatch(
            setObjectsPerPage(
                apiResponse.data.objectsPerPage
                    ? apiResponse.data.objectsPerPage
                    : INITIAL_OBJECTS_PER_PAGE
            )
        );
        await dispatch(setColumnsData(storeColumns));
        await dispatch(setFooterActionsExist(apiResponse.data.isFooterActionExist));
        await dispatch(setTargetScreenCode(apiResponse.data.targetScreenCode));
        // await Promise.all([
        //     dispatch(updateTableDataFromApi(screen)),
        //     dispatch(updateStatusTableDataFromApi(screen))
        // ]);
    };

/**
 * update store state, and fetch new data from sever about objects, because paging filters etc.
 * change object data for rows
 * @param refreshData - defualt false because main list components connect location changes with
 * reloading objectsData and savePageLocationState make that location change so brwser fetches
 * twice for objects data unncessary
 */
export const updateObjectsData =
    (
        screen: string,
        paging?: ListPagingData,
        filters?: ListSearchFilters,
        sortingData?: ListSortProperty[],
        refreshData: boolean = true
    ): AppThunk =>
    async (dispatch) => {
        await dispatch(updateTransformationsData(paging, filters, sortingData));

        // fetch new data from api and set to store
        if (refreshData) await dispatch(updateTableDataFromApi(screen));
    };

/**
 * update store state, and fetch new data from sever about status table, because paging filters
 * etc. change statustable data
 */
export const updateStatusTableData =
    (
        screen: string,
        paging?: ListPagingData,
        filters?: ListSearchFilters,
        sortingData?: ListSortProperty[]
    ): AppThunk =>
    async (dispatch) => {
        await dispatch(updateTransformationsData(paging, filters, sortingData));
        // fetch new data from api and set to store
        dispatch(updateStatusTableDataFromApi(screen));
    };

export const updateTreeData =
    (
        screen: string,
        paging?: ListPagingData,
        filters?: ListSearchFilters,
        sortingData?: ListSortProperty[]
    ): AppThunk =>
    async (dispatch) => {
        // await dispatch(updateTransformationsData(paging, filters, sortingData));
        // fetch new data from api and set to store
        dispatch(fetchAndLoadInitialTreeData(screen));
    };

/**
 * load data from locationData to store
 */
export const loadListScreenDataFromLocationData =
    (screen: string | null, view: ListScreenViews, searchFieldsSlice?: SearchFieldsState) =>
    (locationData: BrowserListScreenLocationState, navigate: NavigateFunction): AppThunk =>
    async (dispatch) => {
        const { filters, legacyParamsKeys } = prepareFilters({ locationData, searchFieldsSlice });
        const { sortingData, sortingLegacyParamsKey } = prepareSorting(locationData);
        // remove found legacyParams from params and add to location store
        if (sortingLegacyParamsKey) legacyParamsKeys.push(sortingLegacyParamsKey);
        moveQueryParamsToLocationState<BrowserListScreenLocationState>(
            legacyParamsKeys,
            locationData.store,
            navigate
        );

        await Promise.all([
            dispatch(
                setActualPage(
                    locationData.params.actualPage ? Number(locationData.params.actualPage) : 1
                )
            ),
            async () => {
                if (locationData.params.objectsPerPage) {
                    await dispatch(setObjectsPerPage(Number(locationData.params.objectsPerPage)));
                }
            },
            dispatch(setFilters(filters)),
            dispatch(setSorting(sortingData))
        ]);

        // fetch new data from api and set to store
        if (!screen) {
            throw new Error('loadListScreenDataFromLocationData: screen is null');
        }

        switch (view) {
            case 'listy':
                await dispatch(updateTableDataFromApi(screen));
                break;
            case 'statusy':
                await dispatch(updateStatusTableDataFromApi(screen));
                break;
            case 'drzewo':
                await dispatch(fetchAndLoadInitialTreeData(screen));
                break;
            default:
        }
    };

// utils

const updateTransformationsData =
    (
        paging?: ListPagingData,
        filters?: ListSearchFilters,
        sortingData?: ListSortProperty[]
    ): AppThunk =>
    async (dispatch, getState) => {
        const promises: any[] = [];

        if (paging) {
            promises.push(dispatch(setPaging(paging)));
        }
        if (filters) {
            promises.push(dispatch(setFilters(filters)));
        }
        if (sortingData) {
            promises.push(dispatch(setSorting(sortingData)));
        }

        await Promise.all(promises);
    };

/**
 * modify given from api data of rows and supply with everything to be set to front store
 * @param apiRowData data from an API
 */
const modifyApiRowsData = (
    apiRowData: Omit<RowData, 'selected'>[],
    pagingData?: ListPagingData
): RowData[] => {
    const actualPage = pagingData ? pagingData.actualPage : 1;
    const objectsPerPage = pagingData ? pagingData.objectsPerPage : 10;
    const firstNumber = (actualPage - 1) * objectsPerPage + 1;
    return apiRowData.map((row, rowIdx) => {
        const item = firstNumber + rowIdx;
        // add for each row static cells

        const newCellValues = {
            ...row.cellValues,
            lp: [{ text: item.toString(), rawValue: item }],
            threeDots: [{ text: '', rawValue: null }]
        };
        // add mising selected api parameter used only in front-side of app
        return { ...row, selected: false, cellValues: newCellValues };
    });
};

/**
 * fetch data from api about data for objects table for given screen and state
 * @param screen screen information wchich object data informations fetch
 */
const updateTableDataFromApi =
    (screen: string): AppThunk =>
    async (dispatch, getState) => {
        const {
            listTransformations,
            listScreenTableData: { columnData }
        } = getState();

        // protect against setting rowData without structure
        if (!columnData) {
            return;
        }

        // fetch api
        const apiResponse = await fetchObjectsData(screen, listTransformations);
        if (!apiResponse.success) {
            ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
            throw new Error(makeErrorText(apiResponse));
        }

        // set data
        dispatch(setColumnsDatasums(apiResponse.data.sums));
        dispatch(setTotalRows(apiResponse.data.totalRows));
        dispatch(
            setRowsData(modifyApiRowsData(apiResponse.data.rowData, listTransformations.paging))
        );
        dispatch(setSpinner(false));
        dispatch(setTotalIdsIsActual(false));
        dispatch(getAllIds(screen));
    };

const getAllIds =
    (screen: string): AppThunk =>
    async (dispatch, getState) => {
        const {
            listTransformations,
            listScreenTableData: { columnData }
        } = getState();

        // protect against setting rowData without structure
        if (!columnData) {
            return;
        }

        // fetch api

        const apiResponse = await fetchAllIds(screen, listTransformations);
        if (!apiResponse.success) {
            ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
            throw new Error(makeErrorText(apiResponse));
        }
        dispatch(setTotalIdsIsActual(true));
        dispatch(setTotalIds(apiResponse.data));
    };

/**
 * fetch data from api about data for status table for given screen and state
 * @param screen screen information wchich object data informations fetch
 */
const updateStatusTableDataFromApi =
    (screen: string): AppThunk =>
    async (dispatch, getState) => {
        const { listTransformations } = getState();

        // fetch api
        const apiResponse = await getStatusTableViewData(screen, listTransformations);
        if (!apiResponse.success) {
            ApiError.handleWithCloseSpinner({ dispatch, err: apiResponse });
            throw new Error(makeErrorText(apiResponse));
        }
        // set data
        dispatch(setTotalRows(apiResponse.data.totalRows));
        dispatch(setStatusTableFromApi(apiResponse.data));
        dispatch(setSpinner(false));
    };
