import { GridEvent } from '../../store/src/calendar/calendar/types';
import { UserState } from '../../store/src/general/user/types';

type Props = {
    allEvents: Omit<GridEvent, 'width' | 'leftOffset' | 'topOffset'>[];
    visibleEvents: GridEvent[];
    userState: UserState;
};

export class OverflowEventArrayCreator {
    overFlowEvents: Omit<GridEvent, 'width' | 'leftOffset' | 'topOffset'>[];
    userState: UserState;
    arrayWithNumbersOfOverflowEvents: number[];
    constructor({ allEvents, visibleEvents, userState }: Props) {
        this.overFlowEvents = allEvents.filter(
            (event) =>
                !visibleEvents.find((item) => item.id === event.id && item.type === event.type)
        );
        this.userState = userState;
        this.arrayWithNumbersOfOverflowEvents = Array(userState.grid.numberOfRows).fill(0);
        this.fillArray();
    }
    fillArray() {
        this.overFlowEvents.forEach((ev) => {
            this.userState.grid.cells.forEach((cell, i) => {
                const startTimeCell = cell.startTimestampOfCell + ev.startDayTimestamp;
                const endTimeCell = startTimeCell + this.userState.grid.partTimestampOfCell;
                if (
                    startTimeCell >= ev.startTime - this.userState.grid.partTimestampOfCell &&
                    endTimeCell <= ev.endTime + this.userState.grid.partTimestampOfCell
                ) {
                    this.arrayWithNumbersOfOverflowEvents[i] =
                        this.arrayWithNumbersOfOverflowEvents[i] + 1;
                }
            });
        });
    }
    getArrayWithNumbersOfOverflowEvents() {
        return this.arrayWithNumbersOfOverflowEvents;
    }
}
