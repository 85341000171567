import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

type Props = {};

export const SelectIcon: FC<Props> = (props) => {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="15"
            viewBox="0 0 18 15"
            {...props}>
            <path
                id="Polygon_34"
                data-name="Polygon 34"
                d="M7.285,2.858a2,2,0,0,1,3.43,0l5.468,9.113A2,2,0,0,1,14.468,15H3.532a2,2,0,0,1-1.715-3.029Z"
                transform="translate(18 15) rotate(180)"
                fill={theme.palette.primary.main}
            />
        </svg>
    );
};
