import { createSelector } from 'reselect';

import { usersArrayToObject, usersToArray, sortUsersAndGroups } from '../../../shared/utils';

/* Get user */
const getUser = (state) => state.user;
/* Get chatUser */
const getChatUser = (state) => state.chatUser;
/* Get app */
const getActiveStatus = (state) => state.app.activeStatus;
/* Get users */
const getUsers = (state) => state.chatUser.users;
/* Get chat groups */
const getGroups = (state) => state.chatUser.groups;
/* Get user types */
const getTypes = (state) => state.chatUser.types;
/* Get active chats */
const getChats = (state) => state.chats;
/* Get users filtering query */
const getUsersQuery = (state) => state.app.searchUsersQuery;
/* Get groups filtering query */
const getGroupsQuery = (state) => state.app.searchGroupsQuery;
/* Unread chats object */
const getUnreadChats = (state) => state.app.unreadChats;

/**
 * @category Redux
 * @namespace Selectors
 * @description Selektory to funkcje które wyciągają z Reduxowego stanu potrzebne informacje
 * @see [redux selectors]{@link https://redux.js.org/recipes/computing-derived-data}
 */

/**
 * @function Selectors.getUsersAndGroups
 * @description Zmemoizowany selektor, zwraca połączone tablice użytkowników oraz grup.
 * @param {ReduxState} state - redux state
 * @returns {Array.<Object>}
 * @example
 * const mapStateToProps = (state) => {
 *     usersAndGroups: getUsersAndGroups(state)
 * };
 */
export const getUsersAndGroups = createSelector([getGroups, getUsers], (groups, users) =>
    Object.values(groups).concat(Object.values(users))
);

/**
 * @function Selectors.getFilterdUsers
 * @description Zmemoizowany selektor, Zwraca hash tabele z użytkownikami po przefiltrowaniu przez search query.
 * @param {ReduxState} state - redux state
 * @returns {Object.<string, User>}
 * @example
 * const mapStateToProps = (state) => {
 *     filteredUsers: getFilterdUsers(state)
 * };
 */
export const getFilterdUsers = createSelector([getUsers, getUsersQuery], (users, query) => {
    const result = {};

    if (!query) {
        return users;
    }
    Object.values(users)
        .filter((u) => u.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
        .forEach((el) => (result[el.id] = el));
    return result;
});
/**
 * @function Selectors.getFilteredGroups
 * @description Zmemoizowany selektor, Zwraca obiekt z grupami po przefiltrowaniu przez search query i sortowaniu przez liczbe nieodczytanych
 * wiadomości.
 * @param {ReduxState} state - redux state
 * @returns {Object.<string, Group>}
 * @example
 * const mapStateToProps = (state) => {
 *     filteredGroups: getFilteredGroups(state)
 * };
 */
export const getFilteredGroups = createSelector(
    [getGroups, getGroupsQuery, getUnreadChats],
    (groups, query, unreadChats) => {
        const result = {};

        if (!query) {
            return groups;
        }

        Object.values(groups)
            .filter((g) => g.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            .sort((prev, next) => sortUsersAndGroups(prev, next, unreadChats))
            .forEach((el) => (result[el.id] = el));
        return result;
    }
);

/**
 * @function Selectors.getFilteredUsersObject
 * @description Zmemoizowany selektor, Zwraca obiekt z typami uzytkowników jako kluczami i przefiltrowanymi oraz posortowanymi tablicami użytkowników jako wartościami.
 * @param {ReduxState} state - redux state
 * @returns {Object.<String<UserType>, User[]>}
 * @example
 * const mapStateToProps = (state) => {
 *     filteredUsersObj: getFilteredUsersObject(state)
 * };
 */
export const getFilteredUsersObject = createSelector(
    [getFilterdUsers, getTypes, getUnreadChats],
    (users, types, unreadChats) => usersArrayToObject(usersToArray(users), types, unreadChats)
);

/**
 * @function Selectors.getUsersObject
 * @description Zmemoizowany selektor, Zwraca obiekt z typami uzytkowników jako kluczami i tablicami użytkowników jako wartościami.
 * @param {ReduxState} state - redux state
 * @returns {Object.<String<UserType>, User[]>}
 * @example
 * const mapStateToProps = (state) => {
 *     UsersObj: getUsersObject(state)
 * };
 */
export const getUsersObject = createSelector([getUsers, getTypes], (users, types) =>
    usersArrayToObject(usersToArray(users), types)
);

/**
 * @function Selectors.getUserOrGroupInfo
 * @description Funkcja która zwraca zmemoizowany selektor. Selektor zwraca obiekt z informacjami o grupie lub użytkowniku.
 * @param {string} id  id - argument funkcji nadrzędnej
 * @param {ReduxState} state redux state - argument zwracanej funkcji
 * @returns {Object| undefined}
 * @example
 * const mapStateToProps = (state, props) => {
 *     chatInfo: getUserOrGroupInfo(props.id)(state)
 * };
 */
export const getUserOrGroupInfo = (id) =>
    createSelector([getUsersAndGroups], (arr) => arr.filter((el) => el.id === id)[0]);

/**
 * @function Selectors.getUnreadChatsCount
 * @description  Zmemoizowany selektor, Zwraca ilość czatów z nieodczytanymi wiadomościami.
 * @param {ReduxState} state - redux state
 * @returns {number}
 * @example
 * const mapStateToProps = (state) => {
 *     unreadCount: getUnreadChatsCount(state)
 * };
 */
export const getUnreadChatsCount = createSelector(getUnreadChats, (unreadChats) => {
    return Object.keys(unreadChats).reduce(
        (acc, key) => (acc += Number(unreadChats[key]) > 0 ? 1 : 0),
        0
    );
});

/**
 * @function Selectors.getChatByWindowID
 * @description  Funkcja która zwraca zmemoizowany selektor. Selektor zwraca obiekt czatu.
 * @param {string} windowID identyfikator okna z czatem - argument funkcji nadrzędnej
 * @param {ReduxState} state redux state - argument zwracanej funkcji
 * @returns {Chat}
 * @example
 * const mapStateToProps = (state, props) => {
 *     chat: getChatByWindowID(props.windowID)(state)
 * };
 */
export const getChatByWindowID = (windowID) =>
    createSelector([getChats, getUsers, getGroups], (chats, users, groups) => {
        const target = users[windowID] || groups[windowID];

        return target ? chats[target.roomID] : null;
    });

/**
 * @function Selectors.getSelf
 * @description  Zmemoizowany selektor, Zwraca obiekt z informacjami o aktualnym użytkowniku.
 * @param {ReduxState} state - redux state
 * @returns {{name: string, id: string, roomID: string, activeStatus: boolean}}
 * @example
 * const mapStateToProps = (state) => {
 *     self: getSelf(state)
 * };
 */
export const getSelf = createSelector(
    [getUser, getActiveStatus],
    ({ userNameToDisplay, userType, userID }, activeStatus) => {
        const roomID = userType + userID;
        return { name: userNameToDisplay, roomID, activeStatus, id: roomID };
    }
);

/**
 * @function Selectors.getWindowsWithNewMsgs
 * @description  Zmemoizowany selektor. Zwraca tablice windowIDs czatów które są oznaczone jako mające nowe wiadomości.
 * @param {ReduxState} state - redux state
 * @returns {string[]}
 * @example
 * const mapStateToProps = (state) => {
 *     windowsWithNewMsg: getWindowsWithNewMsgs(state)
 * };
 */
export const getWindowsWithNewMsgs = createSelector([getChats], (chats) =>
    Object.values(chats)
        .filter((ch) => ch.newMessage)
        .map((ch) => ch.windowID)
);

export const getShouldFlash = createSelector(
    [getChats, getUnreadChats],
    (chats, unread) =>
        Object.values(chats).some((ch) => ch.newMessage) ||
        Object.values(unread).some((num) => num > 0)
);
