import { UserState } from '../../../store/src/general/user/types';
import { PlaceEvent } from '../../../data/types';

export class GridCalcEvent {
    userState: UserState;
    event: PlaceEvent;
    timestampday: number;
    constructor(userState: UserState, event: PlaceEvent) {
        this.userState = userState;
        this.event = event;
        this.timestampday = 86400000;
    }
    calcStartRowAndOffsetTop() {
        const num =
            (this.event.startTime - this.event.startDayTimestamp) /
            (this.timestampday / this.userState.grid.numberOfRows);
        const startRow = Math.floor(num);
        const offsetTop = Number(num.toFixed(4).slice(-4)) / 100;
        return { startRow, offsetTop };
    }
    calcHeightEvent() {
        const ratePixelOnMillisecond = this.timestampday / (this.userState.grid.numberOfRows * 50);
        return (this.event.endTime - this.event.startTime) / ratePixelOnMillisecond;
    }
    getData() {
        const { startRow, offsetTop } = this.calcStartRowAndOffsetTop();
        return {
            height: this.calcHeightEvent(),
            startRow,
            offsetTop
        };
    }
}
