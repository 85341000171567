import { BaseApiResponse } from '../../types';
import { simpleQueryServerData } from '../simpleQueryServerData';
import { ApiScreenColumnsConfiguration } from './types';

/**
 * Update information about columns pin and visibility
 */
export async function updateScreenColumnsConfiguration(
    screen: string,
    columnConfig: ApiScreenColumnsConfiguration
): Promise<BaseApiResponse<null>> {
    return simpleQueryServerData(
        'api_react/src/listScreen/updateScreenColumnsConfiguration.php',
        'POST',
        {
            screen,
            columns: columnConfig
        }
    );

    //temporary use mock fixed value
    // return {
    //     message: 'Zaktualizowano',
    //     success: true,
    //     errorCode: null,
    //     data: null
    // };
}
