import { TemplateItem } from 'src/utils/src/shared/TemplateItem';
import { TemplateGlobalManager } from 'src/utils/src/shared/TemplateGlobalManager';

type TemplateHashProps = {
    temporaryAllString: string;
    templateItem: TemplateItem;
};

export class TemplateHash extends TemplateGlobalManager {
    temporaryAllString: string;
    templateItem: TemplateItem;
    constructor({ templateItem, temporaryAllString }: TemplateHashProps) {
        super();
        this.templateItem = templateItem;
        this.temporaryAllString = temporaryAllString;
    }
    getData() {
        const url = new URLSearchParams(window.location.search);
        return this.replaceContent({
            contentToReplace: url.get(this.templateItem.contentClear),
            templateItem: this.templateItem,
            temporaryAllString: this.temporaryAllString
        });
    }
}
