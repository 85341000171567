import React, { FC } from 'react';
import { UiGrid } from 'src/components/shared/atoms/uiLayout/uiGrid/UiGrid';
import { Controller, Control } from 'react-hook-form';
import { PopupFormBehavior, DynamicFieldT, SystemAction } from 'src/data/types';
import { DynamicField } from 'src/components/shared/molecules/dynamicField/DynamicField';
import { DynamicStateForm } from 'src/components/shared/molecules/dynamicForm/DynamicFormHandler';
import { DynamicFormInput } from 'src/components/shared/molecules/dynamicForm/DynamicFormInput';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';

type Props = {
    id: RowDataId;
    control: Control<DynamicStateForm>;
    action: SystemAction | null;
};

export const DynamicFormItem: FC<Props> = ({ id, control, action }) => {
    const behaviour = action?.behaviour as PopupFormBehavior;
    return (
        <div
            style={{
                display: 'flex',
                width: '420px',
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
            <UiGrid sx={{ width: '350px' }}>
                {behaviour?.data?.fields.map((field: DynamicFieldT) => {
                    if (DynamicFormInput.checkIfDisplay({ field, control })) {
                        return (
                            <Controller
                                key={field.kod}
                                name={id + '-' + field.kod}
                                control={control}
                                render={(props) => {
                                    return (
                                        <DynamicField
                                            {...field}
                                            {...props}
                                            isCheckboxTodayInDatePicker={true}
                                            isInputTimeInDatePicker={true}
                                        />
                                    );
                                }}
                            />
                        );
                    }
                })}
            </UiGrid>
        </div>
    );
};
