import React, { FC, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateFirst from 'src/components/shared/organisms/popupTemplateFirst/PopupTemplateFirst';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import { useTypedSelector, useAppDispatch } from 'src/store';
import { TableStructureManager } from 'src/store/src/listScreen/tableData/TableStructureManager';
import { GlobalPopup, ConfigPropertyArrayItem } from 'src/context/globalPopup/store';
import { setColumnsConfiguration } from 'src/store/src/listScreen';
import { useSearchParam } from 'src/hooks';
import { changeColumnConfiguration } from 'src/store/src/listScreen';
import { closePopup } from 'src/store/src/popup';
import { UI } from 'src/utils/src/list/manegersDataForInner/UI';
import styles from './PopupContentConfig.module.css';
import UICheckboxWewSquare from 'src/components/shared/atoms/uiInputs/uiCheckboxWewSquare/UICheckboxWewSquare';

const PopupContentConfig: FC = () => {
    const screen = useSearchParam('obiekt');
    const theme = useTheme();
    const objectDataState = useTypedSelector((state) => state.listScreenTableData);
    const dispatch = useAppDispatch();
    const { dispatchGlobalPopupContext, globalPopupState } = useContext(GlobalPopup);

    const setAll = () => {
        if (!screen) {
            console.error('Brak parametru "obiekt"');
            return;
        }
        const apiConfig = TableStructureManager.generateDataConfigForApi(
            globalPopupState.config.propertyCol
        );
        dispatch(
            changeColumnConfiguration(screen, {
                apiConfig: apiConfig,
                storeConfig: globalPopupState.config.propertyColObj
            })
        );

        dispatch(closePopup());
    };

    const ubdatePinnedItem = (item: ConfigPropertyArrayItem) => {
        const configPinnedCol = TableStructureManager.ubdatePinnedColItem(
            globalPopupState.config,
            item
        );
        dispatchGlobalPopupContext({
            type: 'SET_CONFIG',
            payload: {
                config: configPinnedCol
            }
        });
    };

    const ubdateVisibleItem = (item: ConfigPropertyArrayItem) => {
        const configPinnedCol = TableStructureManager.ubdateVisilbleColItem(
            globalPopupState.config,
            item
        );
        dispatchGlobalPopupContext({
            type: 'SET_CONFIG',
            payload: {
                config: configPinnedCol
            }
        });
    };

    useEffect(() => {
        const configPropertyCol = new TableStructureManager({
            columnDataEntries: objectDataState.columnData
        }).setStatePopupPropertyCol();

        dispatchGlobalPopupContext({
            type: 'SET_CONFIG',
            payload: {
                config: configPropertyCol
            }
        });
    }, [dispatchGlobalPopupContext, objectDataState]);

    return (
        <PopupTemplateFirst onClick={setAll} buttonTxt={'Zapisz'} title={'Dane do wyświetlenia'}>
            <div className={styles.wrapper}>
                <div className={styles.wrapperInner}>
                    {globalPopupState.config.propertyCol.map((item) => {
                        const isDisabled =
                            globalPopupState.config.limitIsAchieved && !item.isPinned;
                        return (
                            <div key={item.code} className={styles.boxOne}>
                                <div
                                    style={{
                                        border: `1px solid ${
                                            isDisabled ? '#949494' : theme.palette.primary.main
                                        } `,
                                        cursor: `${isDisabled ? 'default' : 'pointer'}`
                                    }}
                                    className={styles.boxTwo}
                                    onClick={isDisabled ? () => {} : () => ubdatePinnedItem(item)}>
                                    {' '}
                                    <ThemeCompatibleIcon
                                        SvgComponent={UI.chooseIconForPinned(item.statusIconPinned)}
                                        sx={{
                                            fontSize: '0.8rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingTop: '1px',
                                            background: `${
                                                item.statusIconPinned === 'isPinned'
                                                    ? theme.palette.primary.main
                                                    : 'transparent'
                                            }`
                                        }}
                                    />
                                </div>
                                <UICheckboxWewSquare
                                    checked={item.isVisible}
                                    onClick={() => ubdateVisibleItem(item)}
                                />
                                <p className={`${styles.p}`}>{item.name}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </PopupTemplateFirst>
    );
};

export default PopupContentConfig;
