import { BaseApiResponse } from '../../types';
import { simpleQueryServerData } from '../simpleQueryServerData';

/**
 * Save search option(some kind of search pattern) in system
 */
export async function saveFilter(
    screen: string,
    code: string,
    name: string
): Promise<BaseApiResponse<null>> {
    const body = {
        screen,
        name,
        code
    };
    return simpleQueryServerData('api_react/src/listScreen/saveFilter.php', 'POST', body);

    // return {
    //     message: 'Zapisano dane',
    //     success: true,
    //     errorCode: null,
    //     data: null
    // };
}
