import React, { useEffect, useState, useRef, useCallback } from 'react';
import { get, post } from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import Timer from '../components/Timer';
import AddTimer from '../components/AddTimer';
import AcceptDialog from '../../shared/components/AcceptDialog';
import Spinner from '../../shared/components/Spinner';

const useStyles = makeStyles(({ spacing }) => ({
    timer_content_root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        overflowY: 'hidden'
    },
    timer_content_list: {
        marginBottom: spacing(2.5),
        paddingTop: spacing(2.5),
        boxSizing: 'border-box',
        // height: spacing(30.8),
        flexGrow: 1,
        overflowY: 'auto'
    }
}));

/**
 * Stanowy komponent funkcyjny. Główny komponent aplikacji. Zarządza stanem aplikacji. Komunikuje się z backendem.
 * Wyświetla komponenty [Components/Timers/AddTimer]{@link AddTimer}, [Components/Timers/Timer]{@link Timer}, [Components/Shared/AcceptDialog]{@link AcceptDialog}, [Components/Shared/Spinner]{@link Spinner}
 * @component
 * @category Components
 * @subcategory Timers
 * @param {object} props - Props komponentu.
 * @param {boolean} props.open - gdy false komponent zostanie odmontowany
 * @param {Function} props.handleOpen - sluży do zamykania widżetu, zostanie wywołana także przy kliknięciu poza powierzchnią widżetu.
 * @param {'large'|'medium'|'small'} props.viewport - rozmiar viewportu
 * @property {number} timeOffset - stan różnicy pomiędzy czasem z serwera a lokalnym, słuzy do synchronizacji czasu
 * @property {number} setTimeOffset - funkcja ustawiania stanu setTimeOffset
 * @property {object} timers -  stan stoperów pobierany z backendu
 * @property {Funtion} setTimers - funckja ustawiania stanu timers
 * @property {string} fieldId -  stan id pola potrzebny do ściągnięcia listy opcji dla pola select
 * @property {Funtion} setFieldId - funckja ustawiania stanu fieldID
 * @property {number} timerInt -  stan identyfikatora interwału Timera
 * @property {Funtion} setTimerInt - funckja ustawiania stanu interwału Timera
 * @property {number} updateInt -  stan identyfikatora interwału pobierania danych z backendu
 * @property {Funtion} setUpdateInt - funckja ustawiania interwału  pobierania danych z backendu
 * @property {Funtion} setTimerIntCount - funckja ustawiania wartości interwału Timera
 * @property {boolean} loading -  stan loading applikacji, uruchamiany podczas wysyłania zapytań do backendu
 * @property {Funtion} setLoading - funckja ustawiania stanu loading
 * @property {number} activeId -  stan identyfikatora aktywnego Timera
 * @property {Funtion} setUpdateInt - funckja ustawiania stanu identyfikatora aktywnego timera
 * @property {null|functdialogOon} dialogOpen -  stan zarządzjący otwarciem dialogu.
 * @property {Funtion} setDialogOpen - funckja ustawiania stanu zarządzającego dialogiem.
 * @property {null|function} dialogCb -  stan funkcji zwrotnej przekazywanej do dialogu,
 * zostanie ona wywołana po zaakceptowaniu akcji w dialogu.
 * @property {Funtion} setDialogCb - funckja ustawiania stanu funkcji zwrotnej przekazywanej do dialogu.
 * @returns {ReactComponent}
 * @see [Components/Timers/AddTimer]{@link module:AddTimer}, [Components/Timers/Timer]{@link module:Timer}, [Components/Timers/TimersApp]{@link module:TimersApp}, [Components/Timers/AcceptDialog]{@link module:AcceptDialog}, [Components/Timers/Spinner]{@link module:Spinner}
 */
const Timers = ({ handleOpen, open, viewport }) => {
    /* Classes object */
    const classes = useStyles();

    /* Application state */
    const [timeOffset, setTimeOffset] = useState(0);
    const [timers, setTimers] = useState([]);
    const [fieldId, setFieldId] = useState(null);
    const [timerInt, _setTimerInt] = useState(null);
    const [updateInt, _setUpdateInt] = useState(null);
    const [, setTimerIntCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogCb, setDialogCb] = useState(null);

    // create interval ref, to be able to refer to it from inside function
    const containerRef = useRef();
    const timerIntRef = useRef(timerInt);
    const updateIntRef = useRef(updateInt);
    /* Use effects */

    /**
     * @memberof Timers
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook do pobierania danych po zamontowaniu komponentu.
     */
    useEffect(() => {
        post('api_react/stopery.php', { action: 'getFieldId' })
            .then(({ data }) => setFieldId(data))
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    /**
     * @memberof Timers
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook ustawia custom eventListener create_timer żeby wychwycić żądanie stworzenia timera z innej części aplikacji
     */
    useEffect(() => {
        const createAssignedTimer = async (e) => {
            const assignment = e.detail;
            const timers = await handleRequest('create_new');
            const newTimer = timers[timers.length - 1];
            await handleRequest('assign', newTimer.id, assignment);
            if (!open) handleOpen();
        };

        window.addEventListener('create_timer', createAssignedTimer);

        return () => {
            window.removeEventListener('create_timer', createAssignedTimer);
        };
    }, [handleOpen, open]);

    /**
     * @memberof Timers
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook uaktywnia się za każdym razem gdy aplikacja zostanie otworzona, ustawia interwały i zakłada odpowiednie eventListnery.
     */
    useEffect(() => {
        if (open) {
            handleRequest();
            // start update interval
            setUpdateInt(true);

            window.addEventListener('blur', handleBlur);
            window.addEventListener('focus', handleFocus);
        }
        return () => {
            window.removeEventListener('blur', handleBlur);
            window.removeEventListener('focus', handleFocus);
            setTimerInt(false);
            setUpdateInt(false);
        };
    }, [open]);

    /**
     * @memberof Timers
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook do zarzadzania lokalnym stanem aplikacji każdorazowo przy aktualizacji timers.
     */
    useEffect(() => {
        // check if any timer is running
        let activeTimer = timers.filter((t) => t.on_off === 1)[0];
        if (activeTimer) {
            handleStartLocal(activeTimer.id);
        } else {
            handlePauseLocal();
        }
    }, [timers]);

    /**
     * @memberof Timers
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook otwiera dialog za każdym razem gdy ustawiany jest dialogCb.
     */
    useEffect(() => {
        if (dialogCb) {
            setDialogOpen(true);
        }
    }, [dialogCb]);

    /**
     * @async
     * @memberof Timers
     * @method handleRequest
     * @description funkcja zarządzająca requestami do backendu
     * @param {string|null} [action] - identyfikator żądanej akcji
     * @param {number|null} [id] - identyfikator obiektu do którego odnosi się akcja.
     * @param {object|null} [options] - dodatkowe opcje
     * @returns {void}
     */
    const handleRequest = (action = null, id = null, options = null) => {
        if (!action) {
            return get('api_react/stopery.php')
                .then(({ data }) => {
                    setTimeOffset(data.time - Math.floor(Date.now() / 1000));
                    setTimers(data.timers);
                    return;
                })
                .catch((e) => console.log(e.message));
        } else {
            setLoading(true);
            return post('api_react/stopery.php', { action, id, options })
                .then(({ data }) => {
                    setTimers(data.timers);
                    setTimeOffset(data.time - Math.floor(Date.now() / 1000));
                    setLoading(false);
                    return data.timers;
                })
                .catch((e) => {
                    console.log(e.message);
                    setLoading(false);
                    return;
                });
        }
    };

    /* Manage intervals */

    const setTimerInt = useCallback((bool) => {
        // clear if already exist
        if (timerIntRef.current) {
            window.clearInterval(timerIntRef.current);
            _setTimerInt(null);
            setTimerIntCount(0);
        }
        // create new if param bool === true
        if (bool) {
            let int = window.setInterval(() => setTimerIntCount((prev) => ++prev), 1000);
            timerIntRef.current = int;
            _setTimerInt(int);
        }
    }, []);

    const setUpdateInt = useCallback((bool) => {
        // clear if already exist
        if (updateIntRef.current) {
            window.clearInterval(updateIntRef.current);
            _setUpdateInt(null);
        }
        // create new if param bool === true
        if (bool) {
            let int = window.setInterval(handleRequest, 10000);
            updateIntRef.current = int;
            _setUpdateInt(int);
        }
    }, []);

    /* Local actions */

    /**
     * @memberof Timers
     * @method handleStartLocal
     * 	@description ustawia stan aktywnego timera i włącza interwał Timera
     * @param {number} id
     * @return {void}
     */
    const handleStartLocal = (id) => {
        setActiveId(id);
        setTimerInt(true);
    };

    /**
     * @memberof Timers
     * @method handlePauseLocal
     * @description anuluje stan aktywnego timera i wyłącza interwał Timera
     * @return {void}
     */
    const handlePauseLocal = () => {
        setActiveId(null);
        setTimerInt(false);
    };

    /**
     * @memberof Timers
     * @method handleBlur
     * @description wyłącza interwały na blur okna
     * @return {void}
     */
    const handleBlur = useCallback(() => {
        setTimerInt(false);
        setUpdateInt(false);
    }, [setTimerInt, setUpdateInt]);

    /**
     * @memberof Timers
     * @method handleFocus
     * @description włącza interwały na focus okna
     * @return {void}
     */
    const handleFocus = () => {
        if (open) {
            handleRequest();
            setUpdateInt(true);
        }
    };
    /* Timer actions */

    /**
     * @memberof Timers
     * @method addTimer
     * @description wysyła do backendu żądanie stworzeia nowego timera.
     * @param {number} id - id timera
     * @return {void}
     */
    const addTimer = () => handleRequest('create_new');

    /**
     * @memberof Timers
     * @method startTimer
     * @description wysyła do backendu żądanie włączenia timera.
     * @param {number} id - id timera
     * @return {void}
     */
    const startTimer = (id) => {
        if (activeId !== id) {
            handleRequest('start', id);
        }
    };

    /**
     * @memberof Timers
     * @method pauseTimer
     * @description wysyła do backendu żądanie zatrzymania timera.
     * @param {number} id - id timera
     * @return {void}
     */
    const pauseTimer = (id) => {
        if (activeId === id) {
            handleRequest('pause', id);
        }
    };

    // Timer action possible confirmation needed

    /**
     * @memberof Timers
     * @method resetTimer
     * @description Zapisuje żądanie do backendu zresetowania timera jako callback.
     * @param {number} id - id timera
     * @return {void}
     */
    const clearTimer = (id) => {
        if (isConfirmationRequired(id)) {
            setDialogCb({ cb: () => handleRequest('clear', id) });
        }
    };

    /**
     * @memberof Timers
     * @method finishTimer
     * @description wysyła do backendu żądanie zakończenia timera lub zapisuje je jako callback.
     * @param {number} id - id timera
     * @return {void}
     */
    const finishTimer = (id) => {
        if (isConfirmationRequired(id)) {
            setDialogCb({ cb: () => handleRequest('finish', id) });
        } else {
            handleRequest('finish', id);
        }
    };

    /**
     * @memberof Timers
     * @method cancelTimer
     * @description wysyła do backendu żądanie anulowania timera lub zapisuje je jako callback.
     * @param {number} id - id timera
     * @return {void}
     */
    const cancelTimer = (id) => {
        if (isConfirmationRequired(id)) {
            setDialogCb({ cb: () => handleRequest('cancel', id) });
        } else {
            handleRequest('cancel', id);
        }
    };

    /**
     * @memberof Timers
     * @method handleCase
     * @description wysyła do backendu żądanie przypisania timera do sprawy lub zapisuje je jako callback.
     * @param {number} id - id timera
     * @param {object|null} options - opcje przypisania - w przypadku null - kasuje przypisanie
     * @return {void}
     */
    const handleCase = (id, options) => {
        // jesli jest już przypisana sprawa
        if (timers.find((t) => t.id === id)['sprawa']) {
            setDialogCb({ cb: () => handleRequest('assign', id, options) });
        } else {
            handleRequest('assign', id, options);
        }
    };

    /**
     * @memberof Timers
     * @method confirmDialog
     * @description Wywołuje zapisany w stanie callback (żądanie do backendu), po czym kasuje callback i zamyka dialog.
     * @return {void}
     */
    const confrirmDialog = () => {
        if (dialogCb) {
            dialogCb.cb();
        }
        setDialogCb(null);
        setDialogOpen(false);
    };

    /**
     * @memberof Timers
     * @method rejectDialog
     * @description Kasuje zapisany w stanie Callback i zamyka dialog.
     * @return {void}
     */
    const rejectDialog = () => {
        if (dialogCb) {
            setDialogCb(null);
        }
        setDialogOpen(false);
    };

    /**
     * @memberof Timers
     * @method isConfirmationRequired
     * @description Zwraca true, jesli timer jest aktywny, lub jego stan nie równa się zero.
     * @param id - Id timera.
     * @return {bool}
     */
    const isConfirmationRequired = (id) =>
        id === activeId || timers.find((t) => t.id === id)['stan_stopera'] !== 0;

    return (
        <div className={classes.timer_content_root}>
            <AcceptDialog
                open={dialogOpen}
                handleAccept={confrirmDialog}
                handleReject={rejectDialog}
            />
            {loading && <Spinner />}
            <AddTimer clickHandler={addTimer} />
            <List className={classes.timer_content_list} ref={containerRef}>
                {timers.map((t) => (
                    <Timer
                        fieldId={fieldId}
                        key={t.id}
                        containerRef={containerRef}
                        handleCase={handleCase}
                        data={t}
                        timeOffset={timeOffset}
                        start={startTimer}
                        pause={pauseTimer}
                        clear={clearTimer}
                        finish={finishTimer}
                        cancel={cancelTimer}
                        activeId={activeId}
                        viewport={viewport}
                    />
                ))}
            </List>
        </div>
    );
};

export default Timers;
