import React from 'react';
import { Popover } from '@mui/material';
import UserIcon from 'src/assets/menu_uzytkownika.svg';
import UserList from 'src/components/menu/organisms/userList/UserList';
import { useTypedSelector } from 'src/store';
import MyReport from 'src/components/menu/organisms/myReport/MyReport';
import ThemeCompatibleIcon from 'src/components/shared/molecules/themeCompatibleIcon/ThemeCompatibleIcon';
import styles from './UserSection.module.css';
export type TypesOfContentPopOver = 'USER_LIST' | 'MY_REPORT';

const UserSection = () => {
    const user = useTypedSelector((state) => state.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [contentPopOver, setContentPopOver] = React.useState<TypesOfContentPopOver>('USER_LIST');
    const handleClick = (_e: React.BaseSyntheticEvent) => {
        setAnchorEl(_e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setTimeout(() => setContentPopOver('USER_LIST'), 500);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <div className={styles.wrapper} aria-describedby={id} onClick={handleClick}>
                <ThemeCompatibleIcon
                    SvgComponent={UserIcon}
                    fontSize={'large'}
                    sx={{
                        fontSize: '3rem',
                        marginTop: '4px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                />
                <div>
                    <p className={styles.p}>{user.firstName ? user.firstName : user.login}</p>
                    <p className={styles.p}>{user.lastName && user.lastName}</p>
                </div>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{
                    '& .MuiPaper-root': {
                        overflow: 'visible'
                    }
                }}>
                {contentPopOver === 'USER_LIST' && (
                    <UserList setContentPopOver={setContentPopOver} handleClose={handleClose} />
                )}
                {contentPopOver === 'MY_REPORT' && <MyReport onClose={handleClose} />}
            </Popover>
        </div>
    );
};

export default React.memo(UserSection);
