import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UiInputSharedProps } from 'src/components/shared/atoms/uiInputs/types';
import styles from './UiInputElLabel.module.css';
export const UiInputElLabel: FC<UiInputSharedProps> = (props) => {
    const theme = useTheme();

    return (
        <Typography
            classes={styles.popup}
            sx={{
                color: `${theme.palette.primary.main}`,
                ...props.sxlabel
            }}>
            {props.label}
        </Typography>
    );
};
