import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import styles from 'src/components/shared/atoms/datePicker/Error.module.css';
import { dataPicker } from 'src/constants/subtitles';
import React from 'react';

export const Error = () => {
    const theme = useTheme();
    return (
        <div
            style={{
                border: `2px solid ${theme.palette.error.main}`
            }}
            className={styles.wrapper}>
            <div className={styles.box}>
                <Typography sx={{ color: theme.palette.error.main, fontWeight: 'bold' }}>
                    {dataPicker.error}
                </Typography>
            </div>
        </div>
    );
};
