import { VariableJMK } from 'src/store/src/calendar/calendar/types';

export class CalendarManager {
    static getVariableJMK() {
        const variableJMK: VariableJMK = {
            object: 'kalendarz_osobisty',
            objectID: 0,
            objectName: 'Kalendarz osobisty',
            type: 'personal'
        };
        variableJMK.objectID = Number(new URL(window.location.href).searchParams.get('obiekt'));
        if (new URL(window.location.href).searchParams.get('zasoby')) {
            variableJMK.type = 'resources';
            variableJMK.object = 'kalendarzzbiorczyzasobow';
            variableJMK.objectName = 'Kalendarz zasobów';
        } else if (new URL(window.location.href).searchParams.get('wszyscy')) {
            variableJMK.type = 'team';
            variableJMK.object = 'kalendarzzbiorczy';
            variableJMK.objectName = 'Kalendarz zbiorczy';
        }
        return variableJMK;
    }
}
