import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusTableState, StatusTableObject } from './types';

export const initialState: StatusTableState = {
    statusFieldCode: '',
    dislikeStatus: null,
    likeStatus: null,
    objects: [],
    statuses: []
};

const statusTableState = createSlice({
    name: 'listScreenStatusTable',
    initialState,
    reducers: {
        setStatusTableFromApi: (
            state: StatusTableState,
            action: PayloadAction<StatusTableState>
        ) => {
            const { statusFieldCode, dislikeStatus, likeStatus, objects, statuses } =
                action.payload;
            state.statusFieldCode = statusFieldCode;
            state.dislikeStatus = dislikeStatus;
            state.likeStatus = likeStatus;
            state.objects = objects;
            state.statuses = statuses;
        },
        setStatusTableObjects: (
            state: StatusTableState,
            action: PayloadAction<StatusTableObject[]>
        ) => {
            state.objects = action.payload;
        }
    }
});

export const { setStatusTableObjects, setStatusTableFromApi } = statusTableState.actions;

export default statusTableState.reducer;
