import React, { useEffect, useRef, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import {
    downloadFileFromResponse,
    generateToken,
    makeFormData,
    navigateToPreparedComponent,
    updateIdWindow,
    waitForElementLoad
} from 'src/utils/index';
import { useSearchParams, useUpdateUserInfo } from 'src/hooks/index';
import { getState } from 'src/store';
import { phpScreenToComponentUrl } from 'src/constants';
import { ScriptAttribute, ScriptData, PageContentReturn, PhpMapperProps } from './types';
import { ContentResponseTypes, InjectingEndStatus } from './enums';
import { LoaderPHP } from 'src/components/shared/organisms/loader/LoaderPHP';
import { isSafari } from 'react-device-detect';

const onloadShowAction =
    " onload=\"document.querySelector('#php_frame')?.classList?.remove('invisible');\" ";

const PHPMaper: FC<PhpMapperProps> = ({ basename }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const phpFrameRef = useRef<HTMLIFrameElement>(null);
    const loaderRef = useRef<HTMLIFrameElement>(null);
    const headInjectSection = useRef<HTMLElement | null>(null);

    // token generated at start of each injection, because page can be reinjected before last
    // injection ends, and then that sripts can generate errors, to prevent that, this token should
    // be checked before each script injection
    const injectToken = useRef<string>('');

    const queryParams = useSearchParams();

    const systemName = getState().system.systemName;

    const updateUserInfo = useUpdateUserInfo();

    useEffect(() => {
        headInjectSection.current = document.querySelector('#inject-head-section');

        // clear after self in head
        return () => {
            if (headInjectSection.current) {
                headInjectSection.current.innerHTML = '';
                headInjectSection.current = null;
            }
        };
    }, []);

    useEffect(() => {
        // system for safari to refresh content of php page because navigation back use cached
        // version of that page
        const safariRefresh = (event: PageTransitionEvent) => {
            if (event.persisted) {
                updatePhpFrame();
            }
        };
        if (isSafari) {
            window.addEventListener('pageshow', safariRefresh);
        }

        updatePhpFrame();

        return () => {
            if (isSafari) window.removeEventListener('pageshow', safariRefresh);
        };
    }, [location]);

    /**
     * Make necessary actions to populate 'php_frame' with php result
     */
    const updatePhpFrame = async () => {
        // get php url from where get content
        let pureUrl = queryParams.get('php_url');
        const actualInjectionToken = generateToken();
        injectToken.current = actualInjectionToken;

        if (!pureUrl) {
            console.warn('Link query params does not have php_url, invalid link');
            pureUrl = location.pathname === '/' ? '/ekran_startowy.php' : location.pathname;
        }

        // navigate to component if is prepared fo that page
        if (pureUrl in phpScreenToComponentUrl) {
            navigateToPreparedComponent(
                queryParams,
                phpScreenToComponentUrl[pureUrl],
                navigate,
                location
            );
            return;
        }

        const url =
            (pureUrl.startsWith('https://')
                ? ''
                : (pureUrl.startsWith(basename) ? '' : basename) +
                  (pureUrl.startsWith('/') ? '' : '/')) + pureUrl;

        // clean last page
        loaderRef.current?.classList?.add('visible');
        if (phpFrameRef.current != null) phpFrameRef.current.innerHTML = '';
        if (headInjectSection.current != null) headInjectSection.current.innerHTML = '';

        // hide phpFrame content, which will be restored after all would be loaded to get rid of
        // styles flickering
        phpFrameRef.current?.classList?.add('invisible');

        const status = await loadContent(url, actualInjectionToken);
        updateIdWindow();
        // on token changes shouldn't remove loader because other updatePhpFrame will do that
        if (status !== InjectingEndStatus.TOKEN_CHANGED) {
            loaderRef.current?.classList?.remove('visible');
            phpFrameRef.current?.classList?.remove('invisible');
        }
    };

    /**
     * Load content from @param url to actual app, by fetch them and properly add to dom
     * @return InjectingEndStatus function work end status
     */
    const loadContent = async (url: string, token: string) => {
        const contentResponse = await getPageContent(url);
        const oldState = { ...window.history.state };

        switch (contentResponse.type) {
            case ContentResponseTypes.ERROR:
            case ContentResponseTypes.CONTENT_LOOP:
                console.warn("Content loop occured, navigation to '/'");
                navigate('/', { replace: true });
                window.history.replaceState({ ...window.history.state, ...oldState }, '');
                return InjectingEndStatus.ERROR;
            case ContentResponseTypes.FILE:
                return await handleFileResult(contentResponse.data);
            case ContentResponseTypes.VALID_CONTENT:
                break;
            default:
                break;
        }

        // so here is valid content case

        // separate scripts from body and head
        const splittedHtml = splitHtml(contentResponse.data);

        console.debug(`PHPMaper: Loading scripts, count: ${splittedHtml.scripts.length}`);

        // actual inject html content
        return await injectHtml(splittedHtml, token);
    };

    const getPageContent = async (url: string): Promise<PageContentReturn> => {
        // prapare form data
        var formdata = await makeFormData(
            queryParams,
            true,
            sessionStorage.getItem('phpHiddenFormData')
        );
        sessionStorage.removeItem('phpHiddenFormData'); // remove item so next nav won't use it

        // get html from server

        var requestOptions = {
            method: 'POST',
            body: formdata
            // redirect: 'follow'
        };

        let res;
        try {
            res = await fetch(url, requestOptions);
        } catch (e) {
            console.error('PhpMapper: ', e);
            // handle unvalid url, situation when user write url to file that is prohibited, server
            // return in that case cors error, handle this by navigating to home
            return { type: ContentResponseTypes.ERROR, data: null };
        }

        // if res is redirected to index.php it is highly possible that user was logged off
        if (res.redirected && res.url.endsWith('index.php')) {
            updateUserInfo();
        }

        // check if it is a non-html page
        if (res.headers.get('content-disposition')?.includes('attachment')) {
            return { type: ContentResponseTypes.FILE, data: res };
        }

        const html_result = await res.text();

        // handle unvalid url, situation when user write url to page that doesn't exists, server
        // return in that case html with this react app, navigate to home istead of nest react in
        // react
        if (html_result.includes('inject-head-section')) {
            return { type: ContentResponseTypes.CONTENT_LOOP, data: null };
        }

        return { type: ContentResponseTypes.VALID_CONTENT, data: html_result };
    };

    /**
     * divide htmlText into data defining how to make scripts and clean html divided into head and
     * body
     * @param {*} htmlText
     */
    const splitHtml = (htmlText: string) => {
        const regex = /<script( )?((.|\n)*?)>((.|\n|\r|\t)*?)<\/script>/gm;
        const matches = htmlText.matchAll(regex);

        let cleanHtml = '';
        const scripts = [];

        let lastEndScriptIndex = 0;
        for (const match of matches) {
            // make object with script tag data easy to build them
            const attributes = makeAttributesObject(match);
            scripts.push({
                content: match[4],
                attributes: attributes,
                isInline: !(attributes && attributes.findIndex((e) => e.name === 'src') !== -1)
            });

            cleanHtml += htmlText.slice(lastEndScriptIndex, match.index);
            lastEndScriptIndex = (match.index ?? 0) + match[0].length;
        }

        // when can't find any script all of text is clean html
        if ([...matches].length <= 0) {
            cleanHtml = htmlText;
        }

        // split head content from body
        const headMatch = /<head>((.|\n|\r|\t)*?)<\/head>/gm.exec(cleanHtml);
        const cleanHead = headMatch ? headMatch[1] : '';
        const matchIdx = headMatch ? headMatch.index : null;
        const cleanBody =
            matchIdx && headMatch
                ? cleanHtml.substring(0, matchIdx) +
                  cleanHtml.substring(matchIdx + headMatch[0].length)
                : cleanHtml;

        return { body: cleanBody, head: cleanHead, scripts };
    };

    /**
     * inject data from @param splittedHtml to actual dom
     * @return InjectingEndStatus function work end status
     */
    const injectHtml = async (
        splittedHtml: {
            body: string;
            head: string;
            scripts: ScriptData[];
        },
        token: string
    ) => {
        const { body, scripts } = splittedHtml;

        if (phpFrameRef.current === null) {
            console.warn('PhpMapper: cannot inject html, there is no #php_frame');
            return InjectingEndStatus.ERROR;
        }

        // add to head most important crm_new_2.css styles onload handler making php_frame visible
        const head = insertIntoString(
            splittedHtml.head,
            onloadShowAction,
            '<link rel="stylesheet" href="crm_new_2.css?v=2.5.2"'
        );

        //inject clean head
        if (headInjectSection.current) injectHtmlToElement(headInjectSection.current, head, 'meta');

        injectHtmlToElement(phpFrameRef.current, body, 'div');

        console.debug(
            `PHPMaper: Injecting to head ${headInjectSection.current != null}, content length: ${
                head.length
            }`
        );

        // add scripts more manually in order to execute them
        return await injectScripts(scripts, phpFrameRef.current, token);
    };

    /**
     * Enable to wait for @param parent loads @param htmlContent
     */
    const injectHtmlToElement = (
        parent: HTMLElement,
        htmlContent: string,
        middleNodeType: 'div' | 'meta'
    ) => {
        const middleNode = document.createElement(middleNodeType);

        middleNode.innerHTML = htmlContent;

        parent.innerHTML = '';
        parent.appendChild(middleNode);
    };

    /**
     * inject scripts to dom by making manually element for every script
     * @param scripts scripts data based on make scripts
     * @param parent where inject scripts
     * @return InjectingEndStatus function work end status
     */
    const injectScripts = async (scripts: ScriptData[], parent: Element, token: string) => {
        for (const scriptData of scripts) {
            if (token !== injectToken.current) return InjectingEndStatus.TOKEN_CHANGED;
            // make script tag and add them to parent
            const scriptElement = document.createElement('script');
            scriptElement.innerHTML = scriptData.content;

            if (scriptData.attributes) {
                for (const { name, value } of scriptData.attributes) {
                    scriptElement.setAttribute(name, value ?? '');
                }
            }

            try {
                parent.appendChild(scriptElement);
            } catch (e: any) {
                console.error(
                    `PHPMaper: error occured: ${e} \nWhen injecting script: ${scriptData}`
                );
            }

            // to wait for loading to every element so elements will be loaded by browser force in
            // order they are given in html, because when are dynamicaly adding to dom, that load
            // order can change
            if (!scriptData.isInline) {
                await waitForElementLoad(scriptElement);
            }
        }
        return InjectingEndStatus.SUCCESS;
    };

    /**
     * return @param text with inserted @param contentToInsert behind position of @param searchString
     */
    const insertIntoString = (text: string, contentToInsert: string, searchString: string) => {
        const insertIndex = text.indexOf(searchString);

        if (insertIndex < 0) {
            return text;
        }

        return text.slice(0, insertIndex) + searchString + text.slice(insertIndex);
    };

    /**
     * extract easy to use attributes from script match object
     * @param {*} match
     */
    const makeAttributesObject = (match: RegExpMatchArray): ScriptAttribute[] | null => {
        if (!match[2]) {
            return null;
        }

        const attributesObj = [];
        const attributes = match[2].replaceAll('\n', ' ');

        // Use a regular expression to match attribute key-value pairs
        const regex = /\b(\w+)(\s*=\s*["']([^"']*)["'])?/g;
        let splitMatch;

        while ((splitMatch = regex.exec(attributes)) !== null) {
            attributesObj.push({
                name: splitMatch[1],
                value: splitMatch.length >= 4 ? splitMatch[3] : undefined
            });
        }

        return attributesObj;
    };

    /**
     * Do apropriate actions when php result is not a html page
     */
    const handleFileResult = async (res: Response) => {
        await downloadFileFromResponse(res, `plik ${systemName}`);

        // after file download return to previous page
        window.history.back();

        return InjectingEndStatus.SUCCESS;
    };

    return (
        <Box
            sx={{
                position: 'relative'
            }}>
            <Box
                ref={loaderRef}
                sx={{
                    visibility: 'hidden'
                }}>
                <LoaderPHP />
            </Box>
            <Box id="php_frame" ref={phpFrameRef}></Box>
        </Box>
    );
};

export default PHPMaper;
