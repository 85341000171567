import React, { FC } from 'react';
import { GlobalPopupCrossProps } from './types';
import styles from './GlobalPopupCross.module.css';
import { useTheme } from '@mui/material/styles';

const GlobalPopupCross: FC<GlobalPopupCrossProps> = ({ width, height }) => {
    const theme = useTheme();
    return (
        <div className={styles.close}>
            <div
                className={styles.closeOne}
                style={{
                    backgroundColor: theme.palette.primary.main,
                    width: `${width && width + 'px'}`,
                    height: `${height && height + 'px'}`
                }}></div>
            <div
                className={styles.closeTwo}
                style={{
                    backgroundColor: theme.palette.primary.main,
                    width: `${width && width + 'px'}`,
                    height: `${height && height + 'px'}`
                }}></div>
        </div>
    );
};

export default GlobalPopupCross;
