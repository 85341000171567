/* eslint-disable indent */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit/';
import { AppThunk, RootState } from '../';
import { fetchCategories } from '../../../../api';
import { resetApp } from '../sharedActions';
import { Categories, CategoriesState, Category } from './types';

export const initialState: CategoriesState = {
    categories: []
};

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategories: (state, action: PayloadAction<Categories>) => {
            state.categories = action.payload;
        },
        selectCategory: (state, action: PayloadAction<number>) => {
            const category = state.categories.find((c) => c.id === action.payload);
            /* Selected categories must be unique and must be ids of existing categories */
            if (!category) throw new ReferenceError('Category not found');
            if (category.selected) throw new TypeError('Selecting already selected category');

            category.selected = true;
        },
        deselectCategory: (state, action: PayloadAction<number>) => {
            const category = state.categories.find((c) => c.id === action.payload);
            /* Selected categories must be unique and must be ids of existing categories */
            if (!category) throw new ReferenceError('Category not found');
            if (!category.selected) throw new TypeError('Deselecting not selected category');

            category.selected = false;
        },
        toggleCategory: (state, action: PayloadAction<number>) => {
            const category = state.categories.find((c) => c.id === action.payload);
            /* Selected categories must be unique and must be ids of existing categories */
            if (!category) throw new ReferenceError('Category not found');

            category.selected = !category.selected;
        },
        clearAllSelected: (state) => {
            state.categories.forEach((c) => {
                c.selected = false;
            });
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, () => {
            return initialState;
        });
    }
});

/* Thunk actions */
export const handleCategories =
    (categories: Category[]): AppThunk =>
    (dispatch) => {
        dispatch(setCategories(categories.map((c) => ({ ...c, color: '#' + c.color }))));
    };

export const fetchAndHandleCategories = (): AppThunk => async (dispatch, getState) => {
    try {
        const { variableJMK, entityID } = getState().calendar;
        const data = await fetchCategories(variableJMK.type, entityID);
        if ('errorCode' in data) throw data;

        dispatch(handleCategories(data.categories));
    } catch (e: any) {
        if ('errorCode' in e) console.error('error status:', e.errorCode);
        console.error('fetch categories:', e.message);
    }
};

/* Selectors */

const getCategoryByName = (state: RootState, name: string) =>
    Object.values(state.categories.categories).find((c) => c.name === name);
const getCategories = (state: RootState) => state.categories.categories;

export const getSelectedCategories = createSelector(getCategories, (cats: Categories) => {
    return cats.filter((cat) => cat.selected);
});

export const makeIsCategoryByNameSelected = () =>
    createSelector(getCategoryByName, (category: Category | undefined) => {
        return category ? category.selected : false;
    });

export const { clearAllSelected, deselectCategory, selectCategory, setCategories, toggleCategory } =
    categoriesSlice.actions;

export default categoriesSlice.reducer;
