import React, { FC } from 'react';
import { useComponentSize } from 'react-use-size';

import { NormalScroll } from 'src/components/shared/molecules/normalScroll/NormalScroll';
import styles from './PopupTemplateFour.module.css';

type Props = {
    maxWidth: number;
    maxHeight: number;
};

export const PopupTemplateFour: FC<Props> = ({ children, maxHeight, maxWidth }) => {
    const { ref, height, width } = useComponentSize();

    const setScrollHeight = height > maxHeight;
    const setScrollWidth = width > maxWidth;
    if (setScrollHeight || setScrollWidth) {
        return (
            <NormalScroll width={maxWidth} height={maxHeight}>
                <div className={styles.box} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.box2}>{children}</div>
                </div>
            </NormalScroll>
        );
    }
    return (
        <div className={styles.box} onClick={(e) => e.stopPropagation()} ref={ref}>
            <div className={styles.box2}>{children}</div>
        </div>
    );
};
