import { EventStub } from '../types';
import { CalendarDays, Grid } from '../../store/src/calendar/calendar/types';
import { createGridDays } from './createGridDays';
import { placeEvents } from './placeEvents';
import { UserState } from '../../store/src/general/user/types';
import { OverflowEventArrayCreator } from './OverflowEventArrayCreator';

/**
 * transform partially formed events into ready made calendar user
 * @param limit - should number of events displayed in one column be limited
 */
export function processGridEvents(
    eventStubs: EventStub[],
    days: CalendarDays,
    userState: UserState,
    limit?: number
): Grid {
    // create days dictionary, with array of partially formed events for each day

    const gridDays = createGridDays(eventStubs, days);
    const grid: Grid = {};
    // iterate over days dictionary, form UserGridState, place events, check for overflow for each day
    for (const timestamp in gridDays) {
        const [events, overflow] = placeEvents(gridDays[timestamp], userState, limit);

        // const overflowedEvents = limit ? calculateOverflowedEvents(gridDays[timestamp], events,userState) : Array(userState.grid.numberOfRows).fill(0);
        const overflowEventsArrayCreator = new OverflowEventArrayCreator({
            allEvents: gridDays[timestamp],
            visibleEvents: events,
            userState: userState
        });
        const overflowedEvents = overflowEventsArrayCreator.getArrayWithNumbersOfOverflowEvents();
        grid[timestamp] = { events, overflow, overflowedEvents };
    }
    return grid;
}
