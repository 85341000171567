import getYear from 'date-fns/getYear';
import startOfWeek from 'date-fns/startOfWeek';
import subDays from 'date-fns/subDays';
import endOfWeek from 'date-fns/endOfWeek';
import getMonth from 'date-fns/getMonth';
import addToDate from 'date-fns/add';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { batch } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../..';
import { resetApp } from '../sharedActions';
import {
    CalendarState,
    CalendarView,
    CalendarDays,
    Months,
    CalendarEvents,
    Grid,
    NoGridEvents,
    CalendarDataControl,
    EnteredHours,
    VariableJMK
} from './types';
import subFromDate from 'date-fns/sub';
import { getQueryAsObject } from 'src/utils';
import { MONTH_NAMES } from 'src/constants';
import { fetchCalendarData } from 'src/api';
import { CalendarEvent } from 'src/api/types';
import { processData } from 'src/data';
import {
    adjustTimestamp,
    getCalendarMonthBoundries,
    getDaysRange,
    getFromSessionStorage,
    saveInSessionStorage
} from 'src/utils';
import { fetchIcalUrl } from 'src/api';
import { createIcalUrl } from 'src/api/src/calendar/createIcalUrl';
import { Duration } from './types';
import { CalendarManager } from 'src/store/src/calendar/calendar/CalendarManager';
import { getView } from 'src/utils/src/calendar/getView';

enum SessionStorageKeys {
    Date = 'calendar_date',
    View = 'calendar_view'
}

const date = new Date();

export const initialState: CalendarState = {
    icalUrl: null,
    entityID:
        (window.__JMK__config || {}).entityID === undefined
            ? undefined
            : Number(window.__JMK__config.entityID),
    view: getView(),
    selectedDate: date.getTime(),
    variableJMK: {
        object: '',
        objectID: 0,
        objectName: '',
        type: 'personal'
    },
    from: 0,
    to: 0,
    loading: false,
    days: [],
    month: MONTH_NAMES[date.getMonth()],
    year: date.getFullYear(),
    events: {},
    grid: {},
    enteredHours: {},
    noGridEvents: [],
    selectedEvent: null,
    selectedRow: null,
    loadOnFocus: false
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setDate: (state, action: PayloadAction<number>) => {
            saveInSessionStorage(SessionStorageKeys.Date, action.payload);
            state.selectedDate = action.payload;
        },
        setView: (state, action: PayloadAction<CalendarView>) => {
            localStorage.setItem('calendarView', action.payload);
            state.view = action.payload;
        },
        setVariableJMK: (state, action: PayloadAction<VariableJMK>) => {
            state.variableJMK = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setFrom: (state, action: PayloadAction<number>) => {
            state.from = action.payload;
        },
        setTo: (state, action: PayloadAction<number>) => {
            state.to = action.payload;
        },
        setDays: (state, action: PayloadAction<CalendarDays>) => {
            state.days = action.payload;
        },
        setMonth: (state, action: PayloadAction<Months>) => {
            state.month = action.payload;
        },
        setYear: (state, action: PayloadAction<number>) => {
            state.year = action.payload;
        },
        setEvents: (state, action: PayloadAction<CalendarEvents>) => {
            state.events = action.payload;
        },
        setGridEvents: (state, action: PayloadAction<Grid>) => {
            state.grid = action.payload;
        },
        setNoGridEvents: (state, action: PayloadAction<NoGridEvents>) => {
            state.noGridEvents = action.payload;
        },
        setEnteredHours: (state, action: PayloadAction<EnteredHours>) => {
            state.enteredHours = action.payload;
        },
        setSelectedEvent: (state, action: PayloadAction<{ id: number; type: string }>) => {
            const eventKey = action.payload.type + '_' + action.payload.id;
            if (state.events[eventKey]) state.selectedEvent = state.events[eventKey];
        },
        cancelSelectedEvent: (state) => {
            if (state.selectedEvent) state.selectedEvent = null;
        },
        setSelectedRow: (state, action: PayloadAction<number | null>) => {
            state.selectedRow = action.payload;
        },
        setIcalUrl: (state, action: PayloadAction<null | string>) => {
            state.icalUrl = action.payload;
        },
        setOnLoad: (state, action: PayloadAction<boolean>) => {
            state.loadOnFocus = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetApp, () => {
            return initialState;
        });
    }
});

export const VIEW_LABELS = {
    day: 'Dzień',
    workweek: 'Tydzień rob.',
    week: 'Tydzień',
    month: 'Miesiąc'
};

export const {
    setDate,
    setView,
    setFrom,
    setLoading,
    setTo,
    setDays,
    setMonth,
    setYear,
    setEvents,
    setGridEvents,
    setNoGridEvents,
    setEnteredHours,
    setSelectedEvent,
    cancelSelectedEvent,
    setSelectedRow,
    setIcalUrl,
    setVariableJMK,
    setOnLoad
} = calendarSlice.actions;

/* Thunk Actions */

// Controls if conditional data fetch will be called
let fetchID = 0;
export const control: CalendarDataControl = {
    fetching: false,
    lastFetched: 0,
    id: fetchID
};

export const handleCalendarDataUpdate =
    (data: CalendarEvent[]): AppThunk =>
    (dispatch, getState) => {
        // get required properties from store
        const { days, view } = getState().calendar;
        const userState = getState().user;
        const selectedEmpl = getState()
            .resources.resources.filter((r) => r.selected)
            .map((r) => r.id);

        // Here  each event startTime and endTime is modify via how javascript reference works. Therefore not only calendarEvents,
        // but also initial events will keep it

        let calEv = {};

        data.forEach((item) => {
            const typeId = item.type + '_' + item.id;
            const newItem = {
                ...item,
                startTime: item.startTime,
                endTime: item.endTime
            };
            Object.assign(calEv, { [typeId]: newItem });
        });

        // check if fetched data is equal to saved data, and return if so
        // if (isEqual(calendarEvents, baseEvents)) return;

        // let start = performance.now();
        // process all data
        const { enteredHours, events, grid, noGrid } = processData(
            calEv,
            data,
            days,
            view,
            selectedEmpl,
            userState
        );

        // batch for batched update
        batch(() => {
            dispatch(setEvents(events));
            dispatch(setEnteredHours(enteredHours));
            grid && dispatch(setGridEvents(grid));
            noGrid && dispatch(setNoGridEvents(noGrid));
        });
    };

/**
 * gets array of events from api call processes it and dispatches to store
 *
 *
 *
 */
export const handleCalendarData =
    (data: CalendarEvent[]): AppThunk =>
    (dispatch, getState) => {
        // get required properties from store
        const { days, view } = getState().calendar;
        const userState = getState().user;
        const selectedEmpl = getState()
            .resources.resources.filter((r) => r.selected)
            .map((r) => r.id);

        // Here  each event startTime and endTime is modify via how javascript reference works. Therefore not only calendarEvents,
        // but also initial events will keep it

        const calendarEvents = data.reduce((acc, ev) => {
            ev.startTime = adjustTimestamp(ev.startTime, 'js');
            ev.endTime = adjustTimestamp(ev.endTime, 'js');
            acc[ev.type + '_' + ev.id] = ev;
            return acc;
        }, {} as CalendarEvents);

        // check if fetched data is equal to saved data, and return if so
        // if (isEqual(calendarEvents, baseEvents)) return;

        // let start = performance.now();
        // process all data
        const { enteredHours, events, grid, noGrid } = processData(
            calendarEvents,
            data,
            days,
            view,
            selectedEmpl,
            userState
        );

        // batch for batched update
        batch(() => {
            dispatch(setEvents(events));
            dispatch(setEnteredHours(enteredHours));
            grid && dispatch(setGridEvents(grid));
            noGrid && dispatch(setNoGridEvents(noGrid));
        });
    };

export const fetchAndHandleCalendarData =
    (action?: Function): AppThunk =>
    async (dispatch, getState) => {
        // store id of transation
        const transactionID = ++fetchID;
        // clear window timeout to not fire debounced version of function
        window.clearTimeout(timeout!);

        try {
            // Gather data from the store
            const { calendar, categories, resources } = getState();
            const variableJMK = CalendarManager.getVariableJMK();
            const { from, to, entityID } = calendar;
            const selectedCategories = categories.categories
                .filter((c) => c.selected)
                .map((c) => c.id);
            const selectedIds = resources.resources.filter((r) => r.selected).map((r) => r.id);

            // do not send request if data not valid
            if (from >= to) return;

            if (
                !selectedIds ||
                !selectedIds.length ||
                !selectedCategories ||
                !selectedCategories.length
            ) {
                batch(() => {
                    dispatch(setEvents({}));
                    dispatch(setGridEvents({}));
                    dispatch(setNoGridEvents([]));
                });
                return;
            }
            // start Loading
            dispatch(setLoading(true));

            /* Bookkeeping */

            control.fetching = true;
            control.id = transactionID;

            const data = await fetchCalendarData(
                variableJMK.type,
                from,
                to,
                selectedCategories,
                selectedIds,
                entityID
            );
            if ('errorCode' in data) throw data;

            // process and dispatch all the data
            dispatch(handleCalendarData(data.events));

            if (typeof action === 'function') dispatch(action());

            dispatch(setLoading(false));
        } catch (e: any) {
            if ('errorCode' in e) console.error('error status:', e.errorCode);
            // console.error('fetch data:', e.message);
            dispatch(setLoading(false));
        } finally {
            // cancel loading
            // Update controller
            if (control.id === transactionID) control.fetching = false;
            control.lastFetched = Date.now();
        }
    };

/**
 * calling fetch data is debounced by 1s
 */
let timeout: NodeJS.Timeout | null;
export const fetchAndHandleCalendarDataDebounced = (): AppThunk => (dispatch) => {
    // Callback to call later
    const later = () => {
        // reset timeout
        timeout = null;
        // call only if immediate flag not start
        dispatch(fetchAndHandleCalendarData());
    };

    // clear and start timoeout
    timeout && clearTimeout(timeout);
    timeout = setTimeout(later, 8000);
};

/**
 * This type of fetch date, fires only if some debounced version is awaited. It cancels debounced fetch and fetch immediately
 */
export const fetchAndHandleCalendarDataWhenPending = (): AppThunk => (dispatch) => {
    if (!timeout) return;
    clearTimeout(timeout);
    timeout = null;
    dispatch(fetchAndHandleCalendarData());
};

// export const fetchEventDataUpdate = (): AppThunk => (dispatch) => {
// 	dispatch(fetchAndHandleCalendarData());
// };

/**
 * special type of fetchAndHandleCalendarData, wont run, if data fetched recently or another fetch in progress
 */
export const fetchAndHandleCalendarDataConditonally = (): AppThunk => (dispatch) => {
    // fetch if not fetching now, and at least 10 seconds passed from last fetch
    if (!control.fetching && Date.now() - control.lastFetched > 10000)
        dispatch(fetchAndHandleCalendarData());
};

/**
 * Selects next date in chosen calendar view
 */
export const nextDate = (): AppThunk => (dispatch, getState) => {
    const { view, selectedDate } = getState().calendar;

    const config: Duration = {};

    switch (view) {
        case 'day':
            config.days = 1;
            break;
        case 'month':
            config.months = 1;
            break;
        default:
            config.weeks = 1;
    }
    dispatch(handleDateChange(addToDate(selectedDate, config).getTime()));
};

/**
 * Selects prev date in chosen calendar view
 */
export const prevDate = (): AppThunk => (dispatch, getState) => {
    const { view, selectedDate } = getState().calendar;
    // eslint-disable-next-line no-undef
    const config: Duration = {};

    switch (view) {
        case 'day':
            config.days = 1;
            break;
        case 'month':
            config.months = 1;
            break;
        default:
            config.weeks = 1;
    }
    dispatch(handleDateChange(subFromDate(selectedDate, config).getTime()));
};

/**
 * Changes view and fetch new calendar data
 */
export const handleViewChange =
    (view: CalendarView): AppThunk =>
    (dispatch, getState) => {
        const { view: previousView } = getState().calendar;

        if (view === previousView) return;

        dispatch(setView(view));
        dispatch(handleDays());
        dispatch(fetchAndHandleCalendarData());
    };

/**

/**
 * Changes date. Also fetches calendar data, but only if new date outside selected days range
 */
export const handleDateChange =
    (date: number): AppThunk =>
    (dispatch, getState) => {
        const { selectedDate, days } = getState().calendar;

        // First and last day
        const firstDay = days[0],
            lastDay = days[days.length - 1];

        // Do nothing if new date is the same
        if (date === selectedDate) return;

        // change date
        dispatch(setDate(date));
        dispatch(handleDays());

        // Check if new date outside selected days range.
        // Only then calculate new days and fetches new data
        if (
            firstDay &&
            lastDay &&
            (date < firstDay.startTimestamp || date > lastDay.startTimestamp)
        ) {
            dispatch(fetchAndHandleCalendarData());
        }
    };

/**
 * This action calculates and dispatches calendar days for given selectedDate and/or view.
 * Must be dispatched after all other actions to be able to read store after all related changes
 */
export const handleDays = (): AppThunk => (dispatch, getState) => {
    let startDate: Date | number, endDate: Date | number;
    const {
        calendar: { view, selectedDate }
    } = getState();

    switch (view) {
        case 'day':
            startDate = startOfDay(selectedDate);
            endDate = endOfDay(selectedDate);
            break;
        case 'workweek':
            startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
            endDate = subDays(endOfWeek(selectedDate, { weekStartsOn: 1 }), 2);
            break;
        case 'week':
            startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
            endDate = endOfWeek(selectedDate, { weekStartsOn: 1 });
            break;
        default: {
            const monthBoundries = getCalendarMonthBoundries(selectedDate);
            startDate = monthBoundries[0];
            endDate = monthBoundries[1];
            break;
        }
    }

    const monthNumber = getMonth(selectedDate);
    const year = getYear(selectedDate);
    const days = getDaysRange(startDate, endDate, selectedDate);

    batch(() => {
        dispatch(setFrom(days[0].startTimestamp));
        dispatch(setTo(days[days.length - 1].endTimestamp));
        dispatch(setDays(days));
        dispatch(setYear(year));
        dispatch(setMonth(MONTH_NAMES[monthNumber]));
    });
};

/**
 * Fetches Icalendar url (it may not currently exists), and sets it in store
 */
export const loadCurrentIcalUrl = (): AppThunk => async (dispatch) => {
    const { url } = await fetchIcalUrl();
    dispatch(setIcalUrl(url));
};

/**
 * Create new icalUrl if not found in store
 */
export const getIcalUrl = (): AppThunk => async (dispatch, getState) => {
    const existingUrl = getState().calendar.icalUrl;
    if (existingUrl) return;
    const { url } = await createIcalUrl();
    dispatch(setIcalUrl(url));
};

export default calendarSlice.reducer;

/*
pt 14:
from 1621007940000
to 1621029599999

event:
start: 1620994560000
end: 1621005359000
*/
