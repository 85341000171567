import { CalendarType } from '../../../store/src/calendar/calendar/types';
import { CategoriesResp } from '../../types';
import { queryServerData } from './queryServerData';

/**
 * Function fetches and avialable and selected categories categories of displayable calendar events
 */
export async function fetchCategories(
    view: CalendarType,
    entityID?: number
): Promise<CategoriesResp> {
    return queryServerData<CategoriesResp>('api_react/calendar.php', {
        type: 'categories',
        view,
        entity_id: entityID
    });
}
