import React from 'react';
import fileDownload from 'js-file-download';
import { Dispatch, PayloadAction, ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { downloadFileFromResponse } from 'src/utils';
import { combineFullUrl } from './combineFullUrl';
import { ProgressBarState } from 'src/store/src/general/progressBar/types';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { BaseApiResponse } from 'src/api/types';
export class FileManager {
    static async loadPreviewFile({
        dispatch,
        data
    }: {
        dispatch: React.Dispatch<any>;
        data: Response;
    }) {
        const blob = await data.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64String = reader.result;
            dispatch(base64String);
        };
    }

    static async previewFile({ response }: { response: Response }) {
        const blob = await response.blob();
        // const url = URL.createObjectURL(blob);
        const pdfWindow = window.open('', '_black');
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            var base64data = reader.result;
            // console.log(base64data);
            // const iFrameUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${base64data}`;
            // const iFrameUrl2 = `https://docs.google.com/viewer?embedded=true&url=${base64data}`;
            pdfWindow?.document.write(
                "<iframe width='100%' height='100%' src='" + base64data + "'></iframe>"
            );
        };
    }

    static chooseExtension(key: string) {
        switch (key) {
            case 'downloadxls':
                return 'doc';
            case 'downloadpdf':
                return 'pdf';
            default:
                return '';
        }
    }

    static async downloadFile({ response, type }: { response: Response; type: string }) {
        const extension = FileManager.chooseExtension(type);
        const blob = await response.blob();
        await fileDownload(blob, `file.${extension}`);
    }

    static async downloadFiles({ responses, type }: { responses: Response[]; type: string }) {
        responses.forEach((response) => {
            (async () => {
                await FileManager.downloadFile({ response, type });
            })();
        });
    }

    static async handlePreviewFile({
        url,
        params,
        payload,
        body
    }: {
        url: string;
        params?: string;
        payload?: { key: string; value: string }[];
        body?: any;
    }) {
        const allUrl = combineFullUrl(url, new URLSearchParams(params));
        let allBody: string | FormData = '';
        if (body) {
            allBody = JSON.stringify(body);
        }
        if (payload?.length) {
            const formData = new FormData();
            payload.forEach((item) => formData.append(item.key, String(item.value)));
            allBody = formData;
        }
        const response = await fetch(allUrl, {
            method: 'POST',
            body: allBody
        });
        if (response.status === 200) {
            await downloadFileFromResponse(response, 'default');
        }
    }

    static async handleDownloadFile({
        url,
        params,
        payload,
        body,
        dispatch,
        setProgress
    }: {
        url: string;
        params?: string;
        payload?: { key: string; value: string }[];
        body?: any;
        dispatch: Dispatch<PayloadAction<any>>;
        setProgress: ActionCreatorWithPayload<ProgressBarState, string>;
        setSpinner: ActionCreatorWithPayload<boolean, string>;
    }) {
        let isContentLength = true;
        dispatch(setSpinner(true));

        const allUrl = combineFullUrl(url, new URLSearchParams(params));
        let allBody: string | FormData = '';
        if (body) {
            allBody = JSON.stringify(body);
        }
        if (payload?.length) {
            const formData = new FormData();
            payload.forEach((item) => formData.append(item.key, String(item.value)));
            allBody = formData;
        }

        const response = await fetch(allUrl, {
            method: 'POST',
            body: allBody
        });

        // check if api returned an error response
        const contentType = response.headers.get('Content-Type');
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentType?.includes('application/json') && contentDisposition == null) {
            const dataPrimary = await response.text();
            try {
                const dataParsed: BaseApiResponse<any> = JSON.parse(dataPrimary);
                if (!dataParsed.success) {
                    alert(dataParsed.message);
                }
            } catch (e) {
                // do not display error
            }
            dispatch(setSpinner(false));
            return;
        }

        const name =
            contentDisposition?.split('filename=')[1].split(';')[0].replaceAll('"', '') ??
            'dafaultName';
        const contentLength = response.headers.get('content-length')
            ? Number(response.headers.get('content-length'))
            : 0;
        if (contentLength) {
            dispatch(setSpinner(false));
            dispatch(
                setProgress({
                    progress: '0%',
                    isShowed: true
                })
            );
        } else {
            isContentLength = false;
        }
        let loaded = 0;

        const streamResponse = await new Response(
            new ReadableStream({
                start(controller) {
                    const reader = response!.body!.getReader();
                    read();
                    function read() {
                        reader.read().then((progressEvent) => {
                            if (progressEvent.done) {
                                controller.close();
                                return;
                            }
                            if (contentLength) {
                                loaded += progressEvent.value.byteLength;
                                const percentageComplete =
                                    Math.round((loaded / contentLength) * 100) + '%';
                                dispatch(
                                    setProgress({
                                        progress: percentageComplete,
                                        isShowed: true
                                    })
                                );
                            }
                            controller.enqueue(progressEvent.value);
                            read();
                        });
                    }
                }
            })
        );

        const blob = await streamResponse.blob();
        try {
            if (isContentLength) {
                dispatch(
                    setProgress({
                        progress: '0%',
                        isShowed: false
                    })
                );
            } else {
                dispatch(setSpinner(false));
            }
            if (typeof window.escape === 'function') {
                fileDownload(blob, decodeURIComponent(escape(name)));
            } else {
                fileDownload(blob, name);
            }
        } catch (error: any) {
            if (isContentLength) {
                dispatch(
                    setProgress({
                        progress: '0%',
                        isShowed: false
                    })
                );
            } else {
                dispatch(setSpinner(false));
            }
        }
    }
}
