import { createAction } from '@reduxjs/toolkit';

import {
    fetchAndHandleCalendarData,
    setLoading,
    setVariableJMK
} from '../calendar/calendar/calendarSlice';
import { fetchCategories, fetchResources } from 'src/api';
import { AppThunk } from '../calendar';
import { handleResources } from '../calendar/resources/resourcesSlice';
import { handleCategories } from '../calendar/categories/categoriesSlice';
import { CalendarManager } from 'src/store/src/calendar/calendar/CalendarManager';
import { ApiError } from 'src/utils';

export const resetApp = createAction('shared/resetApp');

/*
 * Fetches resources and categories sets them in store, then fetches calendar data
 */
export const loadInitialData = (): AppThunk => async (dispatch, getState) => {
    try {
        const variableJMK = CalendarManager.getVariableJMK();
        dispatch(setLoading(true));
        dispatch(setVariableJMK(variableJMK));
        // Fetch resources and categories
        const requests: [ReturnType<typeof fetchResources>, ReturnType<typeof fetchCategories>] = [
            fetchResources(variableJMK.type),
            fetchCategories(variableJMK.type)
        ];
        // await for them to check if none failed
        const [resourcesData, categoriesData] = await Promise.all(requests);
        //  apart of network errors, api could return error responses
        if ('errorCode' in resourcesData || 'errorCode' in categoriesData) throw new Error();
        const { allEmployees, resources, departments } = resourcesData;
        dispatch(handleResources(resources, allEmployees, departments));
        dispatch(handleCategories(categoriesData.categories));
        // if all ok, can fetch data now
        dispatch(fetchAndHandleCalendarData());
    } catch (e: any) {
        ApiError.handle({ dispatch, err: e });
        console.error('Initial calendar data load failed:', e.message);
    } finally {
        dispatch(setLoading(false));
    }
};
