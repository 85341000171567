import React, { FC, KeyboardEvent } from 'react';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { PopupTemplateFour } from 'src/components/shared/organisms/popupTemplateFour/PopupTemplateFour';
import {
    DynamicStateFormHandler,
    DynamicStateForm
} from 'src/components/shared/molecules/dynamicForm/DynamicFormHandler';
import { globalPopup } from 'src/constants/subtitles';
import { PopupFormBehavior } from 'src/data/types';
import { DynamicFormItem } from 'src/components/shared/molecules/dynamicForm/DynamicFormItem';
import { DynamicFormDataApiCreator } from 'src/components/shared/molecules/dynamicForm/DynamicFormDataApiCreator';
import { DynamicTitle } from 'src/components/shared/molecules/dynamicForm/DynamicTitle';
import { DynamicDecscription } from 'src/components/shared/molecules/dynamicDescription/DynamicDescription';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { useUpdateObjectsData } from 'src/hooks';
import { simpleQueryServerData } from 'src/api/src/simpleQueryServerData';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { PopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import styles from './PopupContentDynamic.module.css';

export const PopupContentDynamic: FC<PopupContentProps> = ({ heightAvailable, widthAvailable }) => {
    const dispatch = useAppDispatch();
    const popup = useTypedSelector((state) => state.popup);
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const behaviour = popup?.listScreen.action?.behaviour as PopupFormBehavior;
    const [submitted, setSubmitted] = React.useState(false);
    const updateObjects = useUpdateObjectsData();

    const { control, handleSubmit } = useForm({
        defaultValues: new DynamicStateFormHandler({
            fields: behaviour.data.fields,
            ids: popup.listScreen.objectId,
            rowData: rowData
        }).createInitState()
    });

    const onSubmit = async (data: DynamicStateForm) => {
        if (submitted) return;

        setSubmitted(true);

        const dataToApi = new DynamicFormDataApiCreator({
            state: data,
            fields: behaviour.data.fields
        }).getData();

        dispatch(setSpinner(true));
        const res = await simpleQueryServerData(behaviour.data.url, 'POST', dataToApi, true);

        if (res.success) {
            updateObjects({ saveLocationState: false, forceRefreshData: true });
        }
        dispatch(setSpinner(false));
        if (behaviour?.data.showResult) {
            new PopupManager({ dispatch, action: popup?.listScreen.action }).setPopupInfo(
                res.message
            );
        } else {
            dispatch(closePopup());
        }
    };

    const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    return (
        <PopupTemplateFour
            maxHeight={heightAvailable > 600 ? 480 : heightAvailable - 100}
            maxWidth={widthAvailable > 700 ? 450 : widthAvailable - 20}>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                {popup.listScreen.objectId.map((id: RowDataId) => {
                    return (
                        <div
                            key={id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <DynamicTitle id={id} />
                            <DynamicDecscription id={id} action={popup.listScreen.action} />
                            <DynamicFormItem
                                id={id}
                                control={control}
                                action={popup.listScreen.action}
                            />
                        </div>
                    );
                })}
                <div className={styles.box}>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 50px',
                            cursor: 'pointer'
                        }}>
                        <p className={`${styles.button}`}>
                            {globalPopup.contentDynamic.button.yes}
                        </p>
                    </Button>
                </div>
            </form>
        </PopupTemplateFour>
    );
};
