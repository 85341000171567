export type Cell = {
    numberOfRow: number;
    time: string;
    startTimestampOfCell: number;
};

export class UserGridStateCreator {
    range: string;
    numberOfRows: number;
    sixPartTimestampOfCell: number;
    partTimestampOfCell: number;
    heightCell: number;
    heightOfBoard: number;
    cells: Cell[];
    constructor(range: string) {
        this.range = range;
        this.numberOfRows = 48;
        this.sixPartTimestampOfCell = 0;
        this.partTimestampOfCell = 0;
        this.heightCell = 50;
        this.heightOfBoard = 0;
        this.cells = [];
        this.generateState(this.range);
    }

    setPartTimestampOfCell() {
        this.partTimestampOfCell = 86400000 / this.numberOfRows;
    }

    setHeightOfBoard() {
        this.heightOfBoard = this.numberOfRows * this.heightCell;
    }

    setSixPartTimestampOfCell() {
        this.sixPartTimestampOfCell = this.partTimestampOfCell / 6;
    }

    setNumbersOfRows(rows: number) {
        this.numberOfRows = rows;
    }

    setHour(i: number) {
        return `${i}:00`;
    }

    setHalfHour(i: number) {
        if (i === 0) return this.setHour(i);
        else if (i === 1) return `${i - 1}:30`;
        else if (!(i % 2)) return this.setHour(i / 2);
        else return `${(i - 1) / 2}:30`;
    }

    setTime(i: number, range: string) {
        if (range === 'HOUR') return this.setHour(i);
        else return this.setHalfHour(i);
    }
    setStartTimestampOfCell(i: number) {
        return i * this.partTimestampOfCell;
    }
    setCells(range: string) {
        for (let i = 0; i < this.numberOfRows; i++) {
            const time = this.setTime(i, range);
            this.cells.push({
                numberOfRow: i + 1,
                startTimestampOfCell: this.setStartTimestampOfCell(i),
                time: time
            });
        }
    }
    generateState(range: string) {
        switch (range) {
            case 'HOUR':
                this.setNumbersOfRows(24);
                this.setHeightOfBoard();
                this.setPartTimestampOfCell();
                this.setSixPartTimestampOfCell();
                break;
            case 'HALFHOUR':
                this.setNumbersOfRows(48);
                this.setHeightOfBoard();
                this.setPartTimestampOfCell();
                this.setSixPartTimestampOfCell();
                break;
            default:
                this.setNumbersOfRows(48);
                this.setHeightOfBoard();
                this.setPartTimestampOfCell();
                this.setSixPartTimestampOfCell();
                break;
        }
        this.setCells(range);
    }
    getGridState() {
        return {
            range: this.range,
            numberOfRows: this.numberOfRows,
            partTimestampOfCell: this.partTimestampOfCell,
            sixPartTimestampOfCell: this.sixPartTimestampOfCell,
            heightCell: this.heightCell,
            heightOfBoard: this.heightOfBoard,
            cells: this.cells
        };
    }
}
