import React, { useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from 'src/store/index';
import { useLoadInitialData } from 'src/hooks';
import Routes from './Routes';
import GlobalPopup from '../../organisms/wraperGlobalPopup/WraperGlobalPopup';
import { Loader } from 'src/components/shared/organisms/loader/Loader';
import { useCheckIsPopupToDisplay } from 'src/hooks';
import { CheckTime } from 'src/components/shared/pages/checkTime/CheckTime';
import { ProgressBar } from 'src/components/shared/molecules/progressBar/ProgressBar';

const Router = () => {
    const dispatch = useAppDispatch();
    const systemLink = useTypedSelector((state) => state.system.systemUrl);

    // extracts from full url 'https://46.171.79.169/testsystem.syndyk.it/test2/' basename part: '/testsystem.syndyk.it/test2'
    const basename = useMemo(() => {
        const calcBasename = () => {
            const splitResult = systemLink?.split('/').filter((e) => e.length > 0);
            if (!splitResult || splitResult.length <= 2) return '';

            return '/' + splitResult.slice(2).join('/');
        };

        const basename = calcBasename();
        window.basename = basename;
        return basename;
    }, [systemLink]);
    useLoadInitialData(dispatch);
    useCheckIsPopupToDisplay();

    // systemLink != null in order to run routes exacly once after basename is loaded
    return (
        <BrowserRouter basename={process.env.NODE_ENV === 'development' ? '' : basename}>
            {systemLink != null && <Routes basename={basename} />}
            <CheckTime />
            <GlobalPopup />
            <Loader />
            <ProgressBar />
        </BrowserRouter>
    );
};

export default Router;
