import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import { Zoom } from '@mui/material';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import SelectOptions from './SelectOptions';

const useStyles = makeStyles(({ palette, spacing }) => ({
    timer_select_root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    timer_select_input: {
        width: 'calc(100% - 15px)',
        padding: spacing(0.5),
        color: palette.text.hint,
        '&:placeholder': {
            opacity: 0.9
        }
    },
    timer_select_input_input: {
        '&.MuiInputBase-input.MuiInput-input': {
            cursor: (props) =>
                props.disabled
                    ? 'default'
                    : props.type === 'typ_czego'
                    ? 'pointer!important'
                    : 'auto',
            border: 'none',
            backgroundColor: 'transparent',
            margin: 0,
            fontSize: spacing(1.5),
            padding: `${spacing(0.6)}px 0 ${spacing(0.7)}px`,
            '&:focus': {
                border: 'none',
                boxShadow: 'none'
            }
        }
    },
    timer_select_input_disabled: {
        color: (props) => (props.sprawa ? 'rgba(168,17,49, .7)' : 'inherit'),
        textOverflow: 'ellipsis',
        '&:before': {
            borderBottomStyle: 'solid!important',
            borderBottomColor: palette.grey[200] + '!important'
        }
    },
    timer_select_icon: {
        opacity: (props) => (props.disabled ? 0 : 1),
        fontSize: spacing(1.5),
        transition: 'transform .5s ease',
        transform: 'rotateZ(0)'
    },
    timer_select_icon_open: {
        transform: 'rotateZ(180deg)'
    }
}));

/**
 * Stanowy komponent funkcyjny Select. Odpowiada za wyświetlanie inputu typu select.
 * Wyświetla komponent [Components/Timers/SelectOptions]{@link SelectOptions}.
 * @component
 * @category Components
 * @subcategory Timers
 * @param {object} props - Propsy komponentu
 * @param {array} props.options -  Dostępne opcje dla inputu select.
 * @param {string} props.value -  Wartosci inputu select.
 * @param {Function} props.setInputValue -  Funckja ustawiania wartosci inputu select.
 * @param {Function} props.handleSelect -  Funkcja wywoływana na kliknięcie w którąś z opcji.
 * @param {"nazwa_czego"|"typ_czego"} props.type - Typ selectu.
 * @param {boolean} props.disabled - Wyznacza czy input jest aktywny
 * @param {boolean} props.open - Wyznacza czy opcje inputu są otwarte
 * @param {object} props.containerRef -  referencja do listy wyświetlającej stopery
 * @param {Function} props.openNewCase -funkcja otwiera nowe okno w systemie, w którym można stworzyć zadanie lub inny obiekt który automatycznie zostanie przypisany do stopera
 * @param {object|null} props.sprawa -  informacje o przypisanej sprawie
 * @param {'large'|'medium'|'small'} props.viewport - rozmiar viewportu
 * @property {object} optionsPosition -  Stan komponentu pozycja i wymiary SelectOptions.
 * @property {Function} setOptionsPosition -  Ustawia stan komponentu pozycja i wymiary SelectOptions.
 * @property {array} selectOptions - Stan komponentu dostępne opcje przefiltrowane przez aktualną wartość inputu.
 * @property {funtion} setSelectOptions -  Filtrowanie stanu dostępnych opcji.
 * @returns {ReactComponent}
 * @see [Components/Timers/SelectOptions]{@link SelectOptions}, [Components/Timers/AssignForm]{@link AssignForm}
 */
const Select = (props) => {
    // Classes
    const classes = useStyles(props);
    const {
        options,
        inputValue,
        setInputValue,
        handleSelect,
        type,
        handleOpen,
        disabled = false,
        open,
        containerRef,
        openNewCase,
        sprawa,
        viewport
    } = props;
    // Local state
    const [optionsPosition, setOptionsPosition] = useState({});
    const inputRef = useRef();

    /**
     * @memberof Select
     * @member useEffect
     * @inner
     * @type {ReactHook}
     * @description Hook efektów - filtruje opcje [Components/Timers/SelectOptions]{@link SelectOptions} gdy zmienia się wartośc inputu..
     */
    // useEffect(() => {
    // 	const el = containerRef?.current;
    // 	if (open) {
    // 		if (el) el.style.overflowY = 'hidden';
    // 	}
    // 	else {
    // 		if (el) el.style.overflowY = '';
    // 	}
    //
    // 	return () => {
    // 		if (el) el.style.overflowY = '';
    // 	};
    // }, [open, containerRef]);

    /**
     * @memberof Select
     * @method handleInputFocus
     * @description Funkcja obsługuje eventy focus i blur dla inputu
     * @param {event} event - event przekazany do funkcji
     * @param {boolean} bool - wartość true oznacza focus, false oznacza blur
     * @returns {void}
     *
     *
     */

    const handleInputFocus = (event, bool) => {
        if (bool) {
            const { x, y, height, width } = event.target.getBoundingClientRect();
            // 4 is for padding offset, if input padding will change adjust this value
            // const position = { top: y + height + 4, right: window.innerWidth - x - width - 4 };
            const position = { height };
            setOptionsPosition(position);
            if (type === 'typ_czego') setTimeout(() => handleOpen(bool), 100);
        } else {
            setTimeout(() => handleOpen(bool), 100);
        }
    };

    return (
        <div className={classes.timer_select_root}>
            <div
                style={{
                    position: 'relative',
                    zIndex: 3
                }}>
                <Tooltip arrow TransitionComponent={Zoom} title={sprawa ? inputValue : ''}>
                    <div>
                        <Input
                            ref={inputRef}
                            readOnly={type === 'typ_czego'}
                            inputProps={{
                                'aria-autocomplete': 'none'
                            }}
                            classes={{
                                root: classes.timer_select_input,
                                disabled: classes.timer_select_input_disabled,
                                input: classes.timer_select_input_input
                            }}
                            disabled={disabled}
                            placeholder={type === 'typ_czego' ? 'rodzaj' : 'wpisz nazwę'}
                            value={inputValue}
                            onChange={setInputValue}
                            onFocus={(e) => handleInputFocus(e, true)}
                            onBlur={(e) => handleInputFocus(e, false)}
                        />
                        <ArrowDropDownIcon
                            className={[
                                classes.timer_select_icon,
                                open ? classes.timer_select_icon_open : ''
                            ].join(' ')}
                        />
                    </div>
                </Tooltip>
                <SelectOptions
                    type={type}
                    options={options}
                    open={open}
                    position={optionsPosition}
                    handleClick={handleSelect}
                    openNewCase={openNewCase}
                    viewport={viewport}
                />
            </div>
        </div>
    );
};

export default Select;
