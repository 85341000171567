const humanizeDuration = require('humanize-duration');
const divider = 0.6;
export class TimeManager {
    static setMinutesAbsoluteToHour(minutes: number | undefined) {
        if (minutes) {
            const hours = Math.floor(minutes / 60);
            const rest = minutes % 60;
            const newMinutes = rest / divider;
            return hours + newMinutes / 100;
        } else return 0;
    }
    static devideHoursToAbsoluteMinutes(time: number | undefined) {
        if (time) {
            const hoursInMinutes = Math.floor(time) * 60;
            const rest = Number(time.toString().split('.')[1])
                ? Number(time.toFixed(2).split('.')[1])
                : 0;
            const restMinutes = rest * divider;
            const allMinutes = hoursInMinutes + restMinutes;
            return Math.round(allMinutes);
        } else return 0;
    }

    static mergeOldTimeWithNewDate(time: String, dateNew: Date) {
        const timeArr = time ? time.split(':') : [];
        const dateOld = new Date();
        if (timeArr[0]) dateOld.setHours(Number(timeArr[0]));
        if (timeArr[1]) dateOld.setMinutes(Number(timeArr[1]));
        const hoursInTimestamp = dateOld.getHours() * 3600000;
        const minutesInTimestamp = dateOld.getMinutes() * 60000;
        const allTimestamp = hoursInTimestamp + minutesInTimestamp;
        const ubdatedDate = dateNew.getTime();
        return new Date(allTimestamp + ubdatedDate);
    }
}
