import React, { FC } from 'react';

import { DynamicSearchLabelProps } from 'src/components/listScreen/molecules/dynamicSearchLabel/type';
import { UiCheckbox } from 'src/components/shared/atoms/uiInputs/uiCheckbox/UiCheckbox';
import styles from './DynamicSearchLabel.module.css';

export const DynamicSearchLabel: FC<DynamicSearchLabelProps> = ({
    searchField,
    listSearchFilters,
    dispatchFilter
}) => {
    const orMode = listSearchFilters[searchField.searchCode]?.fieldFilter?.orMode
        ? listSearchFilters[searchField.searchCode]?.fieldFilter?.orMode
        : false;
    return (
        <div className={styles.wrapper}>
            {searchField.name} {searchField.searchType === 'data' && '(od - do)'}
            {searchField.orMode && (
                <UiCheckbox
                    label={'kryterium "lub"'}
                    sxelwrapper={{
                        justifyContent: 'flex-start'
                    }}
                    sxcheckbox={{
                        paddingTop: '0px',
                        paddingBottom: '2px'
                    }}
                    defaultChecked={orMode}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        dispatchFilter({
                            type: 'SET_ORMODE',
                            payload: {
                                code: searchField.searchCode,
                                ormode: event.target.checked
                            }
                        });
                    }}
                />
            )}
        </div>
    );
};
