import React from 'react';
import styles from './Logo.module.css';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from 'src/store';
export const LogoProduction = () => {
    const system = useTypedSelector((state) => state.system);
    const navigate = useNavigate();
    let url: string | undefined | null = system?.systemUrl;
    if (system?.systemUrl?.endsWith('/')) {
        url = url?.slice(0, -1);
    }

    return (
        <div className={styles.wrapper} onClick={() => navigate('/')}>
            <img
                src={url + system.logoSystemuMini}
                alt="Logo"
                style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
            />
        </div>
    );
};
